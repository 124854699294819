import React,{ReactNode,useEffect} from 'react';
import {Menus} from '../menu/menu'
import {List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import InboxIcon from "@mui/icons-material/Inbox";
import PostAddIcon from '@mui/icons-material/PostAdd';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import BarChartIcon from '@mui/icons-material/BarChart';
import PersonIcon from '@mui/icons-material/Person';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {authSelector} from "../../store/auth/auth";
interface layoutComponent{
    children:ReactNode
}

export const LayoutAdmin =({children}:layoutComponent) =>{
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const navigate=useNavigate();
    const {user}=useSelector(authSelector);
    
    const arrayTaxiPlan = JSON.parse(
        process.env.REACT_APP_ID_TAXIPLAN ?? ""
    );
    const arrayCajaGeneral = JSON.parse(
        process.env.REACT_APP_ID_CAJA_GENERAL ?? ""
    );
    const arraySaleReport = JSON.parse(
        process.env.REACT_APP_ID_UPLOAD_SALE ?? ""
    );
    const arrayDirectSells = JSON.parse(
        process.env.REACT_APP_ID_VENTAS_DIRECTAS ?? ""
    );

    const hasAccessToCashBox = arrayTaxiPlan.some(
        (value:string) => +value === user.id
    );
    const hasAccessToIncrement =   arrayCajaGeneral.some(
        (value:string) => +value === user.id
    );
    const hasAccessToReport =   arraySaleReport.some(
        (value:string) => +value === user.id
    );
    const hasAccessToDirectSells = arrayDirectSells.some(
        (value:string) => +value === user.id
    );


    useEffect(() => {
        switch (window.location.pathname) {
            case '/cash':
                setSelectedIndex(0);
                break;
            case '/commission':
                setSelectedIndex(1);
                break;
            case '/reports':
                setSelectedIndex(2);
                break;
            case '/increment/cash':
                setSelectedIndex(3);
                break;
            case '/increment/cashBox':
                setSelectedIndex(3);
                break;
            case '/seller':
                setSelectedIndex(4);
                break;
            case '/reports-sale':
                setSelectedIndex(5);
                break;
        }
    }, []);

    return(
        <>
            <Menus/>
            <div className="cont-taxi-plan">
                <div className="aside-panel">
                    <List component="nav" aria-label="main mailbox folders">
                        {
                            hasAccessToCashBox &&         <ListItemButton
                                selected={selectedIndex === 0}
                                onClick={(event) =>{
                                    setSelectedIndex(0);
                                    navigate("/cash", { replace: true })
                                }}

                            >
                                <ListItemIcon>
                                    <PointOfSaleIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Caja"/>
                            </ListItemButton>
                        }
                        {
                            hasAccessToCashBox &&       <ListItemButton
                                selected={selectedIndex === 1}
                                onClick={(event) =>{
                                    setSelectedIndex(1);
                                    navigate("/commission", { replace: true })
                                }}
                            >
                                <ListItemIcon>
                                    <InboxIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Comisiones"/>
                            </ListItemButton>
                        }

                        {
                            hasAccessToCashBox &&        <ListItemButton
                                selected={selectedIndex === 3}
                                onClick={(event) =>{
                                    setSelectedIndex(3);
                                    navigate("/increment/cash", { replace: true })
                                }}
                            >
                                <ListItemIcon>
                                    <PostAddIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Incrementos"/>
                            </ListItemButton>
                        }

                        {
                            hasAccessToIncrement && <ListItemButton
                                selected={selectedIndex === 3}
                                onClick={(event) =>{
                                    setSelectedIndex(3);
                                    navigate("/increment/cashBox", { replace: true })
                                }}
                            >
                                <ListItemIcon>
                                    <PostAddIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Incrementos Caja General"/>
                            </ListItemButton>
                        }

                        {
                            hasAccessToCashBox &&     <ListItemButton
                                selected={selectedIndex === 4}
                                onClick={(event) =>{
                                    setSelectedIndex(4);
                                    navigate("/seller", { replace: true })
                                }}

                            >
                                <ListItemIcon>
                                    <PersonIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Taxista"/>
                            </ListItemButton>
                        }

                        {
                            hasAccessToDirectSells &&     <ListItemButton
                                selected={selectedIndex === 5}
                                onClick={(event) =>{
                                    setSelectedIndex(5);
                                    navigate("/report-sale", { replace: true })
                                }}

                            >
                                <ListItemIcon>
                                    <InboxIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Ventas Directas"/>
                            </ListItemButton>
                        }

                        <ListItemButton
                            selected={selectedIndex === 2}
                            onClick={(event) =>{
                                setSelectedIndex(2);
                                navigate("/reports", { replace: true })
                            }}

                        >
                            <ListItemIcon>
                                <BarChartIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Reportes"/>
                        </ListItemButton>
                    </List>

                </div>
            <main className="w-100">{children}</main>
            </div>
        </>
    )
}

export default LayoutAdmin;
