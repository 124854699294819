import React from 'react';
import {AppBar,Box,Toolbar,Typography,Button} from '@mui/material';
import './menu.css';
import {Logo} from '../../assets/image/';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {useSelector} from "react-redux";
import {authSelector,logout} from "../../store/auth/auth";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../store/store";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
export const Menus =() =>{
    const navigate=useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const dispatch = useAppDispatch();
    const {user}=useSelector(authSelector);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return(
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" className="header">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} onClick={()=>{
                        navigate("/home", { replace: true })
                    }}>
                        <div className="cont-logo"><img src={Logo} alt=""/></div>
                    </Typography>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <span style={{display:'flex',justifyContent:'center'}}><AccountCircleIcon/>&nbsp; {user?.name}</span>
                        <Button
                            id="basic-button"
                            color="inherit"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                        >
                            <MoreVertIcon/>
                        </Button>
                    </div>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={()=>{
                            dispatch(logout())
                            navigate("/login", { replace: true })
                        }}>Logout</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>

        </Box>
    )
}
