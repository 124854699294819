import React from "react";
import {TextField} from "@mui/material";
interface IDateField{
    index:number,
    date:string | null
}
export const DateField :React.FC<IDateField>=({index,date}) =>{
    const newDay = new Date();
    const today = newDay.getFullYear() + '/' + (((newDay.getMonth() + 1) < 10) ? '0' : '') + (newDay.getMonth() + 1) + '/' + ((newDay.getDate() < 10) ? '0' : '') + newDay.getDate();
    const retrieveDate=date?date.split(" "):'';
    return              <TextField
        disabled
        key={`fecha-${index}`}
        id="outlined-disabled"
        label="Fecha Operación"
        defaultValue={date===null?today:retrieveDate[0]}
        InputProps={{
            readOnly: true,
        }}

    />

}
