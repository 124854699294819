import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axios from 'axios';
import storageSession from 'redux-persist/lib/storage/session'
import {persistReducer} from 'redux-persist';
import {productsByEnterprise} from '../enterprises/enterprise';
import {openBackdrop,closeBackdrop} from '../backdrop/backdrop'

import {RootState} from '../rootReducer';
import {logout} from "../../store/auth/auth";
import {
    ICommission,
    ICommissionPaginate,
    IDataCommission,
    IRetrieveCommission,
    IErrorCommission,
    ICreateCommission
} from '../../models/commision'
import {openNotifier} from "../../store/notifier/notifier";


const api = process.env.REACT_APP_API_URL
const initialStatePaginate: ICommissionPaginate = {
    data: [],
    meta: {
        current_page: 1,
        per_page: '100',
        total: 0
    },
    search:'',
    commission:null,
    errors:null,
    loading: false,
    new_seller:false,
    update_seller:false,
    update_commisionable_agent:false,
    new_commisionable_agent:false,
    delete_array:[]
}

export const commissionPaginate = createAsyncThunk<ICommissionPaginate,
    { page: number, items: number,search:string },
    {
        rejectValue: string
    }>(
    'commission/list',
    async (data, thunkApi) => {
            thunkApi.dispatch(openBackdrop())
        try {
            const url=data.search?`${api}/taxi-plan?page=${data.page}&items=${data.items}&searchFolio=${data.search}`:`${api}/taxi-plan?page=${data.page}&items=${data.items}`;
            const response = await axios.get(url);
             thunkApi.dispatch(closeBackdrop());
             thunkApi.dispatch(searchFilter(data.search))
            return response.data;
        } catch (err) {
            thunkApi.dispatch(closeBackdrop());
            // @ts-ignore
            if(err?.response.status===401 ||err?.response.status===408){
                thunkApi.dispatch(openNotifier({open:true,variant:"error",message:'Ha ocurrido un error y el sistema se ha tenido que cerrar'}))
                thunkApi.dispatch(logout());
            }
            return thunkApi.rejectWithValue('error')
        }
    }
)

export const commissionRetrieve = createAsyncThunk<IRetrieveCommission,
    { id: string,intAgent:number },
    {
        rejectValue: string
    }>(
    'commission/retrieve',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        try {

            const url=data.intAgent?`${api}/taxi-plan/${data.id}/${data.intAgent}`:`${api}/taxi-plan/${data}`
            const response = await axios.get(url);
            thunkApi.dispatch(closeBackdrop());
            thunkApi.dispatch(productsByEnterprise({enterpriseId:response.data?.enterprise?.id}))
            return response.data;
        } catch (err) {
            thunkApi.dispatch(closeBackdrop());
            return thunkApi.rejectWithValue('error')
        }
    }
)
export const commissionPay = createAsyncThunk<string,
    { id: string,intAgent:string },
    {
        rejectValue: string
    }>(
    'commission/payment',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        try {
            const url=data.intAgent?`${api}/taxi-plan/pay/${data.id}/${data.intAgent}`:`${api}/taxi-plan/pay/${data.id}/2`
            const response = await axios.put(url);
            thunkApi.dispatch(closeBackdrop());
            return response.data;
        } catch (err) {
            thunkApi.dispatch(closeBackdrop());
            // @ts-ignore
            thunkApi.dispatch(fillError(err.response.data))
            // @ts-ignore
            return thunkApi.rejectWithValue(err.response.data)
        }
    }
)


export const commissionCreate = createAsyncThunk<ICommission,
    { commission: ICreateCommission },
    {
        rejectValue: unknown
    }>(
    'commission/create',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        try {
            const response = await axios.post(`${api}/taxi-plan`, data.commission);
            thunkApi.dispatch(closeBackdrop());
            return response.data;
        } catch (err) {
            thunkApi.dispatch(closeBackdrop());
            // @ts-ignore
             thunkApi.dispatch(fillError(err.response.data))
            // @ts-ignore
            return thunkApi.rejectWithValue(err.response.data)
        }
    }
)
export const commissionUpdate = createAsyncThunk<ICommission,
    { id: string, commission: ICreateCommission },
    {
        rejectValue: unknown
    }>(
    'commission/update',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        try {

            const response = await axios.put(`${api}/taxi-plan/${data.id}`, data.commission);
            thunkApi.dispatch(closeBackdrop());
            return response.data;
        } catch (err) {
            thunkApi.dispatch(closeBackdrop());
            // @ts-ignore
            thunkApi.dispatch(fillError(err.response.data))
            // @ts-ignore
            return thunkApi.rejectWithValue(err.response.data)
        }
    }
)
export const commissionDisabled = createAsyncThunk<IDataCommission[],
    { id: number,data:IDataCommission[]},
    {
        rejectValue: string
    }>(
    'commission/disabled',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        try {

            const response = await axios.delete(`${api}/taxi-plan/${data.id}`);
            const detailUpdate=Array.from(data.data, (detail:IDataCommission) => {
                if(detail.idReserva===data.id){
                    return {...detail,intEstado:!detail.intEstado}
                }
                return {...detail}

            } )
            thunkApi.dispatch(closeBackdrop());
            return detailUpdate;
        } catch (err) {
            thunkApi.dispatch(closeBackdrop());
            return thunkApi.rejectWithValue('error')
        }
    }
)

export const commissionSlice = createSlice({
    initialState: initialStatePaginate,
    name: 'commission',
    extraReducers: builder => {
        builder
            .addCase(commissionPaginate.pending, state => {
                state.loading = true
            })
            .addCase(commissionPaginate.fulfilled, (state, {payload}) => {
                const {data, meta} = payload
                state.data = data
                state.meta = meta
                state.commission=null
                state.loading = false
            })
            .addCase(commissionPaginate.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(commissionRetrieve.pending, state => {
                state.loading = true
            })
            .addCase(commissionRetrieve.fulfilled, (state, {payload}) => {
                state.commission = payload
                state.loading = false
            })
            .addCase(commissionRetrieve.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(commissionPay.pending, state => {
                state.loading = true
            })
            .addCase(commissionPay.fulfilled, (state, {payload}) => {
                state.loading = false
                state.commission=null
            })
            .addCase(commissionPay.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(commissionCreate.pending, state => {
                state.loading = true
            })
            .addCase(commissionCreate.fulfilled, (state, {payload}) => {
                state.loading = false
                state.errors=null;
                state.new_seller=false;
                state.new_commisionable_agent=false;
            })
            .addCase(commissionCreate.rejected, (state, action) => {
                state.loading = false
                state.new_seller=false;
                state.new_commisionable_agent=false;
            })
            .addCase(commissionUpdate.pending, state => {
                state.loading = true
            })
            .addCase(commissionUpdate.fulfilled, (state, {payload}) => {
                state.loading = false
                // state.errors=null;
                // state.new_seller=false;

                // state.new_commisionable_agent=false;
            })
            .addCase(commissionUpdate.rejected, (state, action) => {
                state.loading = false
                // state.new_seller=false;
                // state.new_commisionable_agent=false;
            })
            .addCase(commissionDisabled.pending, state => {
                state.loading = true
            })
            .addCase(commissionDisabled.fulfilled, (state, {payload}) => {
                state.loading = false
                state.data=payload
            })
            .addCase(commissionDisabled.rejected, (state, action) => {
                state.loading = false
            })
    },
    reducers: {
        fillError: (state, action: PayloadAction<IErrorCommission>) => {
            state.errors = {...action.payload};
        },
        searchFilter:(state, action: PayloadAction<string>)=>{
            state.search=action.payload
        },
        changeNewSeller:(state)=>{
        state.new_seller=!state.new_seller
        },
        changeNewCommisionableAgent:(state)=>{
            state.new_commisionable_agent=!state.new_commisionable_agent
        },
        resetCommission:(state)=>{
            state.commission=null
        },
        resetStateNewSellerNewCommisionable:(state)=>{
            state.new_seller=false
            state.new_commisionable_agent=false
        },
        deleteArrayPush:(state,{payload}: PayloadAction<number>)=>{
            // console.log(Array.isArray(state.delete_array))
            // console.log(state.delete_array)
            // console.log(Array.isArray([]))
            // state.delete_array=state.delete_array.concat();
        }

    },
})


export const CommissionSelector = (state: RootState) => {
    return {
        commission: state.commission.commission,
        loadingCommission: state.commission.loading,
        new_seller:state.commission.new_seller,
        new_commisionable_agent:state.commission.new_commisionable_agent,
        update_seller:state.commission.update_seller,
        update_commisionable_agent:state.commission.update_commisionable_agent,
        errors:state.commission.errors
    };
};
export const CommissionMetaSelector = (state: RootState) => {
    return {
        meta: state.commission.meta,
        data: state.commission.data,
        loadingCommissionPaginate: state.commission.loading,
        search: state.commission.search
    };
};

export const CommissionPaginateReducer = persistReducer({
    storage:storageSession,
    key: 'commission',
    blacklist: ['data', 'meta','new_commisionable_agent','new_seller']
}, commissionSlice.reducer)
export const {
    fillError,
    searchFilter,
    resetCommission,
    changeNewSeller,
    changeNewCommisionableAgent,
    deleteArrayPush,
    resetStateNewSellerNewCommisionable
} = commissionSlice.actions;
