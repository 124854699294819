export interface IEnterprise {
    enterprises:Enterprise[],
    loading:boolean
}

export interface Enterprise {
    id: number,
    name: string
}
export interface IProduct{
    products:Product[],
    loadingProducts:boolean
}
export interface Product{
    id: number,
    name: string,
    rates: {
        adult: number,
        children: number,
    },
    type_tour: string,
}

export const InitialValueEnterprise:Enterprise={
    id: 0,
    name: ''
}

export const InitialValueProduct:Product={
    id: 0,
    name: '',
    rates: {
        adult: 0,
        children: 0,
    },
    type_tour: '',
}
