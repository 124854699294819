import React, {useEffect, useState, useRef} from "react";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import {useAppDispatch} from "../../store/store";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {useNavigate, useParams} from "react-router-dom";
import { formSaleUploadExcel } from "../../store/formSale/formSale";
import {openNotifier} from "../../store/notifier/notifier";
import axios, { AxiosResponse } from "axios";
import { RadioGroup, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export const FormSale = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    /*
        Para el boton de carga de Excel
        Primero usamos un usetate para manejar el estado y recibir un archivo o null
        Despues usamos useRef para manipular posteriormente un elemento input, inicializado en null con un tipo de argumento
    */
    const [ selectedFile, setSelectedFile ] = useState<File | null>( null );
    const fileInputRef = useRef<HTMLInputElement>(null);

    /*
        Para manejar el estado de los radio boton
    */
   const [ selectedOption, setSelectedOption ] = useState<string>('1');

    /*
        Cuando cambie el archivo seleccionado; o se elija por primera vez
        Recibimos cuando cambia el archivo actual y se actualiza el estado de selectedFile o archivo seleccionado
    */
    const handleFileChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
        const files = event.target.files;
        if(files && files.length > 0){
            setSelectedFile( files[0] );
        }
    };

    /*
        Cuando hacemos clic al boton para "cargar" el archivo entonces le hacemos clic
        con useRef desde el elemento HTMLInput previamente cargado.
    */
    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }

    useEffect(() => {
        
    }, [dispatch]);

    /*
        Aqui manejamos el boton de "Subir", revisamos si ya hay un archivo  cargado
        y vemos que hacemos con este
    */
    const handleUpload = () => {
        /*
            Si tengo un archivo cargado en el estado; entonces 
        */
        if( selectedFile ){
            //Instancio una clase que me permitirá leer el contenido de dicho archivo | excel
            const reader = new FileReader();
            reader.onload = (event) => {
                //En la variable "fileContents" tenemos guardado el contenido del archivo excel
                const fileContents = event.target?.result as string | null;
                
                if(fileContents){
                    //Enviamos la data con form data
                    const formData = new FormData();
                    formData.append('ventas_file', selectedFile);
                    formData.append('excel_type', selectedOption );
                    
                    //  Se hace el envio y captura de respuesta
                    dispatch( formSaleUploadExcel({datos: formData}) )
                    .then((res) => {
                        const response = JSON.stringify(res);
                        const response1 = JSON.parse(response);

                        if(res.payload == "error"){
                            dispatch(openNotifier({variant: "error", open: true, message: 'error: Formato Incorrecto'}));
                        }else{
                            let registrosTotales;
                            let details_created;

                            if( "payload" in response1 ){
                                if( "response" in response1.payload ){
                                    if( "total_created" in response1.payload.response ){
                                        registrosTotales = response1.payload.response.total_created;
                                    }
                                    if( "details_created" in response1.payload.response ){
                                        details_created = response1.payload.response.details_created;
                                    }
                                }
                            }
                            dispatch(openNotifier({variant: "success", open: true, message: `éxito: ${registrosTotales} ventas grabadas; ${details_created} detalles grabados`}));
                        }
                        
                        //Reseteamos el estado
                        setSelectedFile( null );
                        if( fileInputRef.current ){
                            fileInputRef.current.value = "";
                        }
                     })
                    .catch((error) => {
                        dispatch(openNotifier({variant: "error", open: true, message: 'error'}));
                        //Reseteamos el estado
                        setSelectedFile( null );
                        if( fileInputRef.current ){
                            fileInputRef.current.value = "";
                        }
                     })
                    .finally(() => {
                        
                    })
                    
                }
            };
            
            /*
                Importante: ¡No borrar!
                readAsDataURL es para manejar tipo blob y, por ende, archivos csv/xlsx 
            */
            reader.readAsDataURL(selectedFile);
            
        }else{
            console.log("archivo no cargado, por favor comprobar el tipo de archivo");
        }
    };
    
    /*
        Aqui manejamos el boton de "Cancelar", revisamos si ya hay un archivo  cargado
        y vemos que hacemos con este
    */
    const handleCancel = () => {
        //Reseteamos el estado
        setSelectedFile( null );
        /*
            Si tengo un archivo cargado en el estado; entonces 
        */
        if( fileInputRef.current ){
            fileInputRef.current.value = "";
        }
    }

    /*
        Aqui manejamos el change de los radio button
    */
    const handleRadioChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
        setSelectedOption( (event.target as HTMLInputElement).value );
    }


    useEffect(() => {
    }, []);

    return (
        <>
            <div className="content-panel" >
                <Grid 
                    container 
                    spacing={0}
                    direction="column"
                    alignItems="center"
                
                
                    className="contVentasDirectas"
                >
                    <Grid 
                        mt={5}
                    >
                        <Box 
                            sx={{ width: "100%" }}
                        >
                            <Typography variant="h5" align="center">Ventas directas</Typography>
                            <Typography variant="body1" align="center" mt={4}>Por favor, elija el origen de la venta directa</Typography>
                        </Box>
                    </Grid>

                    <Grid 
                        className="mt-25 d-25" 
                        mb={4}
                    > 
                        <Grid
                            container
                            direction="row"
                        >
                            <RadioGroup 
                                row
                                aria-labelledby="segmented-controls-example"
                                name="radio-buttons-group"
                                defaultValue="1"
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel value="1" control={<Radio/>} label="taquillas"/>
                                <FormControlLabel value="2" control={<Radio/>} label="booths"/>
                            </RadioGroup>
                        </Grid>
                    </Grid>

                    <div className="d-flex " >
                        
                        <Grid
                            container 
                            spacing={0}
                            direction="row"
                            className="contBTNVD"
                        >
                            <div>
                                <input 
                                    ref={fileInputRef}
                                    type="file" 
                                    accept=".xlsx, .xls, .csv"
                                    onChange={handleFileChange}
                                    style={{ display:'none' }}
                                    id="ventas_file"
                                    name="ventas_file"
                                />
                                <label htmlFor="ventas_file">
                                    <Button 
                                        variant="contained"
                                        component="label"
                                        startIcon={<CloudUploadIcon />}
                                        onClick={handleButtonClick}
                                    >
                                        Cargar archivo
                                    </Button>
                                </label>
                            </div>

                            <div className="dGrid">
                                <Button
                                    variant="contained"
                                    onClick={handleUpload}
                                    disabled={!selectedFile}
                                    color="success"
                                >
                                    Subir
                                </Button>
                                <Button
                                    variant="contained"
                                    disabled={!selectedFile}
                                    onClick={handleCancel}
                                    color="error"
                                >   
                                    Descartar   
                                </Button>
                            </div>
                        </Grid>
                        
                    </div>

                </Grid>
                

                
            </div>
        </>
        

    )
}
