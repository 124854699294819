import React from "react";
import Main from '../pages/main/main'
import {Commission} from "../pages/commission/commission";
import {Cash} from "../pages/cash/cash";
import {CashBoxPending} from "../pages/cashBoxPending/cashBoxPending";
import {CashBox} from "../pages/cashBox/cashBox";
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import {LayoutAdmin} from '../components/layout/layoutAdmin';
import {LayoutMain} from '../components/layout/layoutMain';
import Form from '../components/form/form';
import {FormCash} from '../components/formCash/formCash';
import {FormSeller} from '../components/formSeller/formSeller';
import {FormCashBoxGeneral} from '../components/formCashBoxGeneral/formCashBoxGeneral';
import {CashClose} from '../components/cashClose/cashClose';
import Payment from '../components/payment/payment';
import {useSelector} from 'react-redux';
import {authSelector} from '../store/auth/auth';
import AuthenticateRoute from './authenticateRoute';
import RedirectIfAuthenticated from './redirectIfAuthenticateRoute';
import {Report} from '../pages/reports/report';
import {Seller} from '../pages/seller/seller';
import {FormSale} from '../pages/formSale/formSale';

export const RoutesComponent = () => {
    const {access_token} = useSelector(authSelector);
    return <BrowserRouter>
        <Routes>
            <Route
                path={''}
                element={
                    <Navigate to={"login"}/>
                }>
            </Route>
            <Route path='login'
                   element={<RedirectIfAuthenticated
                       path={'/login'}
                       redirectPath={'/home'}
                       isAuthenticated={access_token}
                   />}>
            </Route>

            <Route path='home'
                   element={<AuthenticateRoute
                       path={'/home'}
                       layout={LayoutMain}
                       component={Main}
                       authenticatePath={'/login'}
                       isAuthenticated={access_token}
                   />}>
            </Route>
            <Route path='commission'
                   element={<AuthenticateRoute
                       path={'/commission'}
                       layout={LayoutAdmin}
                       component={Commission}
                       authenticatePath={'/login'}
                       isAuthenticated={access_token}
                   />}

            >

            </Route>
            <Route path='commission/create'
                   element={<AuthenticateRoute
                       path={'/commission/create'}
                       layout={LayoutAdmin}
                       component={Form}
                       authenticatePath={'/login'}
                       isAuthenticated={access_token}
                   />}

            >

            </Route>
            <Route path='commission'
                   element={<AuthenticateRoute
                       path={'/commission'}
                       layout={LayoutAdmin}
                       component={Commission}
                       authenticatePath={'/login'}
                       isAuthenticated={access_token}
                   />}

            >

            </Route>
            <Route path='commission/create'
                   element={<AuthenticateRoute
                       path={'/commission/create'}
                       layout={LayoutAdmin}
                       component={Form}
                       authenticatePath={'/login'}
                       isAuthenticated={access_token}
                   />}

            >

            </Route>
            <Route path='cash'
                   element={<AuthenticateRoute
                       path={'/cash'}
                       layout={LayoutAdmin}
                       component={Cash}
                       authenticatePath={'/login'}
                       isAuthenticated={access_token}
                   />}

            >

            </Route>
            <Route path='cash/create'
                   element={<AuthenticateRoute
                       path={'/cash/create'}
                       layout={LayoutAdmin}
                       component={FormCash}
                       authenticatePath={'/login'}
                       isAuthenticated={access_token}
                   />}

            >

            </Route>
            <Route path='cash/increment/:data'
                   element={<AuthenticateRoute
                       path={'/cash/increment'}
                       layout={LayoutAdmin}
                       component={FormCash}
                       authenticatePath={'/login'}
                       isAuthenticated={access_token}
                   />}

            >

            </Route>

            <Route path='increment/cashBox/:id_detail/:type_movement'
                   element={<AuthenticateRoute
                       path={'/cash'}
                       layout={LayoutAdmin}
                       component={FormCashBoxGeneral}
                       authenticatePath={'/login'}
                       isAuthenticated={access_token}
                   />}

            >

            </Route>
            <Route path='increment/cashBox'
                   element={<AuthenticateRoute
                       path={'increment/cashBox'}
                       layout={LayoutAdmin}
                       component={CashBox}
                       authenticatePath={'/login'}
                       isAuthenticated={access_token}
                   />}

            >

            </Route>
            <Route path='increment/cash'
                   element={<AuthenticateRoute
                       path={'increment/cash'}
                       layout={LayoutAdmin}
                       component={CashBoxPending}
                       authenticatePath={'/login'}
                       isAuthenticated={access_token}
                   />}

            >

            </Route>

            <Route path='cash/close/:data'
                   element={<AuthenticateRoute
                       path={'/cash/close'}
                       layout={LayoutAdmin}
                       component={CashClose}
                       authenticatePath={'/login'}
                       isAuthenticated={access_token}
                   />}

            >

            </Route>
            <Route path='reports'
                   element={<AuthenticateRoute
                       path={'/reports'}
                       layout={LayoutAdmin}
                       component={Report}
                       authenticatePath={'/login'}
                       isAuthenticated={access_token}
                   />}

            >

            </Route>
            <Route path='commission/:sku'
                   element={<AuthenticateRoute
                       path={'/commission'}
                       layout={LayoutAdmin}
                       component={Form}
                       authenticatePath={'/login'}
                       isAuthenticated={access_token}
                   />}

            >

            </Route>
            <Route path='commission/:sku/:intAgent'
                   element={<AuthenticateRoute
                       path={'/commission'}
                       layout={LayoutAdmin}
                       component={Form}
                       authenticatePath={'/login'}
                       isAuthenticated={access_token}
                   />}

            >

            </Route>
            <Route path='payment/:sku'
                   element={<AuthenticateRoute
                       path={'/payment'}
                       layout={LayoutAdmin}
                       component={Payment}
                       authenticatePath={'/login'}
                       isAuthenticated={access_token}
                   />}
            >
            </Route>
            <Route path='payment/:sku/:intAgent'
                   element={<AuthenticateRoute
                       path={'/payment'}
                       layout={LayoutAdmin}
                       component={Payment}
                       authenticatePath={'/login'}
                       isAuthenticated={access_token}
                   />}
            >
            </Route>
            <Route path='seller'
                   element={<AuthenticateRoute
                       path={'/sellers'}
                       layout={LayoutAdmin}
                       component={Seller}
                       authenticatePath={'/login'}
                       isAuthenticated={access_token}
                   />}
            >
            </Route>
            <Route path='seller/:sku'
                   element={<AuthenticateRoute
                       path={'/seller'}
                       layout={LayoutAdmin}
                       component={FormSeller}
                       authenticatePath={'/login'}
                       isAuthenticated={access_token}
                   />}

            >

            </Route>
            <Route path='seller/create'
                   element={<AuthenticateRoute
                       path={'/seller/create'}
                       layout={LayoutAdmin}
                       component={FormSeller}
                       authenticatePath={'/login'}
                       isAuthenticated={access_token}
                   />}

            >

            </Route>
            <Route path='reports-sale'
                   element={<AuthenticateRoute
                       path={'/reports-sale'}
                       layout={LayoutAdmin}
                       component={FormSale}
                       authenticatePath={'/login'}
                       isAuthenticated={access_token}
                   />}

            >

            </Route>
            <Route path="*" element={<Navigate to={access_token?'/home':'/login'} replace />} />
        </Routes>
    </BrowserRouter>
}
