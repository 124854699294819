import React from 'react';
import {useSelector} from 'react-redux';
import {BackdropSelector} from "../../store/backdrop/backdrop";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {authSelector,openDialog} from "../../store/auth/auth";
import { useAppDispatch } from '../../store/store'
import { cashOpen} from "../../store/cash/cash";
import { cashIncrement} from "../../store/cashBox/cashBox";
import {unwrapResult} from "@reduxjs/toolkit";
import {openNotifier} from "../../store/notifier/notifier";
import {AxiosError} from "axios";
import {useNavigate} from "react-router-dom";
interface ICashOpen{
    amount:number,
    isIncrementType:boolean,
    detail_id:string,
    type_fund:string
}
export const DialogConfirmation=(data:ICashOpen)=> {

    const {access_token,open_dialog} = useSelector(authSelector);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const confirmClick=()=>{
        dispatch(openDialog(true));
        dispatch(cashIncrement({detail_id:data.detail_id,type_fund:data.type_fund,amount:data.amount})).then(unwrapResult).then((result: any) => {
            navigate("../increment/cashBox", {replace: true})
            dispatch(openDialog(false));
            dispatch(openNotifier({variant: "success", open: true, message: 'success'}))
        }).catch((result: AxiosError) => {
            dispatch(openDialog(false));
            // @ts-ignore
            dispatch(openNotifier({variant: "error", open: true, message: result?.errores?.type_fund_id[0]?result?.errores?.type_fund_id[0]:result?.message}))
        })
    }

    return (
        <div>
            <Dialog
                open={open_dialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {data.isIncrementType?`¿Está seguro que desea incrementar la caja?`:`¿Está seguro que desea reembolsar a la caja?`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {data.isIncrementType?`Se incrementará en la caja ${data.amount} mxn`:`Se reembolsara a la caja ${data.amount} mxn`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=> dispatch(openDialog(false))}>Cerrar</Button>
                    <Button onClick={confirmClick}>
                        {data.isIncrementType?`Incrementar`:`Reembolsar`}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
