
export interface ISeller extends ISellerCreate{
    id: number
}
export interface ISellerUpdate extends ISellerCreate,ISeller{
    status: number
}
export interface ISellerCreate {
    mobile_phone: string
    name: string
    phone: string
    web: string
    intComisionista:null| number

}
export interface  IErrorsSeller{
    message:string
    name?: [string]
    phone?: [string]
    web?: [string]
}
export interface ListISeller{
    sellers:ISellerUpdate[],
    seller:ISellerUpdate,
    seller_commisionable:ISellerUpdate,
    meta: {
        current_page: number,
        per_page: string,
        total: number
    },
    search:string,
    errors:null|IErrorsSeller,
    loadingSellers:boolean
}
export const initialValueSeller={
    id: 0,
    status:0,
    mobile_phone: '',
    name: '',
    phone: '',
    web: '',
    intComisionista:null
}
export const initialValueSellerCreate={
    mobile_phone: '',
    name: '',
    phone: '',
    web: '',
    intComisionista:null
}
