import React from 'react';
import {
    RouteProps,
    Navigate
} from "react-router-dom";

interface IAuthenticateRouteProps extends RouteProps {
    readonly isAuthenticated:boolean,
    readonly authenticatePath:string,
    readonly component:React.ComponentClass<any> | React.FC<any>
    readonly layout?: React.ComponentClass<any> | React.FC<any>
}
const AuthenticateRoute: React.FC<IAuthenticateRouteProps> =
    ({
         isAuthenticated,
         authenticatePath,
         component,
         layout,
         ...props
     }) => {
        const Component = React.createElement(component);
        return !isAuthenticated ?<Navigate to={{pathname: authenticatePath,}} /> : React.createElement(layout ? layout : React.Fragment, null, Component);
    };

export default AuthenticateRoute;
