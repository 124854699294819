import {IDataCommission, IErrorCommission, IRetrieveCommission} from "./commision";

export interface ICashOpen {
    amount_mxn: number,
    amount_usd: number,
}

export const initialCashState={
    amount_mxn: 1,
    amount_usd: 0
}
export interface ICashList{
    date:string,
    user: string,
    cash_closings: [
        {
            type: "Comision",
            initial_fund: number,
            commision_payed: number,
            final_fund: null | number,
            currency: "MXN"
        },
        {
            type: "Taxiplan",
            initial_fund: number,
            commision_payed: number | number,
            final_fund: null,
            currency: "MXN"
        }
    ]
}

export interface ICashListAll{
    data: ICashList
}
export interface ICashPaginate{
    data: ICashList[],
    meta: {
        current_page: number,
        per_page: string,
        total: number
    },
    search:string,
    errors: IErrorCommission | null,
    loadingCash: boolean
}

export interface ICashPending{
    id:number,
    amount:number,
    initial_fund:number,
    currency:string,
    date:string,
    status:string,
    type:string
}
export interface ICashListPending{
    data: ICashPending[],
    meta: {
        current_page: number,
        per_page: string,
        total: number
    },
    search:string,
    errors: IErrorCommission | null,
    loadingCashBox: boolean
}
