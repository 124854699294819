import {ISeller} from './seller'


export interface ICommissionPaginate {
    data: IDataCommission[],
    meta: {
        current_page: number,
        per_page: string,
        total: number
    },
    search:string,
    commission: IRetrieveCommission | null |[],
    errors: IErrorCommission | null,
    loading: boolean,
    new_seller:boolean,
    new_commisionable_agent:boolean
    update_seller:boolean,
    update_commisionable_agent:boolean,
    update_comissionable_agent_info?:  ISeller | undefined,
    update_seller_info?: ISeller | undefined,
    delete_array:number[]
}

export interface IDataCommission {
    intAgent: number | null;
    adult: number
    adult_amount: number
    children: number
    intEstado?: boolean,
    idReserva?: number,
    children_amount: number
    comment: string
    date_operation: string
    infant: number
    product: IProduct[]
    folio: string
    hotel_cruise: IHotelCruise
    id: number
    incidence: string
    seller: ISeller
    transaction_status: TransactionStatus
    transaction_type: TransactionType
}

interface IProduct {
    id: number
    name: string
}

export interface IHotelCruise {
    id: number,
    name: string,
    type: HotelCruise
}


type HotelCruise = 'hotel' | 'cruise'

export enum TransactionStatus {
    Confirmada = 1,
    Cancelada = 2,
    NoShow = 3,
    Reembolso = 4,
    Pendiente = 5,
    Pagado = 6,
}

export enum TransactionType {
    Regular = 1,
    Cortesia = 2,
    FamTrip = 2,
    Comision = 4,
    Transporte = 5,
}

export interface ICommission {
    hotel_cruise_id: number,
    customer_name: string,
    seller_id: number,
    enterprise_id: number,
    incidence: string,
    details: IItems[],
    point_sale:string,
    transaction_status:TransactionStatus
    loading?: boolean
}

export interface ItransactionStatus{
    id: number,
    name: string
}
export interface IItems {
    id: number,
    adult: number,
    children: number,
    infant: number,
    comment: string
    adult_amount?:number,
    detail_id?:number,
    child_amount?:number,
}

interface Enterprise {
    name: string,
    id: number
}

export interface IRetrieveCommission {
    id: number,
    enterprise: Enterprise
    folio: string,
    customer_email: string | null,
    customer_name: string,
    transaction_type: TransactionType,
    amount: number,
    adult: number,
    children: number,
    adult_amount: number,
    children_amount: number,
    transaction_status: TransactionStatus,
    seller: ISeller,
    incidence: string,
    hotel_cruise: IHotelCruise,
    details: IDetailsRetrieve[],
    loading: boolean
}

export interface IDetailsRetrieve{
    id: number,
    detail_id:number,
    adult: number,
    children: number,
    infant: number,
    adult_amount: number,
    children_amount: number,
    comment: string
}
export interface ICreateCommission {
    hotel_cruise_id: number,
    customer_name: string,
    point_sale:string,
    commissionable_agent?:number |null |undefined,
    transaction_status: TransactionStatus
    seller_id?: number |undefined,
    enterprise_id: number,
    incidence: string,
    new_seller?:boolean,
    new_commissionable?:boolean,
    new_seller_info?: seller_commisionable_agent_info | null,
    new_comissionable_agent_info?:  seller_commisionable_agent_info | null,
    update_seller?:boolean,
    update_commissionable?:boolean,
    update_comissionable_agent_info?:  ISeller | undefined,
    update_seller_info?: ISeller | undefined,

    details: Array<{
        id: number,
        adult: number,
        children: number,
        infant: number,
        detail_id:number
        comment: string
        adult_amount?:number,
        children_amount?:number,

    }>
}

export interface seller_commisionable_agent_info{
    seller_name: string,
    seller_phone: string,
    seller_web: string
}


export interface IErrorCommission {
    errores: IError,
    message?: string
}

interface IError {
    customer_name?: [string],
    details?: [string],
    enterprise_id?: [string],
    hotel_cruise_id?: [string],
    seller_id?: [string],
}

export interface IDetailsRetrieve {
    product: {
        id: number,
        name: string
    },
    adult: number,
    point_sale:pointSaleType,
    children: number,
    infant: number,
    date_operation: string,
    adult_amount: number,
    children_amount: number,
    comment: string
}

export type pointSaleType = 'puerta-maya' | 'others'
export const InitialValuesCommission = {
    hotel_cruise_id: 0,
    customer_name: "taxi plan playamia",
    point_sale: 'puerta-maya',
    commissionable_agent:null,
    new_commissionable:false,
    new_seller:false,
    transaction_status:TransactionStatus.Pendiente,
    seller_id: 0,
    enterprise_id: 0,
    new_seller_info: null,
    new_comissionable_agent_info: null,
    update_comissionable_agent_info: undefined,
    update_seller_info:  undefined,
    incidence: "",
    details: []
}
