import React, {useState, useEffect, useRef} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {Typography, TextField, Button} from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import {Enterprise, InitialValueEnterprise, InitialValueProduct} from '../../models/enterprise'
import Autocomplete from '@mui/material/Autocomplete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useAppDispatch} from "../../store/store";
import {hotelCruisePaginate,CruisePaginateToday, HotelCruiseSelector} from "../../store/hotelCruise/hotelCruise";
import CircularProgress from '@mui/material/CircularProgress';
import {
    enterpriseList,
    productsByEnterprise,
    EnterpriseSelector,
    ProductsSelector
} from "../../store/enterprises/enterprise";

import {
    commissionCreate,
    commissionUpdate,
    commissionRetrieve,
    CommissionSelector,
    resetCommission,
    changeNewSeller,
    changeNewCommisionableAgent,
    resetStateNewSellerNewCommisionable,
    deleteArrayPush
} from "../../store/commission/commision";
import {SellersSelector, sellerList, resetSellers, resetSeller, findSeller,findSellerCommsionable,resetSellerCommisionable} from "../../store/sellers/sellers";
import {
    InitialValuesCommission,
    ICreateCommission,
    IItems,
    IDetailsRetrieve,
    TransactionStatus,
} from "../../models/commision";
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';
import {useFormik} from 'formik';
import AddIcon from '@mui/icons-material/Add';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {openNotifier} from "../../store/notifier/notifier";
import {unwrapResult} from "@reduxjs/toolkit";
import {AxiosError} from "axios";
import {Product} from "../../models/enterprise";
import {DateField} from '../dateField/dateField'
import {initialValueSeller, ISeller} from "../../models/seller";
import {IHotelCruise, InitialValueHotelCruise} from "../../models/hotelCruise";
import Backdrop from '@mui/material/Backdrop';




interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}


export const Form = () => {
    const [value, setValue] = useState(0);
    const [detailError, setDetailError] = useState(false);
    const commissionAgentRef = useRef<HTMLDivElement>();
    const customerNameRef = useRef<HTMLDivElement>();
    const detailsRef = useRef(null);
    const enterpriseIdRef = useRef(null);
    const hotelCruiseIdRef = useRef(null);
    const pointSaleRef = useRef(null);
    const sellerIdRef = useRef(null);
    const {sku,intAgent} = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(enterpriseList({}))
        dispatch(sellerList({}))
        dispatch(CruisePaginateToday({}))

    }, [dispatch]);
    const {enterprises, loading} = useSelector(EnterpriseSelector);
    const {sellers, loadingSellers,seller_commisionable,seller} = useSelector(SellersSelector);
    const {hotels_cruises, loadingHotelCruise} = useSelector(HotelCruiseSelector);
    const {loadingProducts, products} = useSelector(ProductsSelector);
    const {commission, loadingCommission, errors,new_seller,new_commisionable_agent,update_seller,update_commisionable_agent} = useSelector(CommissionSelector);


    const {
        setFieldValue,
        setValues,
        values,
        handleChange,
        submitForm,
        handleBlur,
        setSubmitting
    } = useFormik<ICreateCommission>({
        initialValues: InitialValuesCommission,
        onSubmit: (values: ICreateCommission, formikHelpers) => {
            setSubmitting(false);
            let {commissionable_agent,new_comissionable_agent_info,update_seller,update_commissionable,update_comissionable_agent_info,update_seller_info,...updateValuesWithOutCommissionAgent} = values;
            const commisionCreateWithoutSellerIdCommisionableAgent:ICreateCommission={
                hotel_cruise_id: values.hotel_cruise_id,
                seller_id:new_seller?undefined:values.seller_id,
                commissionable_agent: (values.point_sale === 'others' || new_commisionable_agent)?undefined:values.commissionable_agent,
                customer_name: values.customer_name,
                point_sale:values.point_sale,
                transaction_status: values.transaction_status,
                enterprise_id: values.enterprise_id,
                incidence: values.incidence,
                new_seller:new_seller,
                new_commissionable:new_commisionable_agent,
                new_seller_info: values.new_seller_info,
                new_comissionable_agent_info: values.new_comissionable_agent_info,
                update_seller:((new_seller===false)&&((values.update_seller_info?.web!==seller.web) ||(values.update_seller_info?.phone!==seller.phone))),
                update_commissionable:values.point_sale === 'others'?false:((new_commisionable_agent===false)&&((values.update_comissionable_agent_info?.web!==seller_commisionable.web) ||(values.update_comissionable_agent_info?.phone!==seller_commisionable.phone))),
                update_comissionable_agent_info:values.point_sale === 'others'?undefined:values.update_comissionable_agent_info,
                update_seller_info: values.update_seller_info,
                details: values.details
            };
            const inUpdateValues: ICreateCommission = values.point_sale === 'puerta-maya' ? values : updateValuesWithOutCommissionAgent;
            if (sku) {
                dispatch(commissionUpdate({
                    id: sku,
                    commission: ((new_seller || new_commisionable_agent)?commisionCreateWithoutSellerIdCommisionableAgent:inUpdateValues)
                })).then(unwrapResult).then((result: any) => {
                    navigate("../commission", {replace: true})
                    dispatch(openNotifier({variant: "success", open: true, message: 'success'}))
                }).catch((result: AxiosError) => {
                    // @ts-ignore
                    dispatch(openNotifier({variant: "error", open: true, message: result?.message}))
                })
            } else {
                dispatch(commissionCreate({commission: ((new_seller || ((values.update_comissionable_agent_info?.web!==seller_commisionable.web) ||(values.update_comissionable_agent_info?.phone!==seller_commisionable.phone)) ||((values.update_seller_info?.web!==seller.web) ||(values.update_seller_info?.phone!==seller.phone)))?commisionCreateWithoutSellerIdCommisionableAgent:inUpdateValues)})).then(unwrapResult).then((result: any) => {
                    navigate("../commission", {replace: true})
                    dispatch(openNotifier({variant: "success", open: true, message: 'success'}))
                }).catch((result: AxiosError) => {
                    // @ts-ignore
                    dispatch(openNotifier({variant: "error", open: true, message: result?.message}))
                })
            }

        },
    })

    const changeTabs = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue === 0) {
            setValue(newValue);
        }
        if (newValue === 1 && values.seller_id !== 0) {
            setValue(newValue);
        }
        // if (newValue === 2 && (values.customer_name !== '' && values.details.length > 0 && values.enterprise_id !== 0 && values.enterprise_id !== 0 && values.hotel_cruise_id !== 0)) {
        if (newValue === 2 && (  values.details.length > 0 && values.enterprise_id !== 0 && values.enterprise_id !== 0)) {
            setValue(newValue);
        }

    };

    const checkTab1 = () => {
        const customer = sellerIdRef.current;
        const commission = commissionAgentRef.current;
        // @ts-ignore
        if (values.seller_id === 0 && !new_seller) {
            // @ts-ignore
            if (customer.classList.contains('error')) {
                // @ts-ignore
            } else {
                // @ts-ignore
                customer.className = "error";
            }
        }
        if (values.point_sale === 'puerta-maya' && !new_commisionable_agent) {
            if (values.commissionable_agent === null) {
                // @ts-ignore
                if (commission.classList.contains('error')) {
                    // @ts-ignore
                } else {
                    // @ts-ignore
                    commission.className = "error";
                }
            }
        }
    }
    const checkTab2 = () => {
        const customerName = customerNameRef.current;
        const details = detailsRef.current;
        const enterprise = enterpriseIdRef.current;
        const hotelCruise = hotelCruiseIdRef.current;
        const pointSale = pointSaleRef.current;
        // @ts-ignore
        // if (values.customer_name === '') {
        //     // @ts-ignore
        //     customerName.className = "MuiOutlinedInput-input MuiInputBase-input css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input error";
        // } else {
        //     // @ts-ignore
        //     if (customerName.classList.contains('error')) {
        //         // @ts-ignore
        //         customerName.className = "MuiOutlinedInput-input MuiInputBase-input css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input";
        //     }
        //
        // }
        // @ts-ignore
        if (values.enterprise_id === 0) {
            // @ts-ignore
            enterprise.className = "error";
        }
        // @ts-ignore
        // if (values.hotel_cruise_id === 0) {
        //
        //     // @ts-ignore
        //     hotelCruise.className = "error";
        // }
        if (values.details.length > 0) {
            if(detailError){
                setDetailError(false)
            }

            values.details.map((detail, index) => {
                const inputPackage = document.querySelectorAll(`#details-autocomplete-${index}`);
                const inputAdult = document.querySelectorAll(`#details-${index}-adult`);
                    if(detail.adult===0){
                        inputAdult[0].className="MuiOutlinedInput-input MuiInputBase-input MuiInputBase-inputTypeSearch MuiInputBase-inputAdornedEnd MuiAutocomplete-input MuiAutocomplete-inputFocused css-1j5luls-MuiInputBase-input-MuiOutlinedInput-input error"
                    } else {
                        inputAdult[0].className="MuiOutlinedInput-input MuiInputBase-input MuiInputBase-inputTypeSearch MuiInputBase-inputAdornedEnd MuiAutocomplete-input MuiAutocomplete-inputFocused css-1j5luls-MuiInputBase-input-MuiOutlinedInput-input"
                    }
                    if(detail.id===0){
                        inputPackage[0].className="MuiOutlinedInput-input MuiInputBase-input MuiInputBase-inputTypeSearch MuiInputBase-inputAdornedEnd MuiAutocomplete-input MuiAutocomplete-inputFocused css-1j5luls-MuiInputBase-input-MuiOutlinedInput-input error"
                    } else {
                       inputPackage[0].className="MuiOutlinedInput-input MuiInputBase-input MuiInputBase-inputTypeSearch MuiInputBase-inputAdornedEnd MuiAutocomplete-input MuiAutocomplete-inputFocused css-1j5luls-MuiInputBase-input-MuiOutlinedInput-input"
                    }


            })

        } else {
            if(!detailError){
                setDetailError(true)
            }
        }
    }


    const validateTab = () => {
        if (value === 0) {
            checkTab1()
        }
        if (value === 1) {
            checkTab2()
        }
        if(values.point_sale==='puerta-maya' && new_seller && (values.new_seller_info?.seller_name && values.new_seller_info?.seller_phone && values.new_seller_info?.seller_web) && new_commisionable_agent && (values.new_comissionable_agent_info?.seller_web && values.new_comissionable_agent_info.seller_name && values.new_comissionable_agent_info.seller_phone)){
            setValue(1)
        }
        if(!new_commisionable_agent && values.point_sale==='puerta-maya' && new_seller && (values.new_seller_info?.seller_name && values.new_seller_info?.seller_phone && values.new_seller_info?.seller_web) && new_commisionable_agent && (values.new_comissionable_agent_info?.seller_web && values.new_comissionable_agent_info.seller_name && values.new_comissionable_agent_info.seller_phone)){
            setValue(1)
        }
        if(!new_commisionable_agent && values.point_sale==='puerta-maya' && new_seller && (values.new_seller_info?.seller_name && values.new_seller_info?.seller_phone && values.new_seller_info?.seller_web) ){
            setValue(1)
        }
        if (value === 0 && values.seller_id !== 0 && (values.point_sale === 'puerta-maya' ? (values.commissionable_agent !== null) : ((values.commissionable_agent === null) ||(values.commissionable_agent === undefined)))) {
            setValue(1)
        }

        if(!new_seller && values.point_sale==='puerta-maya' && new_commisionable_agent && (values.new_comissionable_agent_info?.seller_name && values.new_comissionable_agent_info?.seller_phone && values.new_comissionable_agent_info?.seller_web)){
            setValue(1)
        }

        if(values.point_sale==='others' && values.seller_id!==0 && !new_seller){
            setValue(1)
        }
        if(values.point_sale==='others' && new_seller && (values.new_seller_info?.seller_name && values.new_seller_info?.seller_phone && values.new_seller_info?.seller_web)){
            setValue(1)
        }
        if(values.point_sale==='others' && new_seller && values.new_seller_info?.seller_name && values.new_seller_info?.seller_phone && values.new_seller_info?.seller_web){
            setValue(1)
        }
        // if (value === 1 && (values.customer_name !== '' && values.details.length > 0 && values.enterprise_id !== 0 && values.enterprise_id !== 0 && values.hotel_cruise_id !== 0)) {
        if (value === 1 && (  values.details.length > 0 && values.enterprise_id !== 0 && values.enterprise_id !== 0 )) {
            setValue(2)
        } else {
        }

    }

    useEffect(() => {

        if (sku) {
            if(intAgent){
                // @ts-ignore
                dispatch(commissionRetrieve({id:sku,intAgent}))
            } else {
                // @ts-ignore
                dispatch(commissionRetrieve(sku))
            }

        }
    }, [dispatch, sku]);
    useEffect(() => {
        if (commission?.id) {
            // @ts-ignore
            dispatch(findSeller(commission.seller.id))
            dispatch(findSellerCommsionable(commission.commissionable_agent?.id))
            const detailUpdate = Array.from(commission.details, (detail: IDetailsRetrieve) => {
                return {
                    id: detail?.product?.id,
                    adult: detail?.adult,
                    children: detail?.children,
                    detail_id:detail?.detail_id,
                    infant: detail?.infant,
                    date_operation: detail?.date_operation,
                    adult_amount: detail?.adult_amount,
                    children_amount: detail?.children_amount,
                    comment: detail?.comment
                }
            })

            setValues({
                hotel_cruise_id: commission?.hotel_cruise?.id,
                point_sale: commission?.point_sale,
                commissionable_agent: commission?.commissionable_agent?.id,
                customer_name: commission?.customer_name,
                seller_id: commission?.seller?.id,
                enterprise_id: commission?.enterprise?.id,
                transaction_status: commission?.transaction_status?.id,
                incidence: commission?.incidence===null?'':commission?.incidence,
                details: detailUpdate
            })
        }
    }, [commission]);

    // @ts-ignore
    return (
        <>

            {
                loadingCommission ? <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={true}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop> : <form onSubmit={submitForm} className="form-login">
                    <Box className="content-panel"
                         sx={{flexGrow: 1, display: 'flex', width: '100%'}}
                    >

                        <Button variant="outlined" size="small" className="btn-atras btn-actions"
                                onClick={() => {
                                    dispatch(resetCommission())
                                    dispatch(resetStateNewSellerNewCommisionable())
                                    dispatch(resetSellers())
                                    dispatch(resetSeller())
                                    dispatch(resetSellerCommisionable())
                                    navigate("../commission", {replace: true})
                                }
                                }><ArrowBackIcon
                            fontSize="small"/>&nbsp; Atras</Button>
                        <div className="contTabs">
                            <div>
                                <Tabs
                                    orientation="vertical"
                                    variant="scrollable"
                                    value={value}
                                    onChange={changeTabs}
                                    aria-label="Vertical tabs example"
                                    sx={{borderRight: 1, borderColor: 'divider'}}
                                >
                                    <Tab label="Datos Taxista" {...a11yProps(0)} />
                                    <Tab label="Datos Reserva" {...a11yProps(1)} />
                                    <Tab label="Incidencias" {...a11yProps(2)} />
                                </Tabs>
                            </div>

                            <div>
                                <TabPanel value={value} index={0}>
                                    <div className="grid-1">

                                        <FormControl>




                                            <FormLabel id="demo-radio-buttons-group-label">Origen de venta</FormLabel>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                value={values.point_sale}
                                                onChange={(event) => {
                                                    setFieldValue('point_sale', event.currentTarget.value)

                                                    if(event.currentTarget.value==='others'){
                                                        if(new_commisionable_agent){
                                                            dispatch(changeNewCommisionableAgent())
                                                        }
                                                    }
                                                }}
                                                name="point_sale_id"
                                                row
                                            >
                                                <FormControlLabel
                                                    value="puerta-maya"
                                                    disabled={sku!==undefined}
                                                    control={<Radio/>}
                                                                  label="Comisionista"/>
                                                <FormControlLabel
                                                    value="others"
                                                    disabled={sku!==undefined}
                                                    control={<Radio/>}
                                                    label="Taxiplan"/>
                                            </RadioGroup>
                                        </FormControl>
                                        {
                                            !new_seller && <>
                                                <>
                                                    <Autocomplete
                                                        freeSolo
                                                        ref={sellerIdRef}
                                                        className={''}
                                                        id="seller_id"
                                                        disableClearable={true}
                                                        loading={loadingSellers}
                                                        defaultValue={{
                                                            id: 0,
                                                            mobile_phone: '',
                                                            name: '',
                                                            phone: '',
                                                            web: '',
                                                            intComisionista: null
                                                        }
                                                        }

                                                        options={loadingSellers ? []:sellers.filter((seller: ISeller) => (seller.id !== values.commissionable_agent && !seller.intComisionista))}
                                                        // options={seller.filter((seller: ISeller) => (seller.id !== values.commissionable_agent && !seller.intComisionista))}
                                                        onChange={(e, value) => {
                                                            // @ts-ignore
                                                            setFieldValue("seller_id", value.id)
                                                            // @ts-ignore
                                                            let seller=sellers.find((seller: ISeller) => seller.id === value.id);
                                                            setFieldValue("update_seller_info.web",seller?.web??'')
                                                            setFieldValue("update_seller_info.phone",seller?.phone??'')
                                                            setFieldValue("update_seller_info.mobile_phone",seller?.mobile_phone??'')
                                                            setFieldValue("update_seller_info.name",seller?.name??'');
                                                            setFieldValue("update_seller_info.intComisionista",seller?.intComisionista??'');
                                                            // @ts-ignore
                                                            dispatch(findSeller(value.id))

                                                        }}
                                                        onInputChange={(event, newInputValue, reason) => {
                                                            if (reason === 'input') {
                                                                // @ts-ignore
                                                                setFieldValue("seller_id", 0)
                                                                // @ts-ignore
                                                                setFieldValue("update_seller_info", null)
                                                            }
                                                            if(newInputValue===''){
                                                                setFieldValue("seller_id", 0)
                                                                // @ts-ignore
                                                                setFieldValue("update_seller_info", null)
                                                            }

                                                        }}
                                                        value={loadingSellers ? initialValueSeller:sellers.find((sellerFind: ISeller) => values.seller_id === sellerFind.id) ?? initialValueSeller}
                                                        isOptionEqualToValue={(option: ISeller, value: ISeller) => option.id === value.id}
                                                        renderOption={(props, option) => {
                                                            // @ts-ignore
                                                            const key = option.id;
                                                            // @ts-ignore
                                                            const name = option.name
                                                            return (
                                                                <li {...props} key={key}>
                                                                    {name}
                                                                </li>
                                                            );
                                                        }}
                                                        // @ts-ignore
                                                        getOptionLabel={(option) => option.name}
                                                        renderInput={(params) => (
                                                            <div style={{display:'flex'}}>
                                                                <TextField
                                                                    error={(errors?.errores?.seller_id?.length > 0 ?? false) && values.seller_id === 0}
                                                                    helperText={errors?.errores?.seller_id?.length > 0 ? errors?.errores?.seller_id[0] : ''}
                                                                    name={'seller_id'}
                                                                    {...params}
                                                                    label="Nombre del Taxista"
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        type: 'search',
                                                                        endAdornment: (
                                                                            <React.Fragment>
                                                                                {loadingSellers ? <Box sx={{width: '100%'}}>
                                                                                    <LinearProgress/>
                                                                                </Box> : null}
                                                                                {params.InputProps.endAdornment}
                                                                            </React.Fragment>)
                                                                    }}
                                                                />
                                                                {
                                                                    !new_seller &&  <Button
                                                                        className="btnAddUser"
                                                                        variant="contained"
                                                                        disabled={values.transaction_status===TransactionStatus.Pagado}
                                                                        onClick={()=> dispatch(changeNewSeller())}
                                                                        sx={{background: '#1976d2'}}
                                                                    >Agregar &nbsp;<PersonAddIcon/></Button>
                                                                }


                                                            </div>
                                                        )}
                                                    />

                                                </>
                                                {
                                                    values.seller_id !== 0 && <div className="grid-2">

                                                        <TextField
                                                            id="outlined-basic"
                                                            label="Número de Taxi"
                                                            variant="outlined"
                                                            type="tel"
                                                            name={'taxi_number'}
                                                            disabled={!!sku}
                                                            onChange={(event)=>{
                                                                setFieldValue("update_seller_info.web", event.currentTarget.value)
                                                            }}
                                                            value={sku?sellers.find((seller: ISeller) => seller.id === values.seller_id)?.web ?? '':values.update_seller_info?.web}
                                                        />
                                                        <TextField
                                                            id="outlined-basic"
                                                            label="Numero de Gafete"
                                                            variant="outlined"
                                                            disabled
                                                            type="email"
                                                            name={'taxi_gafete'}
                                                            value={sku?sellers.find((seller: ISeller) => seller.id === values.seller_id)?.phone ?? '':values.update_seller_info?.phone}
                                                            onChange={(event)=>{
                                                                setFieldValue("update_seller_info.phone", event.currentTarget.value)
                                                            }}
                                                        />
                                                    </div>
                                                }

                                            </>
                                        }
                                        {
                                            new_seller && <>
                                                <>
                                                    <div style={{display:'flex'}}>
                                                        <TextField
                                                            id="outlined-basic"
                                                            // error={(errors?.errores?.customer_name?.length > 0 ?? false) && values.customer_name === ''}
                                                            // helperText={errors?.errores?.customer_name?.length > 0 ? errors?.errores?.customer_name[0] : ''}
                                                            label="Nombre del Taxista"
                                                            variant="outlined"
                                                            type="text"
                                                            name={'new_seller_info.seller_name'}
                                                            onChange={(event) => {
                                                                setFieldValue('new_seller_info.seller_name', event.currentTarget.value)
                                                            }}
                                                            value={values.new_seller_info?.seller_name}
                                                            style={{width:'100%'}}
                                                        />
                                                    {
                                                        new_seller &&  <Button
                                                            className="btnAddUser"
                                                            variant="contained"
                                                            onClick={()=> dispatch(changeNewSeller())}
                                                            sx={{background: '#d32d20', "&:hover": {backgroundColor: '#d32d20'}}}
                                                        >Cancelar &nbsp;<PersonAddIcon/></Button>
                                                    }
                                                    </div>
                                                </>
                                                {
                                                    <div className="grid-2">

                                                        <TextField
                                                            id="outlined-basic"
                                                            label="Número de Taxi"
                                                            variant="outlined"
                                                            type="tel"
                                                            name={'taxi_number'}
                                                            onChange={(event) => {
                                                                setFieldValue('new_seller_info.seller_web', event.currentTarget.value)
                                                            }}
                                                            value={values.new_seller_info?.seller_web?values.new_seller_info?.seller_web: ''}
                                                        />
                                                        <TextField
                                                            id="outlined-basic"
                                                            label="Numero de Gafete"
                                                            variant="outlined"
                                                            type="email"
                                                            name={'taxi_gafete'}
                                                            onChange={(event) => {
                                                                setFieldValue('new_seller_info.seller_phone', event.currentTarget.value)
                                                            }}
                                                            value={values.new_seller_info?.seller_phone?values.new_seller_info?.seller_phone: ''}

                                                        />
                                                    </div>
                                                }
                                            </>
                                        }
                                        <div className="grid-1">
                                            {
                                                !new_commisionable_agent && <>
                                                    <div className={''}>
                                                        {
                                                            values.point_sale === 'puerta-maya' && <Autocomplete
                                                                freeSolo
                                                                id="commissionable_agent"
                                                                ref={commissionAgentRef}
                                                                disableClearable
                                                                loading={loadingSellers}
                                                                defaultValue={{
                                                                    id: 0,
                                                                    mobile_phone: '',
                                                                    name: '',
                                                                    phone: '',
                                                                    web: '',
                                                                    intComisionista: null
                                                                }
                                                                }
                                                                options={loadingSellers?[]:sellers.filter((seller: ISeller) => (seller.id !== values.seller_id)&&seller.intComisionista)}
                                                                onChange={(e, value) => {
                                                                    // @ts-ignore
                                                                    setFieldValue("commissionable_agent", value.id)
                                                                    // @ts-ignore
                                                                    let commissionable=sellers.find((seller: ISeller) => seller.id === value.id);
                                                                    // @ts-ignore
                                                                    setFieldValue("commissionable_agent", value.id)
                                                                    // @ts-ignore
                                                                    setFieldValue("update_comissionable_agent_info.web", commissionable?.web ?? '')
                                                                    // @ts-ignore
                                                                    setFieldValue("update_comissionable_agent_info.phone", commissionable?.phone ?? '')
                                                                    setFieldValue("update_comissionable_agent_info.mobile_phone", commissionable?.mobile_phone ?? '');
                                                                    setFieldValue("update_comissionable_agent_info.name", commissionable?.name ?? '')
                                                                    setFieldValue("update_comissionable_agent_info.intComisionista", commissionable?.intComisionista ?? '')
                                                                    // @ts-ignore
                                                                    dispatch(findSellerCommsionable(value.id))
                                                                }}
                                                                onInputChange={(event, newInputValue, reason) => {
                                                                    if (reason === 'input') {
                                                                        // @ts-ignore
                                                                        setFieldValue("commissionable_agent", null);
                                                                        // @ts-ignore
                                                                        setFieldValue("update_comissionable_agent_info", null)
                                                                    }
                                                                    if(newInputValue===''){
                                                                        setFieldValue("commissionable_agent", null)
                                                                        // @ts-ignore
                                                                        setFieldValue("update_comissionable_agent_info", null)
                                                                    }
                                                                }}
                                                                value={loadingSellers ? initialValueSeller:(sellers.find((sellerFind: ISeller) => values.commissionable_agent === sellerFind.id) && values.point_sale === 'puerta-maya') ? sellers.find((sellerFind: ISeller) => values.commissionable_agent === sellerFind.id) : initialValueSeller}

                                                                isOptionEqualToValue={(option: ISeller, value: ISeller) => option.id === value.id}
                                                                renderOption={(props, option) => {
                                                                    // @ts-ignore
                                                                    const key = option.id;
                                                                    // @ts-ignore
                                                                    const name = option.name
                                                                    return (
                                                                        <li {...props} key={key}>
                                                                            {name}
                                                                        </li>
                                                                    );
                                                                }}
                                                                // @ts-ignore
                                                                getOptionLabel={(option) => option.name}
                                                                renderInput={(params) => (
                                                                    <div style={{display:'flex'}}>
                                                                        <TextField
                                                                            error={(errors?.errores?.seller_id?.length > 0 ?? false) && values.seller_id === 0}
                                                                            helperText={errors?.errores?.seller_id?.length > 0 ? errors?.errores?.seller_id[0] : ''}
                                                                            name={'commissionable_agent'}
                                                                            {...params}
                                                                            label="Nombre del Vendedor"
                                                                            InputProps={{
                                                                                ...params.InputProps,
                                                                                type: 'search',
                                                                                endAdornment: (
                                                                                    <React.Fragment>
                                                                                        {loadingSellers ? <Box sx={{width: '100%'}}>
                                                                                            <LinearProgress/>
                                                                                        </Box> : null}
                                                                                        {params.InputProps.endAdornment}
                                                                                    </React.Fragment>)
                                                                            }}
                                                                        />
                                                                        {
                                                                            !new_commisionable_agent &&       <Button
                                                                                className="btnAddUser"
                                                                                variant="contained"
                                                                                disabled={values.transaction_status===TransactionStatus.Pagado}
                                                                                onClick={()=> dispatch(changeNewCommisionableAgent())}
                                                                                sx={{background: '#1976d2'}}
                                                                            >Agregar &nbsp;<PersonAddIcon/></Button>
                                                                        }


                                                                    </div>
                                                                )}
                                                            />

                                                        }
                                                    </div>

                                                    {
                                                        values.commissionable_agent !== null && values.point_sale === 'puerta-maya' &&
                                                        <div className="grid-2">

                                                            <TextField
                                                                id="outlined-basic"
                                                                label="Número de Taxi"
                                                                variant="outlined"
                                                                type="tel"
                                                                name={'taxi_number'}
                                                                onChange={(event) => {
                                                                    setFieldValue('update_comissionable_agent_info.web', event.currentTarget.value)
                                                                }}
                                                                disabled={!!sku}
                                                                value={sku?sellers.find((seller: ISeller) => seller.id === values.seller_id)?.web ?? '':values.update_comissionable_agent_info?.web}
                                                            />
                                                            <TextField
                                                                id="outlined-basic"
                                                                label="Numero de Gafete"
                                                                variant="outlined"
                                                                type="email"
                                                                name={'taxi_gafete'}
                                                                disabled
                                                                onChange={(event) => {
                                                                    setFieldValue('update_comissionable_agent_info.phone', event.currentTarget.value)
                                                                }}
                                                                value={sku?sellers.find((seller: ISeller) => seller.id === values.commissionable_agent)?.phone ?? '':values.update_comissionable_agent_info?.phone}

                                                            />
                                                        </div>
                                                    }

                                                </>
                                            }
                                            {
                                                new_commisionable_agent && <>
                                                    <>
                                                    <div style={{display:'flex'}}>
                                                        <TextField
                                                            id="outlined-basic"
                                                            // error={(errors?.errores?.customer_name?.length > 0 ?? false) && values.customer_name === ''}
                                                            // helperText={errors?.errores?.customer_name?.length > 0 ? errors?.errores?.customer_name[0] : ''}
                                                            label="Nombre del vendedor"
                                                            variant="outlined"
                                                            type="text"
                                                            name={'new_comissionable_agent_info.seller_name'}
                                                            onChange={(event) => {
                                                                setFieldValue('new_comissionable_agent_info.seller_name', event.currentTarget.value)
                                                            }}
                                                            value={values.new_comissionable_agent_info?.seller_name}
                                                            style={{width:'100%'}}
                                                        />
                                                        {
                                                            new_commisionable_agent && <Button
                                                                className="btnAddUser"
                                                                variant="contained"
                                                                onClick={()=> dispatch(changeNewCommisionableAgent())}
                                                                sx={{background: '#d32d20', "&:hover": {backgroundColor: '#d32d20'}}}
                                                            >Cancelar &nbsp;<PersonAddIcon/></Button>
                                                        }
                                                    </div>
                                                    </>
                                                    {
                                                         <div className="grid-2">
                                                            <TextField
                                                                id="outlined-basic"
                                                                label="Número de Taxi"
                                                                variant="outlined"
                                                                type="tel"
                                                                name={'taxi_number'}
                                                                onChange={(event) => {
                                                                    setFieldValue('new_comissionable_agent_info.seller_web', event.currentTarget.value)
                                                                }}
                                                                value={values.new_comissionable_agent_info?.seller_web?values.new_comissionable_agent_info?.seller_web: ''}
                                                            />
                                                            <TextField
                                                                id="outlined-basic"
                                                                label="Numero de Gafete"
                                                                variant="outlined"
                                                                type="email"
                                                                name={'taxi_gafete'}
                                                                onChange={(event) => {
                                                                    setFieldValue('new_comissionable_agent_info.seller_phone', event.currentTarget.value)
                                                                }}
                                                                value={values.new_comissionable_agent_info?.seller_phone?values.new_comissionable_agent_info?.seller_phone: ''}

                                                            />
                                                        </div>
                                                    }
                                                </>
                                            }

                                        </div>
                                    </div>
                                </TabPanel>

                                <TabPanel value={value} index={1}>
                                    {
                                        sku &&      <div className="d-flex cont-txt-folio">
                                            <Typography>Folio de la reserva:</Typography>
                                            <Typography className="TextBold">{commission?.folio ?? ''}</Typography>
                                        </div>
                                    }

                                    {/*<div className="grid-1 b-bottom">*/}
                                    {/*    <TextField*/}
                                    {/*        id="outlined-basic"*/}
                                    {/*        inputRef={customerNameRef}*/}
                                    {/*        error={(errors?.errores?.customer_name?.length > 0 ?? false) && values.customer_name === ''}*/}
                                    {/*        helperText={errors?.errores?.customer_name?.length > 0 ? errors?.errores?.customer_name[0] : ''}*/}
                                    {/*        label="Nombre del cliente"*/}
                                    {/*        variant="outlined"*/}
                                    {/*        type="text"*/}
                                    {/*        name={'customer_name'}*/}
                                    {/*        onChange={handleChange}*/}
                                    {/*        onBlur={handleBlur}*/}
                                    {/*        value={values.customer_name}*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                    <div className="grid-1 B-bottom">
                                        <Autocomplete
                                            freeSolo
                                            id="hotel_cruise_id"
                                            disabled={values.transaction_status===TransactionStatus.Pagado}
                                            disableClearable
                                            ref={hotelCruiseIdRef}
                                            loading={loadingHotelCruise}
                                            options={loadingHotelCruise?[]:hotels_cruises}
                                            onChange={(e, value) => {
                                                // @ts-ignore
                                                setFieldValue("hotel_cruise_id", value.id)
                                            }}
                                            onInputChange={(event, newInputValue, reason) => {
                                                if (reason === 'input') {
                                                    // @ts-ignore
                                                    setFieldValue("hotel_cruise_id", 0)
                                                }
                                                if (newInputValue === '') {
                                                    // @ts-ignore
                                                    setFieldValue("hotel_cruise_id", 0)
                                                }
                                            }}
                                            value={loadingHotelCruise?InitialValueHotelCruise:hotels_cruises.find((hotelCruiseFind: IHotelCruise) => values.hotel_cruise_id === hotelCruiseFind.id) ?? InitialValueHotelCruise}
                                            defaultValue={{
                                                id: 0,
                                                name: 'N/A',
                                                type: 'hotel',
                                            }}
                                            isOptionEqualToValue={(option: IHotelCruise, value: IHotelCruise) => option.id === value.id}
                                            renderOption={(props, option) => {
                                                // @ts-ignore
                                                const key = option.id;
                                                // @ts-ignore
                                                const name = option.name
                                                return (
                                                    <li {...props} key={key}>
                                                        {name}
                                                    </li>
                                                );
                                            }}
                                            // @ts-ignore
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => (
                                                <TextField
                                                    className="sd"
                                                    name={'hotel_cruise_id'}
                                                    value={values.hotel_cruise_id}
                                                    error={(errors?.errores?.hotel_cruise_id?.length > 0 ?? false) && values.hotel_cruise_id === 0}
                                                    helperText={errors?.errores?.hotel_cruise_id?.length > 0 ? errors?.errores?.hotel_cruise_id[0] : ''}
                                                    {...params}
                                                    label="Hotel / Crucero"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        type: 'search',
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {loadingHotelCruise ? <Box sx={{width: '100%'}}>
                                                                    <LinearProgress/>
                                                                </Box> : null}
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>)
                                                    }}
                                                />
                                            )}
                                        />


                                    </div>
                                    <div className="mt-25">
                                        <Typography className="TextBold m-bottom">Datos del Tour</Typography>
                                        <div className="grid-1 mb-25">
                                            <Autocomplete
                                                freeSolo
                                                id="enterprise_id"
                                                disabled={values.transaction_status===TransactionStatus.Pagado}
                                                value={loadingProducts?InitialValueEnterprise:enterprises.find((enterpriseFind: Enterprise) => values.enterprise_id === enterpriseFind.id) ?? InitialValueEnterprise}
                                                disableClearable
                                                ref={enterpriseIdRef}
                                                onChange={(event, value) => {
                                                    // @ts-ignore
                                                    dispatch(productsByEnterprise({enterpriseId: value.id}))
                                                    // @ts-ignore
                                                    setFieldValue('enterprise_id', value.id)
                                                }}
                                                onInputChange={(event, newInputValue, reason) => {
                                                    if (reason === 'input') {
                                                        // @ts-ignore
                                                        setFieldValue("enterprise_id", 0)
                                                    }
                                                    if(newInputValue===''){
                                                        setFieldValue("enterprise_id", 0)
                                                    }
                                                }}
                                                options={loadingProducts?[]:enterprises}
                                                isOptionEqualToValue={(option: Enterprise, value: Enterprise) => option.id === value.id}
                                                defaultValue={{
                                                    id: 0,
                                                    name: ''
                                                }}
                                                renderOption={(props, option) => {
                                                    // @ts-ignore
                                                    const key = option.id;
                                                    // @ts-ignore
                                                    const name = option.name
                                                    return (
                                                        <li {...props} key={key}>
                                                            {name}
                                                        </li>
                                                    );
                                                }}
                                                // @ts-ignore
                                                getOptionLabel={(option) => option.name}
                                                renderInput={(params) => (
                                                    <TextField
                                                        className="sd"
                                                        name={'enterprise_id'}
                                                        {...params}
                                                        value={values.enterprise_id}
                                                        error={(errors?.errores?.enterprise_id?.length > 0 ?? false) && values.enterprise_id === 0}
                                                        helperText={errors?.errores?.enterprise_id?.length > 0 ? errors?.errores?.enterprise_id[0] : ''}
                                                        label="Empresa"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            type: 'search',
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {loading ? <Box sx={{width: '100%'}}>
                                                                        <LinearProgress/>
                                                                    </Box> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>)
                                                        }}
                                                    />
                                                )}
                                            />

                                        </div>
                                        {
                                            detailError && <span className={'error-detail'}>you should add details about the package</span>
                                        }
                                        <div key={'keydiv'}>
                                            <Button variant="outlined" size="small"
                                                    color="success"
                                                    disabled={values.transaction_status===TransactionStatus.Pagado}
                                                    onClick={() => setFieldValue('details', values.details.concat(
                                                        {
                                                            id: 0,
                                                            adult: 0,
                                                            children: 0,
                                                            detail_id:0,
                                                            infant: 0,
                                                            comment: ""
                                                        }
                                                    ))}
                                            ><AddIcon/> agregar</Button>

                                            {values.details.length > 0 &&
                                                values.details.map((item: IItems, index: number) => (
                                                    <div key={`div-main-${index}`} className="border-bottom">
                                                        {
                                                            values.details.length > 0 && <Button
                                                                key={`button-remove${index}`}
                                                                disabled={values.transaction_status===TransactionStatus.Pagado}
                                                                onClick={() => {
                                                                    // dispatch(deleteArrayPush(values.details[index]?.detail_id))
                                                                    setFieldValue('details', values.details.filter
                                                                    ((value, innerIndex) => innerIndex !== index
                                                                    ))
                                                                }}
                                                                variant="outlined" size="small"
                                                                color="error"><DeleteOutlineIcon/> Eliminar</Button>

                                                        }

                                                        <div className="grid-2 mt-25" key={`div-grid-${index}`}>
                                                            <Autocomplete
                                                                freeSolo
                                                                id={`details-autocomplete-${index}`}
                                                                key={`autocomplete-details-${index}`}
                                                                disabled={products.length <= 0 ||values.transaction_status===TransactionStatus.Pagado}
                                                                disableClearable
                                                                defaultValue={{
                                                                    id: 0,
                                                                    name: '',
                                                                    rates: {
                                                                        adult: 0,
                                                                        children: 0,
                                                                    },
                                                                    type_tour: '',
                                                                }}
                                                                options={
                                                                    products.filter((product: Product) => !values.details.map(({id}) => id).includes(product.id)
                                                                    )}
                                                                value={products.find((productFind: Product) => values.details[index].id === productFind.id) ?? InitialValueProduct}

                                                                //isOptionEqualToValue={(option: Product, value: Product) => option.id === value.id}
                                                                renderOption={(props, option) => {
                                                                    // @ts-ignore
                                                                    const key = `${option.id}-${index}`;
                                                                    // @ts-ignore
                                                                    const name = option.name
                                                                    return (
                                                                        <li {...props} key={key}>
                                                                            {name}
                                                                        </li>
                                                                    );
                                                                }}
                                                                // @ts-ignore
                                                                getOptionLabel={(option) => option.name}
                                                                onChange={(event, value) => {
                                                                    // @ts-ignore
                                                                    setFieldValue(`details.${index}.id`, value.id)
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        className="sd"
                                                                        name={`details.${index}.id`}
                                                                        error={Boolean((errors?.errores?.[`details.${index}.id`]) && values.details[index].id === 0)}
                                                                        helperText={Boolean((errors?.errores?.[`details.${index}.id`]) && values.details[index].id === 0) ? errors?.errores?.[`details.${index}.id`]?.[0] : ''}
                                                                        value={values.details[index].id}
                                                                        {...params}
                                                                        label="Paquete"
                                                                        InputProps={{
                                                                            ...params.InputProps,
                                                                            type: 'search',
                                                                            endAdornment: (
                                                                                <React.Fragment>
                                                                                    {loadingProducts ?
                                                                                        <Box sx={{width: '100%'}}>
                                                                                            <LinearProgress/>
                                                                                        </Box> : null}
                                                                                    {params.InputProps.endAdornment}
                                                                                </React.Fragment>)
                                                                        }}
                                                                    />
                                                                )}
                                                            />

                                                            <DateField
                                                                index={index}
                                                                date={sku ? commission.details[index]?.date_operation : null}
                                                            />
                                                        </div>
                                                        <div className="grid-3" key={`pax-grid-${index}`}>
                                                            <TextField
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.details[index].adult}
                                                                disabled={values.transaction_status===TransactionStatus.Pagado}
                                                                InputProps={{ inputProps: { min: 1, max: 1000 } }}
                                                                name={`details.${index}.adult`}
                                                                error={Boolean((errors?.errores?.[`details.${index}.adult`]) && values.details[index].adult === 0)}
                                                                helperText={Boolean((errors?.errores?.[`details.${index}.adult`]) && values.details[index].adult === 0) ? errors?.errores?.[`details.${index}.adult`]?.[0] : ''}
                                                                key={`adults-${index}`}
                                                                id={`details-${index}-adult`} label="# Adultos" variant="outlined"
                                                                type="number"/>
                                                            <TextField
                                                                name={`details.${index}.children`}
                                                                disabled={values.transaction_status===TransactionStatus.Pagado}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                InputProps={{inputProps: {min: 0}}}
                                                                value={values.details[index].children}
                                                                key={`child-${index}`}
                                                                id="outlined-basic" label="# Niños" variant="outlined"
                                                                type="number"/>
                                                            <TextField
                                                                name={`details.${index}.infant`}
                                                                disabled={values.transaction_status===TransactionStatus.Pagado}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                InputProps={{inputProps: {min: 0}}}
                                                                value={values.details[index].infant}
                                                                key={`infant-${index}`}
                                                                id="outlined-basic" label="# Infantes"
                                                                variant="outlined"
                                                                type="number"/>
                                                        </div>
                                                        {
                                                            (sku && values.details[index].adult_amount) &&  <div className="grid-3" key={`pax-grid-${index}-sku`}>
                                                                <TextField
                                                                    onChange={handleChange}
                                                                    disabled
                                                                    onBlur={handleBlur}
                                                                    value={values.details[index].adult_amount}
                                                                    name={`details.${index}.adult`}
                                                                    error={Boolean((errors?.errores?.[`details.${index}.adult`]) && values.details[index].adult === 0)}
                                                                    helperText={Boolean((errors?.errores?.[`details.${index}.adult`]) && values.details[index].adult === 0) ? errors?.errores?.[`details.${index}.adult`]?.[0] : ''}
                                                                    key={`adults-amount-${index}`}
                                                                    id={`details-${index}-adult`}
                                                                    label="Adult Amount"
                                                                    // label={`${(sku&&commission.commissionable_agent) ? `sku` : ''}`}
                                                                    variant="outlined"
                                                                    type="number"/>
                                                                <TextField
                                                                    name={`details.${index}.infant`}
                                                                    disabled
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    InputProps={{inputProps: {min: 0}}}
                                                                    value={values.details[index].children_amount}
                                                                    key={`child-amount-${index}`}
                                                                    id="outlined-basic" label="Children Amount"
                                                                    variant="outlined"
                                                                    type="number"/>
                                                            </div>
                                                        }
                                                        <div className="grid-1" key={`comment-grid-${index}`}>
                                                            <TextField
                                                                name={`details.${index}.comment`}
                                                                disabled={values.transaction_status===TransactionStatus.Pagado}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.details[index].comment ?? ''}
                                                                key={`comment-${index}`}
                                                                id="outlined-multiline-static"
                                                                label="Comentarios"
                                                                multiline
                                                                rows={4}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>

                                    </div>
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <div className="grid-1">
                                        <TextField
                                            id="outlined-multiline-static"
                                            label="Incidencias"
                                            multiline
                                            rows={4}
                                            name={'incidence'}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.incidence}
                                        />
                                    </div>
                                </TabPanel>


                                <div className="aling-right">{
                                    value === 2 ? <Button variant="contained" type={'button'}
                                                          onClick={() => submitForm()}>Insertar</Button> :
                                        <Button variant="contained" onClick={validateTab}>Continuar</Button>
                                }</div>
                            </div>

                        </div>

                    </Box>
                </form>
            }


        </>
    );
}

export default Form
