import { combineReducers } from '@reduxjs/toolkit'
import {AuthReducer} from './auth/auth';
import {CommissionPaginateReducer} from './commission/commision';
import {ReportReducer} from './report/report'
import {HotelCruiseReducer} from './hotelCruise/hotelCruise';
import {NotifierReducer} from './notifier/notifier';
import {EnterprisesReducer,ProductsReducer} from './enterprises/enterprise';
import {SellersReducer} from './sellers/sellers';
import {BackdropReducer} from './backdrop/backdrop'
import {CashReducer} from './cash/cash'
import {CashBoxReducer} from './cashBox/cashBox'
import {CashBoxPendingReducer} from './cashBoxPending/cashBoxPending'
import { FormSaleReducer } from "./formSale/formSale";
export const rootReducer = combineReducers({
    auth:AuthReducer,
    commission:CommissionPaginateReducer,
    hotelCruise:HotelCruiseReducer,
    notifier:NotifierReducer,
    backdrop:BackdropReducer,
    sellers:SellersReducer,
    enterprises:EnterprisesReducer,
    products:ProductsReducer,
    reports:ReportReducer,
    cash:CashReducer,
    cashBox:CashBoxReducer,
    cashBoxPending:CashBoxPendingReducer,
    formSale: FormSaleReducer,
})

export type RootState = ReturnType<any>

export default rootReducer
