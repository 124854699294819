export interface IHotelCruisePaginate{
    hotels_cruises:IHotelCruise[]
    loadingHotelCruise:boolean
}
export interface IHotelCruise{
    id: number,
    name: string,
    type: HotelCruiseLineType,
}
export type HotelCruiseLineType='cruise'|'hotel'|'n/a'

export const InitialValueHotelCruise:IHotelCruise={
    id: 0,
    name: '',
    type: 'hotel',
}
