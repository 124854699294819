import React, {useState} from 'react';
import Box from '@mui/material/Box';
import {TextField, Button, Typography} from '@mui/material';
import {Formik} from 'formik';
import {ILogin} from '../../models/login';
import {unwrapResult} from '@reduxjs/toolkit'
import { useAppDispatch } from '../../store/store'
import {auth} from '../../store/auth/auth';
import { useNavigate } from "react-router-dom";
import {authSelector} from '../../store/auth/auth'
import {useSelector} from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton'
export default function Login() {
    const dispatch = useAppDispatch();
    const navigate=useNavigate();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const {errors,loading}=useSelector(authSelector)
    return (

        <Box sx={{flexGrow: 1}}>
            <div className="cont-login">
                <div className="cont-img">
                    <div className="logo"/>
                </div>
                <div className="cont-info-login">
                    <div className="info-login">
                        <Formik
                            initialValues={{
                                username: '',
                                password: '',
                            }}
                            onSubmit={(  values: ILogin, { setSubmitting }) => {
                                    setSubmitting(false);
                                dispatch(auth({username:values.username,password:values.password})).then(unwrapResult
                                ).then((result:any)=>navigate("/home", { replace: true }))
                            }}
                        >
                            {({
                                  values,
                                  handleChange
                                  ,
                                  handleBlur,
                                  handleSubmit,
                              }) => (
                                <form onSubmit={handleSubmit} className="form-login">

                                            <Typography className="titulo-form"> Ingrese los datos correspondientes</Typography>
                                            <TextField
                                                id="username"
                                                name={'username'}
                                                label="Usuario"
                                                autoComplete={'username'}
                                                onChange={handleChange}
                                                error={(errors?.errors?.username?.length>0??false) && values.username===''}
                                                helperText={((errors?.errors?.username?.length>0)&& values.username==='')?errors?.errors?.username[0]:''}
                                                onBlur={handleBlur}
                                                value={values.username}
                                                variant="outlined"
                                            />

                                            <TextField
                                                id="password"
                                                name={'password'}
                                                type={showPassword?'text':'password'}
                                                onChange={handleChange}
                                                autoComplete={'current-password'}
                                                onBlur={handleBlur}
                                                value={values.password}
                                                error={(errors?.errors?.password?.length>0??false) && values.password===''}
                                                helperText={((errors?.errors?.password?.length>0)&& values.password==='')?errors?.errors?.password[0]:''}
                                                label="Contraseña"
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                                <IconButton
                                                                    style={{position:'absolute',right:'15px'}}
                                                                    aria-label="toggle password visibility"
                                                                    onClick={()=>setShowPassword(!showPassword)}
                                                                >
                                                                    {showPassword ?  <VisibilityOffIcon/>: <VisibilityIcon/>}
                                                                </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />

                                            <Button type={'submit'} variant="contained" disabled={loading}>

                                                {
                                                    loading ? <CircularProgress color="primary"/>:'Iniciar sesión'
                                                }
                                            </Button>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>

        </Box>

    );
}
