import React, {useEffect} from "react";
import {DataGrid, GridColDef, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Button,Typography,Switch,Box} from '@mui/material';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import AddIcon from '@mui/icons-material/Add';
import {useAppDispatch} from "../../store/store";
import {useNavigate} from "react-router-dom";
import {commissionPaginate, CommissionMetaSelector, commissionDisabled} from '../../store/commission/commision';
import {useSelector} from 'react-redux';


const QuickSearchToolbar=()=> {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 0,
            }}
        >
            <GridToolbarQuickFilter
                quickFilterParser={(searchInput: string) =>
                    searchInput
                        .split(',')
                        .map((value) => value.trim())
                        .filter((value) => value !== '')
                }
                debounceMs={200} // time before applying the new quick filter value
            />
        </Box>
    );
}

export const Commission = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const metaSelector = useSelector(CommissionMetaSelector);
    let {
        meta: {
            current_page,
            per_page,
            total
        },
        data,
        search,
        loadingCommissionPaginate
    } = metaSelector;

    const columns: GridColDef[] = [

        {field: 'idReserva', headerName: 'ID', width: 70,disableColumnMenu:true},
        {field: 'folio', headerName: 'Folio', width: 130,sortable: false,
            disableColumnMenu: true},
        {field: 'seller', headerName: 'Taxista', width: 200,
            sortable: false,
            hideable:false,
            renderCell: (cellValues) => {
                const name=cellValues.row.intAgent===1?cellValues.row.commissionable_agent:cellValues.row.seller
                return <div  key={`${cellValues.row.id}-${cellValues.row.intAgent}`}>{name}</div>
            },
        },
        {field: 'adult', headerName: 'Adult', width: 55, type: 'number' ,disableColumnMenu: true},
        {field: 'children', headerName: 'Child', type: 'number', width: 55,disableColumnMenu: true},
        {field: 'adult_amount', headerName: 'Adult Amount', type: 'number', width: 120,disableColumnMenu: true},
        {field: 'children_amount', headerName: 'Child Amount', type: 'number', width: 120,disableColumnMenu: true},
        {field: 'total_amount', headerName: 'Total Amount', type: 'number', width: 120,disableColumnMenu: true,
            renderCell: (cellValues) => {
                return <div key={`${cellValues.row.id}-${cellValues.row.intAgent}`}>{(cellValues.row.adult_amount)+(cellValues.row.children_amount)}</div>
            }
        },
        {field: 'currency', headerName: 'Moneda', type: 'number', width: 120,disableColumnMenu: true},
        {   field:'status',
            headerName: 'status',
            sortable: false,disableColumnMenu: true,
            width: 130,
            renderCell: (cellValues) => {
                return <div  key={`${cellValues.row.id}-${cellValues.row.intAgent}`}>{cellValues.row.transaction_status}</div>
            }
        },
        {   field:'transaction_type',
            headerName: 'Type',
            sortable: false,
            disableColumnMenu: true,
            width: 130,
            renderCell: (cellValues) => {
                return <div  key={`${cellValues.row.id}-${cellValues.row.intAgent}`}>{cellValues.row.transaction_type}</div>
            }
        },
        {
            field: "",
            sortable: false,
            disableColumnMenu: true,
            width: 250,
            renderCell: (cellValues) => {
                return (
                    <>

                        <Button
                            variant="outlined"
                            className="btn-actions"
                            color="primary"
                            size="small"
                            disabled={!cellValues.row?.intEstado}
                            onClick={(event) => {
                                const route=cellValues.row.intAgent?`./${cellValues.row.idReserva}/${cellValues.row.intAgent}`:`./${cellValues.row.idReserva}`;
                                navigate(route,{replace:true})
                            }}
                            startIcon={<InboxIcon/>}
                        >
                            Editar
                        </Button>
                        <Button
                            size="small"
                            className="btn-actions"
                            variant="contained"
                            disabled={(cellValues.row.transaction_status==='Pagado' || !cellValues.row?.intEstado)}
                            color="primary"
                            onClick={(event) => {
                                const route=cellValues.row.intAgent?`../payment/${cellValues.row.idReserva}/${cellValues.row.intAgent}`:`../payment/${cellValues.row.idReserva}`;
                                navigate(route,{replace:true})

                            }}
                            startIcon={<DraftsIcon/>}
                        >
                            Pagar
                        </Button>

                        <Switch checked={!!cellValues.row?.intEstado} onClick={()=>{
                            dispatch(commissionDisabled({id:parseInt(cellValues.row?.id),data:data}))
                        }} />
                    </>
                );
            }
        },
    ];

    useEffect(() => {
        if (current_page !== undefined && per_page !== undefined) {

            dispatch(commissionPaginate({page: current_page, items: per_page,search:search}))
        }
    }, [per_page, current_page,dispatch]);

    return (
        <>
                <div className="content-panel">
                    <div className="d-flex pd-25">
                        <Typography variant={"h5"}>TaxiPlan</Typography>
                        <Button onClick={()=>{
                            navigate(`./create`,{replace:true})
                        }} variant="contained" startIcon={<AddIcon />} > Agregar</Button>
                    </div>
                    <div style={{height: 400, width: '100%',display:'grid',gridTemplateColumns:'1fr'}}>
                        <Box
                            sx={{
                                height: 400,
                                width: '100%',
                                '& .class-row-1': {
                                    bgcolor: '#d9f3f9',
                                },
                                '& .class-row-2': {
                                    bgcolor: '#ffe8bc',
                                },
                                '& .class-row': {
                                    bgcolor: '#c4c4c4',
                                },
                            }}
                        >

                        <DataGrid
                            rows={data}
                            getRowId={(row) => row.id?row.id:row.idReserva}
                            getRowClassName={(params) => params.row.intAgent===1?`class-row-1`:``}
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                            sortingMode={'client'}
                            filterMode={'server'}
                            paginationMode={'server'}
                            rowCount={total}
                            columns={columns}
                            loading={loadingCommissionPaginate}
                            onPageChange={(page) => {
                                if (current_page !== page+1) dispatch(commissionPaginate({page: (page+1), items: per_page,search:search}));
                            }}
                            onPageSizeChange={(newPageSize) =>{
                                dispatch(commissionPaginate({page: current_page, items: newPageSize,search:search}));
                            }
                            }
                            components={{ Toolbar: QuickSearchToolbar }}
                            componentsProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: {debounceMs: 500},
                                }}
                                }
                            onFilterModelChange={(change)=>
                            {
                                // @ts-ignore
                                dispatch(commissionPaginate({page: current_page, items: per_page,search:change.quickFilterValues[0]}))
                            }
                            }
                            rowsPerPageOptions={[ 10,15, 20,30,100]}
                            pagination
                            pageSize={+per_page}
                        />
                        </Box>
                    </div>
                </div>
        </>
    )
}
