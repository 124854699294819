import React, {useEffect} from "react";
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {Button, Typography, Switch, Box} from '@mui/material';
import {useAppDispatch} from "../../store/store";
import {CashBoxMetaSelector,cashPendingPaginate,ICashBoxPending,changePending} from '../../store/cashBoxPending/cashBoxPending';
import {useSelector} from 'react-redux';
import {openDialog} from "../../store/auth/auth";
import {DialogConfirmation} from "../../components/dialogConfirmPending/dialogconfirmPeding";
import InboxIcon from "@mui/icons-material/Inbox";
import {unwrapResult} from "@reduxjs/toolkit";
import {openNotifier} from "@store/notifier/notifier";
import {AxiosError} from "axios";


export const CashBoxPending = () => {
    const dispatch = useAppDispatch();
    const metaCashBoxSelector = useSelector(CashBoxMetaSelector);;
    const {cashBoxPending} = useSelector(CashBoxMetaSelector);
    let {
        data,
        loadingCashPendingPaginate
    } = metaCashBoxSelector;

    const columns: GridColDef[] = [
        {
            field: 'amount', headerName: 'Monto', width: 75, sortable: false,
            disableColumnMenu: true,
            renderCell: (cellValues) => {
                return <div
                    key={`${cellValues.rowNode?.id}-amount`}>{cellValues.row?.amount}</div>
            },

        },
        {
            field: 'currency', headerName: 'Divisa', width: 65,
            sortable: false,
            hideable: false,
            renderCell: (cellValues) => {
                return <div key={`${cellValues.rowNode?.id}-currency`}>{cellValues.row?.currency}</div>
            },
        },
        {
            field: 'date', headerName: 'Fecha', width: 90,
            sortable: false,
            hideable: false,
            renderCell: (cellValues) => {
                return <div key={`${cellValues.rowNode?.id}-date`}>{cellValues.row?.date}</div>
            },
        },
        {
            field: 'initial_fund', headerName: 'Fondo Inicial', width: 110,
            sortable: false,
            hideable: false,
        },
        {
            field: 'status', headerName: 'Estado', width: 100,
            sortable: false,
            hideable: false,
        },        {
            field: 'type', headerName: 'Tipo', width: 110,
            sortable: false,
            hideable: false,
        },
        {
            field: "",
            sortable: false,
            hide: false,
            disableColumnMenu: true,
            width: 200,
            renderCell: (cellValues) => {
                return (
                    <>
                        {
                            <Button
                                variant="outlined"
                                className="btn-actions"
                                color="primary"
                                size="small"
                                disabled={cellValues.row?.status==='APROBADO'}
                                onClick={(event) => {
                                    let data:ICashBoxPending ={id:cellValues.row?.id,amount:cellValues.row?.amount,initial_fund:cellValues.row?.initial_fund,currency:cellValues.row?.currency,status:cellValues.row?.status,type:cellValues.row?.type}
                                    dispatch(changePending({data})).then( ()=>{
                                                dispatch(openDialog(true))
                                        }
                                    )
                                }}
                                startIcon={<InboxIcon/>}
                            >
                                Aprovar
                            </Button>
                        }

                    </>
                );
            }
        },
    ];

    useEffect(() => {

            dispatch(cashPendingPaginate({}))


    }, [ dispatch]);

    return (
        <>
            <div className="content-panel">
                <DialogConfirmation  amount={cashBoxPending.amount} type_fund={cashBoxPending.type} id_detail={cashBoxPending?.id} currency={cashBoxPending.currency} ></DialogConfirmation>
                <div className="d-flex pd-25">
                    <Typography variant={"h5"}>Incrementos</Typography>
                </div>
                <div style={{height: 400, width: '100%', display: 'grid', gridTemplateColumns: '1fr'}}>
                    <Box
                        sx={{
                            height: 400,
                            width: '100%',
                            '& .class-row-1': {
                                bgcolor: '#d9f3f9',
                            },
                            '& .class-row-2': {
                                bgcolor: '#ffe8bc',
                            },
                            '& .class-row': {
                                bgcolor: '#c4c4c4',
                            },
                        }}
                    >

                        <DataGrid
                            rows={data}
                            getRowId={(row) => row.id}
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                            sortingMode={'client'}
                            filterMode={'server'}
                            paginationMode={'server'}
                            rowCount={data.length}
                            columns={columns}
                            rowsPerPageOptions={[100]}
                            loading={loadingCashPendingPaginate}
                            onPageChange={(page) => {}}
                            onPageSizeChange={(newPageSize) => {
                                // dispatch(cashPendingPaginate({page: current_page, items: newPageSize}));}
                            }
                            }
                            pageSize={100}
                        />
                    </Box>
                </div>
            </div>
        </>
    )
}
