import React, {useEffect, useState} from "react";
import {
    Button,
    Typography,
    Grid,
    Autocomplete,
    TextField,
    FormControlLabel,
    Radio,
    RadioGroup, Switch,
} from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DescriptionIcon from '@mui/icons-material/Description';
import EmailIcon from '@mui/icons-material/Email';
import {
    reportCreate,
    reset_Filter,
    reportGet,
    reportSaleGet,
    reportMail,
    changeEnterprise_id,
    changeSeller_id,
    changeCommissionable_id,
    changeFinish_date,
    changeStart_date,
    changeTransaction_status_id,
    changeTransaction_type_id,
    reportPaginate,
    reset_data
} from "../../store/report/report";
import {useAppDispatch} from "../../store/store";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
// @ts-ignore
import {DateRangePicker, FocusedInputShape} from "react-dates";
import moment from 'moment';
import {useSelector} from "react-redux";
import {ReportSelector} from "../../store/report/report";
import {initialValueSeller, ISeller} from "../../models/seller";
import {sellerList, SellersSelector} from "../../store/sellers/sellers";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import AlertTitle from '@mui/material/AlertTitle';
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import {commissionDisabled} from "@store/commission/commision";
import {useNavigate} from "react-router-dom";
import {CashMetaSelector, cashPaginate} from "../../store/cash/cash";

export const Report = () => {
    const navigate = useNavigate();
    const columns: GridColDef[] = [
        {
            field: 'folio', headerName: 'Folio', width: 140,
            sortable: false,
            hideable: false,
        },
        {
            field: 'fchReserva', headerName: 'Fecha', width: 120,
            sortable: false,
            hideable: false,
            renderCell: (cellValues) => {
                const fecha = cellValues.row.fchReserva? cellValues.row.fchReserva:''
                return <div key={`${cellValues.row.id}-${cellValues.row.intAgent}-fecha`}>{fecha.split(' ')[0]}</div>
            },
        },
        {
            field: 'seller', headerName: 'Taxista', width: 185,
            sortable: false,
            hideable: false,
            renderCell: (cellValues) => {
                const name = cellValues.row.intAgent === 1 ? cellValues.row.commissionable_agent : cellValues.row.seller
                return <div key={`${cellValues.row.id}-${cellValues.row.intAgent}`}>{name}</div>
            },
        },
        {
            field: 'seller_mobile_phone', headerName: 'Taxista Numero', width: 130,
            renderCell: (cellValues) => {
                const name = cellValues.row.intAgent === 1 ? cellValues.row.seller_mobile_phone_comm : cellValues.row.seller_mobile_phone
                return <div key={`${cellValues.row.id}-${cellValues.row.intAgent}`}>{name}</div>
            },
        },
        {
            field: 'seller_web', headerName: 'Taxista Placa', width: 120,
            renderCell: (cellValues) => {
                const name = cellValues.row.intAgent === 1 ? cellValues.row.seller_web_comm : cellValues.row.seller_web
                return <div key={`${cellValues.row.id}-${cellValues.row.intAgent}`}>{name}</div>
            },
        },
        {
            field: 'strTour2', headerName: 'Paquete', width: 150,
        },
        {field: 'adult', headerName: 'Adulto', width: 70, type: 'number'},
        {field: 'adult_amount', headerName: 'Total Adulto', width: 110, type: 'string'},
        {field: 'children', headerName: 'Children', width: 80, type: 'number'},
        {field: 'children_amount', headerName: 'Total Niño', width: 110, type: 'string'},
        {
            field: 'total_amount',
            headerName: 'Total Amount',
            sortable: false,
            disableColumnMenu: false,
            width: 130,
        },
        {
            field: 'transaction_status',
            headerName: 'Estatus',
            sortable: false,
            disableColumnMenu: false,
            width: 110,
        },
        {
            field: 'transaction_type',
            headerName: 'Tipo',
            sortable: false,
            disableColumnMenu: false,
            width: 110,
        }, {
            field: "",
            sortable: false,
            disableColumnMenu: true,
            width: 250,
            renderCell: (cellValues) => {
                return (
                    <>
                    <Button
                    size="small"
                    className="btn-actions"
                    variant="contained"
                    disabled={(cellValues.row.transaction_status === 'Pagado' || !cellValues.row?.intEstado)}
                    color="primary"
                    onClick={(event) => {
                        const route = cellValues.row.transaction_type ==='Comision'? `../payment/${cellValues.row.idReserva}/${cellValues.row.intAgent}` : `../payment/${cellValues.row.idReserva}`;
                       // console.log(route)
                        navigate(route, {replace: true,state: { origin:'reports' } })

                    }}
                    startIcon={<DraftsIcon/>}
                    >
                    Pagar
                    </Button>

                    </>
                );
            }
        },
    ];


    const dispatch = useAppDispatch();
    const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);
    const metaSelector = useSelector(CashMetaSelector);
    const {
        meta: {
            current_page,
            per_page,
            total
        },
        data,
        search,
        loadingReport,
        enterprise_id,
        seller_id,
        commissionable_id,
        start_date,
        finish_date,
        transaction_type_id,
        transaction_status_id
    } = useSelector(ReportSelector);

    const {sellers, loadingSellers} = useSelector(SellersSelector);
    useEffect(() => {
        // @ts-ignore
        dispatch(changeSeller_id(0))
        // @ts-ignore
        dispatch(changeCommissionable_id(0))
        dispatch(reset_Filter())
    }, []);
    useEffect(() => {
        dispatch(sellerList({}));
        dispatch(cashPaginate({page: 1, items: 10}))
    }, [dispatch]);


    // @ts-ignore
    return (
        <>
            <div className="content-panel">
                <div>
                    <Typography variant="overline" display="block" gutterBottom>
                        Filtrar por:
                    </Typography>
                    <Grid container spacing={2} style={{gridGap: '15px 0'}}>
                        <Grid item xs={6}>
                            <div className="cont-center">
                                <div>
                                    <Typography className="titulo-reportes" variant="h6" gutterBottom component="div">Rango
                                        de Fecha</Typography>
                                </div>
                                <DateRangePicker
                                    startDate={start_date !== null ? moment(start_date) : start_date}
                                    endDate={finish_date !== null ? moment(finish_date) : finish_date}
                                    startDateId='daterangepicker_start_date'
                                    displayFormat='DD/MM/YYYY'
                                    startDateAriaLabel='Fecha de Entrada'
                                    minimumNights={0}
                                    isOutsideRange={() => false}
                                    hideKeyboardShortcutsPanel
                                    endDateId='daterangepicker_end_date'
                                    onDatesChange={(value: { startDate: moment.MomentInput; endDate: moment.MomentInput; }) => {
                                        // @ts-ignore
                                        if (value.startDate !== false && start_date !== value.startDate) {
                                            dispatch(changeStart_date(moment(value.startDate).format('YYYY-MM-DD')))
                                            if(moment(value.startDate).format('YYYY-MM-DD')!==start_date){
                                                dispatch(reset_data());
                                            }
                                        }
                                        // @ts-ignore
                                        if (value.endDate !== null && finish_date !== value.endDate) {
                                            dispatch(changeFinish_date(moment(value.endDate).format('YYYY-MM-DD')))
                                            if(moment(value.endDate).format('YYYY-MM-DD')!==finish_date){
                                                dispatch(reset_data());
                                            }
                                        }


                                    }}
                                    focusedInput={focusedInput}
                                    onFocusChange={(focusedInput: FocusedInputShape | null) => {
                                        setFocusedInput(focusedInput)
                                    }
                                    }
                                />

                            </div>
                        </Grid>
                        <Grid item xs={6}>

                            <div className="cont-center">
                                <div>
                                    <Typography className="titulo-reportes" variant="h6" gutterBottom
                                                component="div">Taxista</Typography>
                                </div>
                                {
                                    <Autocomplete
                                        freeSolo
                                        id="seller_id"
                                        disableClearable={true}
                                        loading={loadingSellers}
                                        onInputChange={(event, value) => {
                                            if (value === '') {
                                                // @ts-ignore
                                                dispatch(changeSeller_id(null))
                                            }
                                        }
                                        }
                                        defaultValue={{
                                            id: 0,
                                            mobile_phone: '',
                                            name: '',
                                            phone: '',
                                            web: '',
                                            intComisionista: null
                                        }
                                        }
                                        options={loadingSellers ? [] : sellers.filter((seller: ISeller) => seller.id !== seller_id)}
                                        onChange={(e, value) => {
                                            // @ts-ignore
                                            dispatch(changeSeller_id(value.id))
                                        }}
                                        value={loadingSellers ? initialValueSeller : sellers.find((sellerFind: ISeller) => seller_id === sellerFind.id)}
                                        isOptionEqualToValue={(option: ISeller, value: ISeller) => option.id === value.id}
                                        renderOption={(props, option) => {
                                            // @ts-ignore
                                            const key = option.id;
                                            // @ts-ignore
                                            const name = option.name
                                            return (
                                                <li {...props} key={key}>
                                                    {name}
                                                </li>
                                            );
                                        }}
                                        // @ts-ignore
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => (
                                            <TextField
                                                name={'seller_id'}
                                                {...params}
                                                label="Nombre del Taxista"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search',
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {loadingSellers ? <Box sx={{width: '100%'}}>
                                                                <LinearProgress/>
                                                            </Box> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>)
                                                }}
                                            />
                                        )}
                                    />
                                }
                                <div>
                                    <Typography className="titulo-reportes" variant="h6" gutterBottom
                                                component="div">Vendedor</Typography>
                                </div>
                                {
                                    <Autocomplete
                                        freeSolo
                                        id="commisionable_id"
                                        disableClearable
                                        loading={loadingSellers}

                                        defaultValue={{
                                            id: 0,
                                            mobile_phone: '',
                                            name: '',
                                            phone: '',
                                            web: '',
                                            intComisionista: null
                                        }
                                        }
                                        options={loadingSellers ? [] : sellers.filter((seller: ISeller) => seller.id !== seller_id && seller.intComisionista)}
                                        onInputChange={(event, value) => {
                                            if (value === '') {
                                                // @ts-ignore
                                                dispatch(changeCommissionable_id(null))
                                            }
                                        }
                                        }
                                        onChange={(e, value) => {
                                            // @ts-ignore
                                            dispatch(changeCommissionable_id(value.id))
                                        }}
                                        // value={seller.find((sellerFind: ISeller) => commissionable_id === sellerFind.id) ?? initialValueSeller}
                                        value={loadingSellers ? initialValueSeller : (sellers.find((sellerFind: ISeller) => commissionable_id === sellerFind.id))}
                                        isOptionEqualToValue={(option: ISeller, value: ISeller) => option.id === value.id}
                                        renderOption={(props, option) => {
                                            // @ts-ignore
                                            const key = option.id;
                                            // @ts-ignore
                                            const name = option.name
                                            return (
                                                <li {...props} key={key}>
                                                    {name}
                                                </li>
                                            );
                                        }}
                                        // @ts-ignore
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => (
                                            <TextField
                                                name={'seller_id'}
                                                {...params}
                                                label="Nombre del Vendedor"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search',
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {loadingSellers ? <Box sx={{width: '100%'}}>
                                                                <LinearProgress/>
                                                            </Box> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>)
                                                }}
                                            />
                                        )}
                                    />
                                }
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="cont-center">
                                <div>
                                    <Typography className="titulo-reportes" variant="h6" gutterBottom
                                                component="div">Empresa</Typography>
                                </div>

                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={enterprise_id}
                                    defaultValue=""
                                    onChange={(event, value) => {
                                        dispatch(changeEnterprise_id(parseInt(value)))
                                    }}
                                >
                                    <FormControlLabel value="1" control={<Radio/>} label="Wildtours"/>
                                    <FormControlLabel value="2" control={<Radio/>} label="Playa Mia"/>
                                    <FormControlLabel value="" control={<Radio/>} label="Todo"/>
                                </RadioGroup>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="cont-center">
                                <div>
                                    <Typography className="titulo-reportes" variant="h6" gutterBottom component="div">Tipo
                                        de transacción</Typography>
                                </div>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={transaction_type_id}
                                    defaultValue=""
                                    onChange={(event, value) => {
                                        dispatch(changeTransaction_type_id(parseInt(value)))
                                    }}
                                >
                                    <FormControlLabel value="4" control={<Radio/>} label="Comission"/>
                                    <FormControlLabel value="6" control={<Radio/>} label="Taxiplan"/>
                                    <FormControlLabel value="" control={<Radio/>} label="Todo"/>
                                </RadioGroup>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="cont-center">
                                <div>
                                    <Typography className="titulo-reportes" variant="h6" gutterBottom component="div">Estatus
                                        de transacción</Typography>
                                </div>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    defaultValue=""
                                    value={transaction_status_id}
                                    onChange={(event, value) => {
                                        dispatch(changeTransaction_status_id(parseInt(value)))
                                    }}
                                >
                                    <FormControlLabel value="5" control={<Radio/>} label="Pendiente"/>
                                    <FormControlLabel value="6" control={<Radio/>} label="Pagado"/>
                                    <FormControlLabel value="" control={<Radio/>} label="Todo"/>
                                </RadioGroup>
                            </div>
                        </Grid>
                    </Grid>
                    <Button onClick={() => {
                        dispatch(reset_Filter());
                        dispatch(changeStart_date(moment().format('YYYY-MM-DD')))
                        dispatch(changeFinish_date(moment().format('YYYY-MM-DD')))
                        // @ts-ignore
                    }} variant="contained"
                            sx={{ mb: 2, mt: 4, background: '#d32d20', "&:hover": {backgroundColor: '#d32d20'}}}
                            startIcon={<RestartAltIcon/>}>Reiniciar
                        Filtros</Button>
                    <Button onClick={() => {
                        dispatch(reportCreate({
                            enterprise_id,
                            seller_id,
                            finish_date,
                            start_date,
                            transaction_status_id,
                            transaction_type_id
                        }));
                    }} variant="contained" sx={{mb: 2, mt: 4,ml: 2,}} startIcon={<DescriptionIcon/>}>Generar Reporte</Button>


                    {
                        data.length > 0 && <Button onClick={() => {
                            dispatch(reportGet({
                                enterprise_id,
                                seller_id,
                                finish_date,
                                start_date,
                                transaction_status_id,
                                transaction_type_id
                            }));
                        }} variant="contained" sx={{ml: 2, mb: 2, mt: 4,background:'#1D6F42',"&:hover": {
                                backgroundColor: '#1D6F42',
                            }}} startIcon={<FileDownloadIcon/>}>Reporte Caja</Button>
                    }
                    {
                        data.length > 0 && <Button onClick={() => {
                            dispatch(reportSaleGet({
                                finish_date,
                                start_date,
                            }));
                        }} variant="contained" sx={{ml: 2, mb: 2, mt: 4,background:'#1D6F42',"&:hover": {
                                backgroundColor: '#1D6F42',
                            }}} startIcon={<FileDownloadIcon/>}>Reporte Ventas</Button>
                    }
                    {
                        data.length > 0 &&(metaSelector?.data[0]?.status!==1) && (moment(start_date).isSame(new Date(), "day") && moment(finish_date).isSame(new Date(), "day")) && <Button onClick={() => {
                            // @ts-ignore
                            dispatch(reportMail());
                        }} variant="contained" sx={{
                            ml: 2, mb: 2, mt: 4, backgroundColor: '#2E5D8C ', "&:hover": {
                                backgroundColor: '#2E5D8C',
                            }
                        }}
                                                   startIcon={<EmailIcon/>}> Enviar Reporte</Button>
                    }
                </div>

                {
                    (data.length > 0 && !loadingReport) ?
                        <div style={{height: 400, width: '100%', display: 'grid', gridTemplateColumns: '1fr'}}>
                            <Box
                                sx={{
                                    height: 400,
                                    width: '100%',
                                    '& .class-row-error': {
                                        bgcolor: '#ff6961',
                                        pointerEvents: "none",
                                        '&:hover':{
                                            bgcolor: '#ff6961',
                                        },
                                        '&.Mui-selected':{
                                            bgcolor: '#ff6961',
                                        },
                                        '&.Mui-focusVisible':{
                                            bgcolor: '#ff6961',
                                        }
                                    },'& .class-row-error:hover': {
                                        bgcolor: '#ff6961',
                                    },'& .class-row-error,&.Mui-selected,&.Mui-focusVisible': {
                                        bgcolor: '#ff6961',
                                    }
                                }}
                            >

                            <DataGrid
                                rows={data}
                                sortingMode={'server'}
                                getRowClassName={(params) => params.row.intEstado===0?`class-row-error`:``}
                                filterMode={'server'}
                                paginationMode={'server'}
                                getRowId={(row) => row.id ? row.id : row.idReserva}
                                rowCount={total}
                                disableColumnMenu
                                disableColumnFilter
                                columns={columns}
                                loading={loadingReport}
                                onPageChange={(page) => {
                                    if (current_page !== page + 1) dispatch(reportPaginate({
                                        page: (page + 1), items: per_page,
                                        enterprise_id,
                                        seller_id,
                                        finish_date,
                                        start_date,
                                        transaction_status_id,
                                        transaction_type_id
                                    }));
                                }}
                                onPageSizeChange={(newPageSize) => {
                                    dispatch(reportPaginate({
                                        page: current_page, items: newPageSize,
                                        enterprise_id,
                                        seller_id,
                                        finish_date,
                                        start_date,
                                        transaction_status_id,
                                        transaction_type_id
                                    }));
                                }
                                }
                                rowsPerPageOptions={[10, 15, 20, 30, 100]}
                                pagination
                                pageSize={+per_page}
                            />
                            </Box>
                        </div> : <Stack sx={{width: '100%'}} spacing={2}>
                            <Alert severity="warning">
                                <AlertTitle>No se encontraron datos.</AlertTitle>
                            </Alert>
                        </Stack>
                }


            </div>
        </>
    )
}
