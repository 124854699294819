import React from 'react';
import {
    RouteProps,
    Navigate
} from "react-router-dom";
import Login from '../pages/login/login'
interface IRedirectIfAuthenticated extends RouteProps {
    readonly isAuthenticated: boolean
    readonly redirectPath: string
}
const RedirectIfAuthenticated: React.FC<IRedirectIfAuthenticated> =
    ({
         isAuthenticated,
         ...props
     }) => {
        return isAuthenticated ?<Navigate to={props.redirectPath} /> : <Login />;
    };
export default RedirectIfAuthenticated;
