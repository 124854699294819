import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axios from 'axios';
import {persistReducer} from 'redux-persist';
import {openBackdrop,closeBackdrop} from '../backdrop/backdrop';
import storageSession from 'redux-persist/lib/storage/session';
import {RootState} from '../rootReducer'
import {
    ICashListPending, ICashPaginate
} from '../../models/cash'


const api = process.env.REACT_APP_API_URL



const initialStatePaginate: ICashPaginate = {
    data: [],
    meta: {
        current_page: 1,
        per_page: '10',
        total: 0
    },
    search:'',
    errors:null,
    loadingCash: false,
}


export const cashListActivePaginate = createAsyncThunk<ICashPaginate,
    { page: number, items: number },
    {
        rejectValue: string
    }>(
    'cashBox/listActive',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        try {
            const response = await axios.get(`${api}/cash-closing/listActive?page=${data.page}&items=${data.items}`);
            thunkApi.dispatch(closeBackdrop());
            return response.data;
        } catch (err) {
            console.log(err)
            thunkApi.dispatch(closeBackdrop());
            return thunkApi.rejectWithValue('error')
        }
    }
)

export const cashIncrement = createAsyncThunk<string,
    {type_fund:string,amount:number,detail_id:string},
    {
        rejectValue: unknown
    }>(
    'cash/increment',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        try {

            const response = await axios.put(`${api}/cash-closing/increment/${data.detail_id}`, {amount:data.amount,type_fund:+data.type_fund});
            thunkApi.dispatch(closeBackdrop());
            return response.data;
        } catch (err) {
            thunkApi.dispatch(closeBackdrop());
            // @ts-ignore
            thunkApi.dispatch(fillError(err.response.data))
            // @ts-ignore
            return thunkApi.rejectWithValue(err.response.data)
        }
    }
)




export const cashBoxSlice = createSlice({
    initialState: initialStatePaginate,
    name: 'cash',
    extraReducers: builder => {
        builder
            .addCase(cashListActivePaginate.pending, state => {
                state.loadingCash = true
            })
            .addCase(cashListActivePaginate.fulfilled, (state, {payload}) => {
                const {data, meta} = payload
                state.data = data
                state.meta = meta
                state.loadingCash = false
            })
            .addCase(cashListActivePaginate.rejected, (state, action) => {
                state.loadingCash = false
            })

    },
    reducers: {},
})


export const CashBoxMetaSelector = (state: RootState) => {
    return {
        meta: state.cashBox.meta,
        data: state.cashBox.data,
        loadingCashBoxPaginate: state.cashBox.loadingCash,
    };
};

export const CashBoxReducer = persistReducer({
    storage:storageSession,
    key: 'cashBox',
    blacklist: ['data', 'meta'],
}, cashBoxSlice.reducer)
export const {
} = cashBoxSlice.actions;
