import React, {useEffect} from "react";
import {DataGrid, GridColDef, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Button,Typography,Switch,Box} from '@mui/material';
import InboxIcon from '@mui/icons-material/Inbox';
import AddIcon from '@mui/icons-material/Add';
import {useAppDispatch} from "../../store/store";
import {useNavigate} from "react-router-dom";
import {resetSeller,sellerPaginate,SellersSelector,sellerDisabled} from '../../store/sellers/sellers';
import {useSelector} from 'react-redux';




const QuickSearchToolbar=()=> {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 0,
            }}
        >
            <GridToolbarQuickFilter
                quickFilterParser={(searchInput: string) =>
                    searchInput
                        .split(',')
                        .map((value) => value.trim())
                        .filter((value) => value !== '')
                }
                debounceMs={200} // time before applying the new quick filter value
            />
        </Box>
    );
}

export const Seller = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const metaSelector = useSelector(SellersSelector);
    let {
        meta: {
            current_page,
            per_page,
            total
        },
        sellers,
        search,
        loadingSellers
    } = metaSelector;

    const columns: GridColDef[] = [

        {field: 'id', headerName: 'ID', width: 70,disableColumnMenu:true},
        {field: 'mobile_phone', headerName: 'Teléfono', width: 130,sortable: false,
            disableColumnMenu: true},
        {field: 'name', headerName: 'Nombre', width: 260, type: 'number' ,sortable: false,disableColumnMenu: true,headerAlign:'left',align:'left'},
        {field: 'web', headerName: 'Taxi', type: 'number', width: 120,sortable: false,disableColumnMenu: true,headerAlign:'left',align:'left'},
        {field: 'phone', headerName: 'Gafete', type: 'number', width: 120,sortable: false,disableColumnMenu: true,headerAlign:'left',align:'left'},
        {
            field: "",
            sortable: false,
            disableColumnMenu: true,
            width: 250,
            renderCell: (cellValues) => {
                return (
                    <>

                        <Button
                            variant="outlined"
                            className="btn-actions"
                            color="primary"
                            size="small"
                            disabled={!cellValues.row?.status}
                            onClick={(event) => {
                                const route=`../seller/${cellValues.row.id}`;
                               navigate(route,{replace:true})
                            }}
                            startIcon={<InboxIcon/>}
                        >
                            Editar
                        </Button>
                        <Switch checked={!!cellValues.row?.status} onClick={()=>{
                            dispatch(sellerDisabled({id:parseInt(cellValues.row?.id),sellers:sellers}))
                        }} />
                    </>
                );
            }
        },
    ];

    useEffect(() => {
        if (current_page !== undefined && per_page !== undefined) {

            dispatch(sellerPaginate({page: current_page, items: per_page,search:search}))
        }
    }, [per_page, current_page,dispatch]);

    return (
        <>
                <div className="content-panel">
                    <div className="d-flex pd-25">
                        <Typography variant={"h5"}>Taxistas</Typography>
                        <Button onClick={()=>{
                            navigate(`./create`,{replace:true})
                            dispatch(resetSeller());
                        }} variant="contained" startIcon={<AddIcon />} > Agregar</Button>
                    </div>
                    <div style={{height: 400, width: '100%',display:'grid',gridTemplateColumns:'1fr'}}>
                        <Box
                            sx={{
                                height: 400,
                                width: '100%',
                                '& .class-row-1': {
                                    bgcolor: '#d9f3f9',
                                },
                                '& .class-row-2': {
                                    bgcolor: '#ffe8bc',
                                },
                                '& .class-row': {
                                    bgcolor: '#c4c4c4',
                                },
                            }}
                        >

                        <DataGrid
                            rows={sellers}
                            //getRowId={(row) => row.id?row.id:row.idReserva}
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                            sortingMode={'client'}
                            filterMode={'server'}
                            paginationMode={'server'}
                            rowCount={total}
                            columns={columns}
                            loading={loadingSellers}
                            onPageChange={(page) => {
                                if (current_page !== page+1) dispatch(sellerPaginate({page: (page+1), items: per_page,search:search}));
                            }}
                            onPageSizeChange={(newPageSize) =>{
                                dispatch(sellerPaginate({page: current_page, items: newPageSize,search:search}));
                            }
                            }
                            components={{ Toolbar: QuickSearchToolbar }}
                            componentsProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: {debounceMs: 500},
                                }}
                                }
                            onFilterModelChange={(change)=>
                            {
                                // @ts-ignore
                                dispatch(sellerPaginate({page: current_page, items: per_page,search:change.quickFilterValues[0]}))
                            }
                            }
                            rowsPerPageOptions={[ 10,15, 20,30,100]}
                            pagination
                            pageSize={+per_page}
                        />
                        </Box>
                    </div>
                </div>
        </>
    )
}
