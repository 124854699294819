import React, {useEffect} from "react";
import {useFormik} from 'formik';
import {TextField, Button} from '@mui/material';
import Box from '@mui/material/Box';
import {useAppDispatch} from "../../store/store";
import {ICashOpen,initialCashState} from "../../models/cash";
import {useNavigate, useParams} from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useSelector} from "react-redux";
import {authSelector, openDialog} from "../../store/auth/auth";
import {cashOpen,CashSelector} from '../../store/cash/cash'
import {unwrapResult} from '@reduxjs/toolkit'
import {openNotifier} from "../../store/notifier/notifier";
import {AxiosError} from "axios";
export const FormCash = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {data} = useParams();
    const {access_token} = useSelector(authSelector);
    const {loadingCash} = useSelector(CashSelector);
    const {
        setFieldValue,
        setValues,
        values,
        handleChange,
        submitForm,
        handleBlur,
        setSubmitting
    } = useFormik<ICashOpen>({
        initialValues: initialCashState,
        onSubmit: (values: ICashOpen, formikHelpers) => {
            // dispatch(openDialog(true))
            dispatch(cashOpen({cash:values})).then(unwrapResult).then((result: any) => {
                navigate("../cash", {replace: true})
                // dispatch(openDialog(false))
                dispatch(openNotifier({variant: "success", open: true, message: 'success'}))
            }).catch((result: AxiosError) => {
                // dispatch(openDialog(false))
                // @ts-ignore
                dispatch(openNotifier({variant: "error", open: true, message: result?.errores?.type_fund_id[0]?result?.errores?.type_fund_id[0]:result?.message}))
            })
        },
    })

    useEffect(() => {
        if (data) {
            const dataArray=JSON.parse(data)
            // @ts-ignore
            setValues({amount_usd:0,amount_mxn:values.amount_mxn})
        }
    }, []);
    // @ts-ignore
    return (
        <>
            {/*<DialogConfirmation isIncrementType={!!data} amount_mxn={values.amount_mxn} amount_usd={values.amount_usd} ></DialogConfirmation>*/}

            <form onSubmit={submitForm} className="form-login">
                <Box className="content-panel"
                     sx={{flexGrow: 1, display: 'flex', width: '100%'}}
                >
                    <Button variant="outlined" size="small" className="btn-atras btn-actions"
                            onClick={() => {
                                navigate("../cash", {replace: true})
                            }
                            }><ArrowBackIcon
                        fontSize="small"/>&nbsp; Atras</Button>
            <div className="grid-2">
                <div className="grid-1 b-bottom">
                    <TextField
                        id="amount_mxn"
                        InputProps={{ inputProps: { min: 1} }}
                        // error={(errors?.errores?.customer_name?.length > 0 ?? false) && values.customer_name === ''}
                        // helperText={errors?.errores?.customer_name?.length > 0 ? errors?.errores?.customer_name[0] : ''}
                        label={data?'Incremento MXN':'Apertura MXN'}
                        variant="outlined"
                        name={'amount_mxn'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type={'number'}
                        value={values.amount_mxn}
                    />
                    <div className="aling-right"><Button variant="contained" type={'button'}
                                                         disabled={loadingCash}
                                                         onClick={() => submitForm()}>{data?'Incrementar':'Insertar'}</Button>
                    </div>

                </div>

            </div>
                </Box>
            </form>

        </>
    );
}
