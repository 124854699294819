import React, {useEffect} from "react";
import {useFormik} from 'formik';
import {TextField, Button} from '@mui/material';
import Box from '@mui/material/Box';
import {useAppDispatch} from "../../store/store";
import {ICashOpen,initialCashState} from "../../models/cash";
import {ISeller,initialValueSeller} from "../../models/seller";
import {useNavigate, useParams} from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useSelector} from "react-redux";
import {authSelector, openDialog} from "../../store/auth/auth";
import {cashOpen} from '../../store/cash/cash'
import {unwrapResult} from '@reduxjs/toolkit'
import {openNotifier} from "../../store/notifier/notifier";
import {AxiosError} from "axios";
import {sellerCreate, sellerRetrieve, SellersSelector,resetSellerError,sellerUpdate} from "../../store/sellers/sellers";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {commissionCreate, commissionUpdate} from "@store/commission/commision";
export const FormSeller = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {sku,intAgent} = useParams();
    const {access_token} = useSelector(authSelector);
    const metaSelector = useSelector(SellersSelector);
    let {
        seller,
        errors,
    } = metaSelector;
    const {
        setFieldValue,
        setValues,
        values,
        handleChange,
        submitForm,
        handleBlur,
        setSubmitting
    } = useFormik<ISeller>({
        initialValues: initialValueSeller,
        onSubmit: (values: ISeller, formikHelpers) => {
            let {id,...data}=values
            if (sku) {
                dispatch(sellerUpdate({seller:data,id:values.id})).then(unwrapResult).then((result: any) => {
                    navigate("../seller", {replace: true})
                    dispatch(openNotifier({variant: "success", open: true, message: 'success'}))
                }).catch((result: AxiosError) => {
                    // @ts-ignore
                    dispatch(openNotifier({variant: "error", open: true, message: result}))
                })
            } else {

                dispatch(sellerCreate({seller:data})).then(unwrapResult).then((result: any) => {
                    navigate("../seller", {replace: true})
                    dispatch(openNotifier({variant: "success", open: true, message: 'success'}))
                }).catch((result: AxiosError) => {
                    // @ts-ignore
                    dispatch(openNotifier({variant: "error", open: true, message: result}))
                })
            }
        },
    })

    useEffect(() => {

        if (sku) {
                // @ts-ignore
                dispatch(sellerRetrieve(sku))
        }
    }, [dispatch, sku]);

    useEffect(() => {
        if (seller.id!==0) {
            // @ts-ignore
            setValues({
                id: seller.id,
                mobile_phone: seller.mobile_phone,
                name: seller.name,
                phone: seller.phone,
                web: seller.web,
                intComisionista:seller.intComisionista,
            })

        }
    }, [seller]);

    // @ts-ignore
    return (
        <>

            <form onSubmit={submitForm} className="form-login">
                <Box className="content-panel"
                     sx={{flexGrow: 1, display: 'flex', width: '100%'}}
                >
                    <Button variant="outlined" size="small" className="btn-atras btn-actions"
                            onClick={() => {
                                navigate("../seller", {replace: true})
                                if (errors!==null){
                                    dispatch(resetSellerError())
                                }

                            }
                            }><ArrowBackIcon
                        fontSize="small"/>&nbsp; Atras</Button>
            <div className="grid-2">
                <div className="grid-1 b-bottom">
                    <TextField
                        id="name"
                        error={(errors?.errors?.name?.length > 0 ?? false) && values.name === ''}
                        helperText={errors?.errors?.name?.length > 0 ? errors?.errors?.name[0] : ''}
                        label={'Nombre'}
                        variant="outlined"
                        name={'Nombre'}
                        onChange={(event) => {
                            setFieldValue('name', event.currentTarget.value)
                        }}
                        type={'text'}
                        value={values.name}
                    />
                    <TextField
                        id="web"
                        error={(errors?.errors?.web?.length > 0 ?? false) && values.web === ''}
                        helperText={errors?.errors?.web?.length > 0 ? errors?.errors?.web[0] : ''}
                        label={'Taxi'}
                        variant="outlined"
                        name={'web'}
                        onChange={(event) => {
                            setFieldValue('web', event.currentTarget.value)
                        }}
                        type={'text'}
                        value={values.web}
                    />
                    <TextField
                        id="phone"
                        error={(errors?.errors?.phone?.length > 0 ?? false) && values.phone === ''}
                        helperText={errors?.errors?.phone?.length > 0 ? errors?.errors?.phone[0] : ''}
                        label={'Gafete'}
                        variant="outlined"
                        name={'phone'}
                        onChange={(event) => {
                            setFieldValue('phone', event.currentTarget.value)
                        }}
                        type={'text'}
                        value={values.phone}
                    />

                    <TextField
                        id="mobile_phone"
                        label={'Teléfono'}
                        variant="outlined"
                        name={'mobile_phone'}
                        onChange={(event) => {
                            setFieldValue('mobile_phone', event.currentTarget.value)
                        }}
                        type={'text'}
                        value={values.mobile_phone}
                    />
                    <FormGroup>
                        <FormControlLabel control={
                            <Switch
                            checked={values.intComisionista !== null}
                            onChange={(event, checked)=>{
                                setFieldValue(
                                    "intComisionista",1,
                                )
                                if(values.intComisionista){
                                    setFieldValue(
                                        "intComisionista",null,
                                    )
                                }

                            }
                            }
                            inputProps={{ 'aria-label': 'controlled' }}
                            />} label="Comisionista" />
                    </FormGroup>
                    <div className="aling-right"><Button variant="contained" type={'button'}
                                                         onClick={() => submitForm()}>{sku?'Actualizar':'Agregar'}</Button>
                    </div>

                </div>

            </div>
                </Box>
            </form>

        </>
    );
}
