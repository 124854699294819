import React from 'react';
import {useSelector} from 'react-redux';
import {BackdropSelector} from "../../store/backdrop/backdrop";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {authSelector,openDialog} from "../../store/auth/auth";
import { useAppDispatch } from '../../store/store'
import {cashPendingApprove, CashBoxMetaSelector, cashPendingPaginate} from "../../store/cashBoxPending/cashBoxPending";
import {unwrapResult} from "@reduxjs/toolkit";
import {openNotifier} from "../../store/notifier/notifier";
import {AxiosError} from "axios";
import {useNavigate} from "react-router-dom";
interface ICashOpen{
    amount:number,
    currency:string,
    type_fund:string,
    id_detail:string
}
export const DialogConfirmation=(data:ICashOpen)=> {

    const {open_dialog} = useSelector(authSelector);
    const {cashBoxPending} = useSelector(CashBoxMetaSelector);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const confirmClick=()=>{
        dispatch(openDialog(true));
        dispatch(cashPendingApprove({idIncrement:cashBoxPending.id})).then(unwrapResult).then((result: any) => {
            navigate("../increment/cash", {replace: true})
            dispatch(openDialog(false));
            dispatch(cashPendingPaginate({}))
            dispatch(openNotifier({variant: "success", open: true, message: 'success'}))
        }).catch((result: AxiosError) => {
            dispatch(openDialog(false));
            // @ts-ignore
            dispatch(openNotifier({variant: "error", open: true, message: result?.errores?.type_fund_id[0]?result?.errores?.type_fund_id[0]:result?.message}))
        })
    }

    return (
        <div>
            <Dialog
                open={open_dialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Aviso
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {cashBoxPending.type==='REEMBOLSO'?`Esta por aprobar un reembolso de ${cashBoxPending.amount} a su caja abierta`:`Esta por aprobar un Incremento de ${cashBoxPending.amount} a su caja abierta`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=> dispatch(openDialog(false))}>Cerrar</Button>
                    <Button onClick={confirmClick}>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
