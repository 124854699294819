import React, {useEffect} from "react";
import {DataGrid, GridColDef, GridToolbarQuickFilter, GridLinkOperator} from '@mui/x-data-grid';
import {Button, Typography, Switch, Box} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {useAppDispatch} from "../../store/store";
import {useNavigate} from "react-router-dom";
import {CashBoxMetaSelector,cashListActivePaginate} from '../../store/cashBox/cashBox';
import {useSelector} from 'react-redux';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import {authSelector} from "../../store/auth/auth";


export const CashBox = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const metaCashBoxSelector = useSelector(CashBoxMetaSelector);
    const {user} = useSelector(authSelector);
    let {
        meta: {
            current_page,
            per_page,
            total
        },
        data,
        loadingCashBoxPaginate
    } = metaCashBoxSelector;

    const columns: GridColDef[] = [
        {field: 'date', headerName: 'Fecha', width: 100, disableColumnMenu: true},
        {
            field: 'user_name', headerName: 'Usuario', width: 130, sortable: false,
            disableColumnMenu: true,
            renderCell: (cellValues) => {
                return <div
                    key={`${cellValues.rowNode?.id}-${cellValues.row?.user}-username`}>{cellValues.row?.user}</div>
            },

        },
        {
            field: 'initial_fund', headerName: 'Fondo inicial mxn', width: 140,
            sortable: false,
            hideable: false,
            renderCell: (cellValues) => {
                return <div
                    key={`${cellValues.rowNode?.id}-${cellValues.row?.user}-initial_fund_mxn`}>{cellValues.row?.cash_closings[0].initial_fund}</div>
            },
        },
        {
            field: 'refund_fund', headerName: 'Reembolso mxn', width: 120,
            sortable: false,
            hideable: false,
            renderCell: (cellValues) => {
                return <div
                    key={`${cellValues.rowNode?.id}-${cellValues.row?.user}_commision_payed`}>{cellValues.row?.cash_closings[0].refund}</div>
            },
        },
        {
            field: 'total_payed', headerName: 'Total pagado mxn', width: 125,
            sortable: false,
            hideable: false,
            renderCell: (cellValues) => {
                return <div
                    key={`${cellValues.rowNode?.id}-${cellValues.row?.user}-total_payed_mxn`}>{cellValues.row?.cash_closings[0].commision_payed}</div>
            },
        },
        {
            field: 'increment_fund', headerName: 'Incremento mxn', width: 120,
            sortable: false,
            hideable: false,
            renderCell: (cellValues) => {
                const increment = cellValues.row?.cash_closings[0]?.increment;
                return <div key={`${cellValues.rowNode?.id}-${cellValues.row?.user}-increment`}>{cellValues.row?.cash_closings[0]?.increment}</div>
            },
        },
        {
            field: "",
            sortable: false,
            hide: false,
            disableColumnMenu: true,
            width: 300,
            renderCell: (cellValues) => {
                return (
                    <>
                        {
                            (cellValues.row?.cash_closings[0].final_fund) === null ? <Button
                                size="small"
                                className="btn-actions"
                                variant="contained"
                                disabled={((cellValues.row.transaction_status?.name === 'Pagado'))}
                                color="primary"
                                sx={{background: '#651a55', "&:hover": {backgroundColor: '#651a55'}}}
                                onClick={(event) => {
                                    const arrayParameter = JSON.stringify(cellValues.row.cash_closings);
                                    const route = `../increment/cashBox/${cellValues.row?.cash_closings[0].id}/1`;
                                    navigate(route, {replace: true})
                                }}
                                startIcon={<PointOfSaleIcon/>}
                            >
                                Reembolso Caja
                            </Button> : <p></p>
                        }
                        {
                            (cellValues.row?.cash_closings[0].final_fund) === null ? <Button
                                size="small"
                                className="btn-actions"
                                variant="contained"
                                disabled={((cellValues.row.transaction_status?.name === 'Pagado'))}
                                color="primary"
                                sx={{background: '#1a652a', "&:hover": {backgroundColor: '#1a652a'}}}
                                onClick={(event) => {
                                    const arrayParameter = JSON.stringify(cellValues.row.cash_closings);
                                    const route = `../increment/cashBox/${cellValues.row?.cash_closings[0].id}/2`;
                                    navigate(route, {replace: true})
                                }}
                                startIcon={<PointOfSaleIcon/>}
                            >
                                Incrementar Caja
                            </Button> : <p></p>
                        }

                        {
                           (cellValues.row?.status === 1 && user.name===process.env.REACT_ID_TAXIPLAN ) ? <Button
                                size="small"
                                className="btn-actions"
                                variant="contained"
                                sx={{background: '#d32d20', "&:hover": {backgroundColor: '#d32d20'}}}
                                disabled={((cellValues.row.transaction_status?.name === 'Pagado')||user.name!==process.env.REACT_ID_TAXIPLAN)}
                                color="primary"
                                onClick={(event) => {

                                    const arrayParameter = JSON.stringify(cellValues.row.cash_closings);
                                    const route = `../cash/close/${arrayParameter}`;
                                    navigate(route, {replace: true})

                                }}
                                startIcon={<PointOfSaleIcon/>}
                            >
                                Cerrar Caja
                            </Button> : <p>{user.name===process.env.REACT_ID_TAXIPLAN?'Caja Cerrada':''}</p>
                        }

                    </>
                );
            }
        },
    ];

    useEffect(() => {
        if (current_page !== undefined && per_page !== undefined) {
            dispatch(cashListActivePaginate({page: current_page, items: per_page}))
        }

    }, [per_page, current_page, dispatch]);

    return (
        <>
            <div className="content-panel">
                <div className="d-flex pd-25">
                    <Typography variant={"h5"}>Cajas Abiertas</Typography>
                </div>
                <div style={{height: 400, width: '100%', display: 'grid', gridTemplateColumns: '1fr'}}>
                    <Box
                        sx={{
                            height: 400,
                            width: '100%',
                            '& .class-row-1': {
                                bgcolor: '#d9f3f9',
                            },
                            '& .class-row-2': {
                                bgcolor: '#ffe8bc',
                            },
                            '& .class-row': {
                                bgcolor: '#c4c4c4',
                            },
                        }}
                    >

                        <DataGrid
                             rows={data}
                            getRowId={(row) => row.date ? `${row.date}-${row.user}-${row.status}-${Math.random()}` : `${row.date}-${row.user}-${row.status}-${Math.random()}`}
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                            sortingMode={'client'}
                            filterMode={'server'}
                            paginationMode={'server'}
                            rowCount={total}
                            columns={columns}
                            loading={loadingCashBoxPaginate}
                            onPageChange={(page) => {
                                if (current_page !== page + 1) dispatch(cashListActivePaginate({
                                    page: (page + 1),
                                    items: per_page
                                }));

                            }}
                            onPageSizeChange={(newPageSize) => {
                                dispatch(cashListActivePaginate({page: current_page, items: newPageSize}));

                            }
                            }
                            componentsProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: {debounceMs: 500},
                                }
                            }
                            }
                            rowsPerPageOptions={[10, 15, 20, 30]}
                            pagination
                            pageSize={+per_page}
                        />
                    </Box>
                </div>
            </div>
        </>
    )
}
