import React, {useEffect} from "react";
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {Button, Typography, Box} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {useAppDispatch} from "../../store/store";
import {useNavigate} from "react-router-dom";
import {cashPaginate, CashMetaSelector} from '../../store/cash/cash';
import {useSelector} from 'react-redux';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import {authSelector} from "../../store/auth/auth";


export const Cash = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const metaSelector = useSelector(CashMetaSelector);
    const {user} = useSelector(authSelector);
    const arrayTaxiPlan = JSON.parse(process.env.REACT_APP_ID_TAXIPLAN ?? "");
    const hasAccessToCashBox = arrayTaxiPlan.some(
        (value:string) => +value === user.id
    );

    let {
        meta: {
            current_page,
            per_page,
            total
        },
        data,
        loadingCashPaginate
    } = metaSelector;

    const columns: GridColDef[] = [
        {field: 'date', headerName: 'fecha', width: 110, disableColumnMenu: true},
        {
            field: 'user_name', headerName: 'Usuario', width: 130, sortable: false,
            disableColumnMenu: true,
            renderCell: (cellValues) => {
                return <div
                    key={`${cellValues.rowNode?.id}-${cellValues.row?.user}-username`}>{cellValues.row?.user}</div>
            },

        },
        {
            field: 'initial_fund', headerName: 'Fondo inicial mxn', width: 200,
            sortable: false,
            hideable: false,
            renderCell: (cellValues) => {
                return <div
                    key={`${cellValues.rowNode?.id}-${cellValues.row?.user}-initial_fund_mxn`}>{cellValues.row?.cash_closings[0].initial_fund}</div>
            },
        },
        {
            field: 'refund_fund', headerName: 'Fondo Reembolsado mxn', width: 200,
            sortable: false,
            hideable: false,
            renderCell: (cellValues) => {
                const increment = cellValues.row?.cash_closings[0]?.refund;
                return <div key={`${cellValues.rowNode?.id}-${cellValues.row?.user}-increment`}>{increment}</div>
            },
        },
        {
            field: 'increment_fund', headerName: 'Fondo Incrementado mxn', width: 200,
            sortable: false,
            hideable: false,
            renderCell: (cellValues) => {
                const increment = cellValues.row?.cash_closings[0]?.increment;
                return <div key={`${cellValues.rowNode?.id}-${cellValues.row?.user}-increment`}>{increment}</div>
            },
        },
        {
            field: 'commision_payed', headerName: 'Comision pagada mxn', width: 200,
            sortable: false,
            hideable: false,
            renderCell: (cellValues) => {
                return <div
                    key={`${cellValues.rowNode?.id}-${cellValues.row?.user}_commision_payed`}>{cellValues.row?.cash_closings[0].commision_payed}</div>
            },
        },
        {
            field: 'final_fund', headerName: 'Fondo Final mxn', width: 200,
            sortable: false,
            hideable: false,
            renderCell: (cellValues) => {
                const finalFund = cellValues.row?.cash_closings[0].final_fund ? cellValues.row?.cash_closings[0].final_fund : 'Caja Abierta';
                return <div key={`${cellValues.rowNode?.id}-${cellValues.row?.user}_final_fund`}>{finalFund}</div>
            },
        },
        {
            field: "",
            sortable: false,
            hide: false,
            disableColumnMenu: true,
            width: 300,
            renderCell: (cellValues) => {

                return (
                    <>
                        {
                           (cellValues.row?.status === 1 && hasAccessToCashBox) ? <Button
                                size="small"
                                className="btn-actions"
                                variant="contained"
                                sx={{background: '#d32d20', "&:hover": {backgroundColor: '#d32d20'}}}
                                disabled={((cellValues.row.transaction_status?.name === 'Pagado')||hasAccessToCashBox===false)}
                                color="primary"
                                onClick={(event) => {

                                    const arrayParameter = JSON.stringify(cellValues.row.cash_closings);
                                    const route = `../cash/close/${arrayParameter}`;
                                    navigate(route, {replace: true})

                                }}
                                startIcon={<PointOfSaleIcon/>}
                            >
                                Cerrar Caja
                            </Button> : <p>{hasAccessToCashBox?'Caja Cerrada':''}</p>
                        }

                    </>
                );
            }
        },
    ];

    useEffect(() => {
        if (current_page !== undefined && per_page !== undefined) {

            dispatch(cashPaginate({page: current_page, items: per_page}))
        }

    }, [per_page, current_page, dispatch]);

    // @ts-ignore
    return (
        <>
            <div className="content-panel">
                <div className="d-flex pd-25">
                    <Typography variant={"h5"}>Caja</Typography>
                    <Button onClick={() => {
                        navigate(`./create`, {replace: true})
                    }}
                            variant="contained"
                            // disabled={user.id+''===process.env.REACT_APP_ID_CAJA_GENERAL}
                            startIcon={<AddIcon/>}
                    >Agregar</Button>
                </div>
                <div style={{height: 400, width: '100%', display: 'grid', gridTemplateColumns: '1fr'}}>
                    <Box
                        sx={{
                            height: 400,
                            width: '100%',
                            '& .class-row-1': {
                                bgcolor: '#d9f3f9',
                            },
                            '& .class-row-2': {
                                bgcolor: '#ffe8bc',
                            },
                            '& .class-row': {
                                bgcolor: '#c4c4c4',
                            },
                        }}
                    >

                        <DataGrid
                            rows={data}
                            getRowId={(row) => row.date ? `${row.date}-${row.user}-${row.status}-${Math.random()}` : `${row.date}-${row.user}-${row.status}-${Math.random()}`}
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                            sortingMode={'client'}
                            filterMode={'server'}
                            paginationMode={'server'}
                            rowCount={total}
                            columns={columns}
                            loading={loadingCashPaginate}
                            onPageChange={(page) => {
                                if (current_page !== page + 1) dispatch(cashPaginate({
                                    page: (page + 1),
                                    items: per_page
                                }));
                            }}
                            onPageSizeChange={(newPageSize) => {
                                dispatch(cashPaginate({page: current_page, items: newPageSize}));
                            }
                            }
                            // components={{ Toolbar: QuickSearchToolbar }}
                            componentsProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: {debounceMs: 500},
                                }
                            }
                            }
                            // onFilterModelChange={(change)=>
                            // { // @ts-ignore
                            //     dispatch(commissionPaginate({page: current_page, items: per_page,search:change.quickFilterValues[0]}))
                            // }
                            // }
                            rowsPerPageOptions={[10, 15, 20, 30]}
                            pagination
                            pageSize={+per_page}
                        />
                    </Box>
                </div>
            </div>
        </>
    )
}
