import React, {useEffect, useRef, useState} from "react";
import {useFormik} from 'formik';
import {useSelector} from "react-redux";
import {Typography, TextField, Button} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useAppDispatch} from "../../store/store";
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {ICashOpen,initialCashState} from "../../models/cash";
import {cashClose, cashOpen} from '../../store/cash/cash'
import {useNavigate, useParams} from "react-router-dom";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import {authSelector} from "../../store/auth/auth";
import {unwrapResult} from "@reduxjs/toolkit";
import {openNotifier} from "../../store/notifier/notifier";
import {AxiosError} from "axios";
import {resetCommission} from "@store/commission/commision";



export const CashClose = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {data} = useParams();
    const dataParse = JSON.parse(data?data:'');
    const {access_token} = useSelector(authSelector);

    return (
        <>

                <Box className="content-panel"
                     sx={{flexGrow: 1, display: 'flex', width: '100%'}}
                >
                    <Button variant="outlined" size="small" className="btn-atras btn-actions"
                            onClick={() => {
                                navigate("../cash", {replace: true})
                            }
                            }><ArrowBackIcon
                        fontSize="small"/>&nbsp; Atras</Button>
                    <div className="grid-2">
                        <div className="grid-1 b-bottom">
                          <div>Fondo Inicial: {dataParse[0]?.initial_fund}</div>
                            {
                                dataParse[0].increments!==0 && <div>Fondo Reembolsado: {dataParse[0]?.refund}</div>
                            }
                            {
                                dataParse[0].increments!==0 && <div>Fondo Incrementado: {dataParse[0]?.increment}</div>
                            }
                            {
                                dataParse[0].increments!==0 && <div>Fondo Total: {dataParse[0]?.initial_fund+dataParse[0]?.increment+dataParse[0]?.refund}</div>
                            }
                          <div>Total Pagado: {dataParse[0]?.commision_payed}</div>
                          <div>Fondo Final: {(dataParse[0]?.initial_fund+dataParse[0]?.increment+dataParse[0]?.refund)-(dataParse[0]?.commision_payed)}</div>
                            <div className="aling-right">
                                <Button
                                    size="small"
                                    className="btn-actions"
                                    variant="contained"
                                    color="primary"
                                    sx={{background: '#d32d20', "&:hover": {backgroundColor: '#d32d20'}}}
                                    onClick={(event) => {
                                        dispatch(cashClose({token:access_token})).then(unwrapResult).then((result: any) => {
                                            navigate("../cash", {replace: true})
                                            dispatch(openNotifier({variant: "success", open: true, message: 'success'}))
                                        }).catch((result: AxiosError) => {
                                            // @ts-ignore
                                            dispatch(openNotifier({variant: "error", open: true, message: 'La caja que desea cerrar no coincide con su usuario'}))
                                            // dispatch(openNotifier({variant: "error", open: true, message: result?.message}))
                                        })
                                    }}
                                    startIcon={<PointOfSaleIcon/>}
                                >
                                    Cerrar Caja
                                </Button>
                            </div>
                        </div>

                    </div>
                </Box>
        </>
    );
}
