import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from 'axios';
import {RootState} from '../rootReducer'
import {IHotelCruise,HotelCruiseLineType,IHotelCruisePaginate} from '../../models/hotelCruise'
const api = process.env.REACT_APP_API_URL
const initialState: IHotelCruisePaginate = {
    hotels_cruises:[{
        id: 0,
        name: '',
        type:'hotel'
    }],
    loadingHotelCruise: false,
}
export const hotelCruisePaginate = createAsyncThunk<IHotelCruisePaginate,
    {  },
    {
        rejectValue: string
    }>(
    'hotelCruise/list',
    async (data, thunkApi) => {
        try {
            const response = await axios.get(`${api}/hotel-cruise`);
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue('error')
        }
    }
)
export const CruisePaginateToday = createAsyncThunk<IHotelCruisePaginate,
    {  },
    {
        rejectValue: string
    }>(
    'hotelCruise/listToday',
    async (data, thunkApi) => {
        try {
            const response = await axios.get(`${api}/hotel-cruise/list`);
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue('error')
        }
    }
)
export const hotelCruiseSlice = createSlice({
    initialState,
    name: 'commission',
    extraReducers: builder => {
        builder
            .addCase(hotelCruisePaginate.pending, state => {
                state.loadingHotelCruise = true
            })
            .addCase(hotelCruisePaginate.fulfilled, (state, {payload}) => {
                // @ts-ignore
                state.hotels_cruises=payload
                state.loadingHotelCruise = false
            })
            .addCase(hotelCruisePaginate.rejected, (state, action) => {
                state.loadingHotelCruise = false
            })
            .addCase(CruisePaginateToday.pending, state => {
                state.loadingHotelCruise = true
            })
            .addCase(CruisePaginateToday.fulfilled, (state, {payload}) => {

                // @ts-ignore
                state.hotels_cruises=payload
                state.hotels_cruises.push({       id: 0,
                    name: 'N/A',
                    type:'n/a'})
                state.loadingHotelCruise = false
            })
            .addCase(CruisePaginateToday.rejected, (state, action) => {
                state.loadingHotelCruise = false
            })
    },
    reducers: {},
})

export const HotelCruiseReducer = hotelCruiseSlice.reducer
export const HotelCruiseSelector = (state: RootState) => state.hotelCruise
