import React from 'react';
import './App.css';
import {RoutesComponent} from './routes/routes';
import useNotifier from './components/notifier/notifier';
import BackdropComponent from "./components/backdrop/backdrop";
const  App=()=> {
  useNotifier();

  return (
          <>
            <BackdropComponent/>
           <RoutesComponent/>
            </>
  );
}

export default App;
