import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axios from 'axios';
import {RootState} from '../rootReducer'
import {IErrorsSeller, ISellerCreate, ISellerUpdate, ListISeller} from '../../models/seller'
import {closeBackdrop, openBackdrop} from "../../store/backdrop/backdrop";
import {openNotifier} from "../../store/notifier/notifier";
import {logout} from "../../store/auth/auth";


const api = process.env.REACT_APP_API_URL
const initialState: ListISeller = {
    sellers: [],
    seller:{
        id: 0,
        mobile_phone: '',
        name: '',
        status:0,
        phone:'',
        web: '',
        intComisionista:null
    },
    seller_commisionable:{
        id: 0,
        mobile_phone: '',
        name: '',
        status:0,
        phone:'',
        web: '',
        intComisionista:null
    },
    meta: {
        current_page: 1,
        per_page: '100',
        total: 0
    },
    search:'',
    errors:null,
    loadingSellers: false,
}

export const sellerList = createAsyncThunk<ListISeller,
    {},
    {
        rejectValue: unknown
    }>(
    'seller/list',
    async (data, thunkApi) => {
        try {
            const response = await axios.get(`${api}/seller`);
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err)
        }
    }
)
export const sellerPaginate = createAsyncThunk<ListISeller,
    { page: number, items: number,search:string },
    {
        rejectValue: string
    }>(
    'seller/paginate',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        try {
            const url=data.search?`${api}/seller/list?page=${data.page}&items=${data.items}&name=${data.search}&strWebAge=${data.search}`:`${api}/seller/list?page=${data.page}&items=${data.items}`;
            const response = await axios.get(url);
            thunkApi.dispatch(closeBackdrop());
            thunkApi.dispatch(searchFilter(data.search))
            return response.data;
        } catch (err) {
            thunkApi.dispatch(closeBackdrop());
            // @ts-ignore
            if(err?.response.status===401 ||err?.response.status===408){
                thunkApi.dispatch(openNotifier({open:true,variant:"error",message:'Ha ocurrido un error y el sistema se ha tenido que cerrar'}))
                thunkApi.dispatch(logout());
            }
            return thunkApi.rejectWithValue('error')
        }
    }
)

export const sellerRetrieve = createAsyncThunk<ISellerUpdate,
    { id: string },
    {
        rejectValue: string
    }>(
    'seller/retrieve',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        try {
            const response = await axios.get(`${api}/seller/${data}/show`);
            thunkApi.dispatch(closeBackdrop());
            return response.data;
        } catch (err) {
            thunkApi.dispatch(closeBackdrop());
            return thunkApi.rejectWithValue('error')
        }
    }
)
export const sellerCreate = createAsyncThunk<ISellerCreate,
    { seller: ISellerCreate },
    {
        rejectValue: string
    }>(
    'seller/create',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        try {
            const response = await axios.post(`${api}/seller`,data.seller);
            thunkApi.dispatch(closeBackdrop());
            return response.data;
        } catch (err) {
            thunkApi.dispatch(closeBackdrop());
            // @ts-ignore
            thunkApi.dispatch(fillSellerError(err.response.data))
            // @ts-ignore
            return thunkApi.rejectWithValue(err?.response?.data?.message)
        }
    }
)

export const sellerUpdate = createAsyncThunk<ISellerCreate,
    { seller: ISellerCreate,id:number },
    {
        rejectValue: string
    }>(
    'seller/update',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        try {
            const response = await axios.put(`${api}/seller/${data.id}`,data.seller);
            thunkApi.dispatch(closeBackdrop());
            return response.data;
        } catch (err) {
            thunkApi.dispatch(closeBackdrop());
            // @ts-ignore
            thunkApi.dispatch(fillSellerError(err.response.data))
            // @ts-ignore
            return thunkApi.rejectWithValue(err?.response?.data?.message)
        }
    }
)

export const sellerDisabled = createAsyncThunk<ISellerUpdate[],
    { id:number,sellers: ISellerUpdate[] },
    {
        rejectValue: string
    }>(
    'seller/disabled',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        try {
            thunkApi.dispatch(closeBackdrop());
            const response = await axios.put(`${api}/seller/${data.id}/changeStatus`);
            const detailUpdate=Array.from(data.sellers, (detail:ISellerUpdate) => {
                if(detail.id===data.id){
                    return {...detail,status:detail.status===1?0:1}
                }
                return {...detail}

            } )
            return detailUpdate;
        } catch (err) {
            thunkApi.dispatch(closeBackdrop());
            return thunkApi.rejectWithValue('error')
        }
    }
)

export const sellerSlice = createSlice({
    initialState,
    name: 'sellers',
    extraReducers: builder => {
        builder
            .addCase(sellerList.pending, state => {
                state.loadingSellers = true
            })
            .addCase(sellerList.fulfilled, (state, {payload}) => {
                // @ts-ignore
                state.sellers = payload
                state.loadingSellers = false
            })
            .addCase(sellerList.rejected, (state, action) => {
                state.loadingSellers = false
            })
            .addCase(sellerPaginate.pending, state => {
                state.loadingSellers = true
            })
            .addCase(sellerPaginate.fulfilled, (state, {payload}) => {
                // @ts-ignore
                const {data, meta} = payload
                state.sellers = data
                state.meta = meta
                state.loadingSellers = false
            })
            .addCase(sellerPaginate.rejected, (state, action) => {
                state.loadingSellers = false
            })
            .addCase(sellerRetrieve.pending, state => {
                state.loadingSellers = true
            })
            .addCase(sellerRetrieve.fulfilled, (state, {payload}) => {
                state.seller = payload
                state.loadingSellers = false
            })
            .addCase(sellerRetrieve.rejected, (state, action) => {
                state.loadingSellers = false
            })
            .addCase(sellerCreate.pending, state => {
                state.loadingSellers = true
            })
            .addCase(sellerCreate.fulfilled, (state, {payload}) => {
                state.loadingSellers = false
                state.seller={ id: 0,
                    mobile_phone: '',
                    name: '',
                    phone:'',
                    status:0,
                    web: '',
                    intComisionista:null};
            })
            .addCase(sellerCreate.rejected, (state, action) => {
                state.loadingSellers = false
                state.seller={ id: 0,
                    mobile_phone: '',
                    name: '',
                    phone:'',
                    status:0,
                    web: '',
                    intComisionista:null};
            })
            .addCase(sellerDisabled.pending, state => {
                state.loadingSellers = true
            })
            .addCase(sellerDisabled.fulfilled, (state, {payload}) => {
                state.loadingSellers = false
                state.sellers=payload
            })
            .addCase(sellerDisabled.rejected, (state, action) => {
                state.loadingSellers = false
            })
    },
    reducers: {
        fillSellers: (state, action: PayloadAction<ISellerUpdate>) => {
            state.sellers = [{...action.payload}];
        },
        fillSeller: (state, action: PayloadAction<any>) => {
            state.seller = {...action.payload};
        },
        findSeller: (state, action: PayloadAction<string>) => {
            // @ts-ignore
            state.seller=state.sellers.find(seller => seller.id === +action.payload) ? state.sellers.find(seller => seller.id === +action.payload) : null
        },
        findSellerCommsionable: (state, action: PayloadAction<string>) => {
            // @ts-ignore
            state.seller_commisionable=state.sellers.find(seller => seller.id === +action.payload) ? state.sellers.find(seller => seller.id === +action.payload) : null
        },
        fillSellerError: (state, action: PayloadAction<IErrorsSeller>) => {
            state.errors = {...action.payload};
        },
        resetSeller:(state)=>{
          state.seller={
              id: 0,
              mobile_phone: '',
              name: '',
              phone:'',
              web: '',
              status:0,
              intComisionista:null}
        },
        resetSellerCommisionable:(state)=>{
            state.seller_commisionable={ id: 0,
                mobile_phone: '',
                name: '',
                phone:'',
                web: '',
                status:0,
                intComisionista:null}
        },
        resetSellerError:(state)=>{
            state.errors=null;
        },
        resetSellers:(state)=>{
            state.sellers=[]
        },
        searchFilter:(state, action: PayloadAction<string>)=>{
            state.search=action.payload
        },
    },
})

export const SellersReducer = sellerSlice.reducer
export const SellersSelector = (state: RootState) => state.sellers
export const {
    fillSellers,
    findSeller,
    fillSellerError,
    findSellerCommsionable,
    resetSellerCommisionable,
    resetSellers,
    resetSeller,
    resetSellerError,
    searchFilter
} = sellerSlice.actions;
