import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from 'axios';
import {RootState} from '../rootReducer'
import {IEnterprise, IProduct,Enterprise} from '../../models/enterprise'

const api = process.env.REACT_APP_API_URL
const initialState: IEnterprise = {
    enterprises:[{id:0,name:''}],
    loading:false
}
const initialStateProducts: IProduct = {
    products:[{    id: 0,
        name:'' ,
        rates: {
            adult: 0,
            children: 0
        },
        type_tour: ''}],
    loadingProducts:false
}
export const enterpriseList = createAsyncThunk<IEnterprise,
    {},
    {
        rejectValue: string
    }>(
    'enterprise/list',
    async (data, thunkApi) => {
        try {
            const response = await axios.get(`${api}/enterprise`);
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue('error')
        }
    }
)
export const productsByEnterprise = createAsyncThunk<IProduct,
    { enterpriseId: boolean },
    {
        rejectValue: string
    }>(
    'enterprise/products',
    async (data, thunkApi) => {
        try {
            const response = await axios.get(`${api}/enterprise/${data.enterpriseId}/products`);
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue('error')
        }
    }
)
export const enterpriseSlice = createSlice({
    initialState,
    name: 'enterprise',
    extraReducers: builder => {
        builder
            .addCase(enterpriseList.pending, state => {
                state.loading = true
            })
            .addCase(enterpriseList.fulfilled, (state, {payload}) => {
                // @ts-ignore
                state.enterprises = payload
                state.loading = false
            })
            .addCase(enterpriseList.rejected, (state, action) => {
                state.loading = true
            })
    },
    reducers: {},
})
export const productsSlice = createSlice({
    initialState: initialStateProducts,
    name: 'enterprise',
    extraReducers: builder => {
        builder
            .addCase(productsByEnterprise.pending, state => {
                state.loadingProducts = true
            })
            .addCase(productsByEnterprise.fulfilled, (state, {payload}) => {
                // @ts-ignore
                state.products = payload
                state.loadingProducts = false
            })
            .addCase(productsByEnterprise.rejected, (state, action) => {
                state.loadingProducts = false
            })
    },
    reducers: {}
})

export const EnterprisesReducer = enterpriseSlice.reducer
export const ProductsReducer = productsSlice.reducer
export const EnterpriseSelector = (state: RootState) => state.enterprises
export const ProductsSelector = (state: RootState) => state.products
