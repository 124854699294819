import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axios, {AxiosError} from 'axios';
import {openBackdrop, closeBackdrop} from '../backdrop/backdrop'
import {RootState} from '../rootReducer'
import { IReportGet, IReportPaginate} from '../../models/report'

// @ts-ignore
import {saveAs} from "file-saver";

import {openNotifier} from "../../store/notifier/notifier";
import {logout} from "../../store/auth/auth";

const api = process.env.REACT_APP_API_URL
const initialStateReport: IReportGet = {
    reports: [],
    loadingReport: false,
    enterprise_id: false,
    seller_id: null,
    commissionable_id: null,
    start_date: null,
    finish_date: null,
    transaction_type_id: false,
    transaction_status_id: false,
}
const initialStatePaginate: IReportPaginate = {
    data: [],
    meta: {
        current_page: 1,
        per_page: '100',
        total: 0
    },
    search: '',
    start_date:null ,
    finish_date:null ,
    loading: false,
}

export const reportCreate = createAsyncThunk<IReportPaginate,
    {
        enterprise_id?: number,
        seller_id?: number,
        start_date?: string,
        finish_date?: string,
        transaction_type_id?: 4 | 5,
        transaction_status_id?: 5 | 6
    },
    {
        rejectValue: string
    }>(
    'report/list',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        try {
            const response = await axios.get(`${api}/taxi-plan/reports/operations?${data.enterprise_id ? '&enterprise_id=' + data.enterprise_id : ''}${data.seller_id ? '&seller_id=' + data.seller_id : ''}${data.start_date ? '&start_date=' + data.start_date : ''}${data.finish_date ? '&finish_date=' + data.finish_date : ''}${data.transaction_type_id ? '&transaction_type_id=' + data.transaction_type_id : ''}${data.transaction_status_id ? '&transaction_status_id=' + data.transaction_status_id : ''}${data.start_date===data.finish_date? '&report_detail=false' : '&report_detail=true'}`);
            thunkApi.dispatch(closeBackdrop());
            return response.data;
        } catch (err) {
            thunkApi.dispatch(closeBackdrop());
            // @ts-ignore
            if(err?.response.status===401 ||err?.response.status===408){
                thunkApi.dispatch(openNotifier({open:true,variant:"error",message:'Ha ocurrido un error y el sistema se ha tenido que cerrar'}))
                thunkApi.dispatch(logout());
            }
            return thunkApi.rejectWithValue('error')
        }
    }
)

export const reportMail = createAsyncThunk<string,
    {
        rejectValue: string
    }>(
    'report/mail',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        try {
            const response = await axios.get(`${api}/taxi-plan/reports/comission`);
            thunkApi.dispatch(closeBackdrop());
            thunkApi.dispatch(openNotifier({variant: "success", open: true, message: 'Reporte enviado correctamente'}))
            return response.data;
        } catch (err) {
            thunkApi.dispatch(closeBackdrop());
            thunkApi.dispatch(openNotifier({variant: "error", open: true, message: 'Error al generar reporte'}))
            return thunkApi.rejectWithValue('error')
        }
    }
)

export const reportPaginate = createAsyncThunk<IReportPaginate,
    {
        page: number,
        items: number
        enterprise_id?: number,
        seller_id?: number,
        start_date?: string,
        finish_date?: string,
        transaction_type_id?: 4 | 5,
        transaction_status_id?: 5 | 6
    },
    {
        rejectValue: string
    }>(
    'report/paginate',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        try {
            const response = await axios.get(`${api}/taxi-plan/reports/operations?page=${data.page}&items=${data.items}${data.enterprise_id ? '&enterprise_id=' + data.enterprise_id : ''}${data.seller_id ? '&seller_id=' + data.seller_id : ''}${data.start_date ? '&start_date=' + data.start_date : ''}${data.finish_date ? '&finish_date=' + data.finish_date : ''}${data.transaction_type_id ? '&transaction_type_id=' + data.transaction_type_id : ''}${data.transaction_status_id ? '&transaction_status_id=' + data.transaction_status_id : ''}`);
            thunkApi.dispatch(closeBackdrop());
            return response.data;
        } catch (err) {
            thunkApi.dispatch(closeBackdrop());
            // @ts-ignore
            if(err?.response.status===401 ||err?.response.status===408){
                thunkApi.dispatch(openNotifier({open:true,variant:"error",message:'Ha ocurrido un error y el sistema se ha tenido que cerrar'}))
                thunkApi.dispatch(logout());
            }
            return thunkApi.rejectWithValue('error')
        }
    }
)
export const reportGet = createAsyncThunk<IReportPaginate,
    {
        enterprise_id?: number,
        seller_id?: number,
        start_date?: string,
        finish_date?: string,
        transaction_type_id?: 4 | 5,
        transaction_status_id?: 5 | 6
    },
    {
        rejectValue: string
    }>(
    'report/get',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        try {

            const response = await axios.get(`${api}/taxi-plan/reports/export?${data.enterprise_id ? '&enterprise_id=' + data.enterprise_id : ''}${data.seller_id ? '&seller_id=' + data.seller_id : ''}${data.start_date ? '&start_date=' + data.start_date : ''}${data.finish_date ? '&finish_date=' + data.finish_date : ''}${data.transaction_type_id ? '&transaction_type_id=' + data.transaction_type_id : ''}${data.transaction_status_id ? '&transaction_status_id=' + data.transaction_status_id : ''}${data.start_date===data.finish_date? '&report_detail=false' : '&report_detail=true'}`, {responseType: "arraybuffer"});
            thunkApi.dispatch(closeBackdrop());
            const today = new Date();
            const day=today.getDate()<10?`0${today.getDate()}`:`${today.getDate()}`;
            const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Setiembre", "Octubre", "Noviembre", "Diciembre"];
            const year=today.getFullYear();
            const dayGet=data.start_date===data.finish_date?`${data.start_date}`:`${data.start_date}_al_${data.finish_date}`
            const outputFilename = `reporte_del_${dayGet}.xlsx`;
            const blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            saveAs(blob, outputFilename);
            return response.data;
        } catch (err) {
            thunkApi.dispatch(closeBackdrop());
            // @ts-ignore
            if(err?.response.status===401 ||err?.response.status===408){
                thunkApi.dispatch(openNotifier({open:true,variant:"error",message:'Ha ocurrido un error y el sistema se ha tenido que cerrar'}))
                thunkApi.dispatch(logout());
            }
            return thunkApi.rejectWithValue('error')
        }
    }
)
export const reportSaleGet = createAsyncThunk<IReportPaginate,
    {
        start_date?: string,
        finish_date?: string,
    },
    {
        rejectValue: string
    }>(
    'report/getSale',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        try {
            const response = await axios.get(`${api}/taxi-plan/reports/exportCommission?${data.start_date ? 'start_date=' + data.start_date : ''}${data.finish_date ? '&finish_date=' + data.finish_date : ''}`, {responseType: "arraybuffer"});
            const today = new Date();
            const day=today.getDate()<10?`0${today.getDate()}`:`${today.getDate()}`;
            const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Setiembre", "Octubre", "Noviembre", "Diciembre"];
            const year=today.getFullYear();
            const dayGet=data.start_date===data.finish_date?`${data.start_date}`:`${data.start_date}_al_${data.finish_date}`
            const outputFilename = `report_ventas_${dayGet}.xlsx`;
            const blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            thunkApi.dispatch(closeBackdrop());
            saveAs(blob, outputFilename);
            return response.data;
        } catch (err) {
            thunkApi.dispatch(closeBackdrop());
            // @ts-ignore
            if(err?.response.status===401 ||err?.response.status===408){
                thunkApi.dispatch(openNotifier({open:true,variant:"error",message:'Ha ocurrido un error y el sistema se ha tenido que cerrar'}))
                thunkApi.dispatch(logout());
            }
            return thunkApi.rejectWithValue('error')
        }
    }
)
export const reportSlice = createSlice({
    initialState: initialStatePaginate,
    name: 'report',
    extraReducers: builder => {
        builder
            .addCase(reportCreate.pending, state => {
                state.loading = true
            })
            .addCase(reportCreate.fulfilled, (state, {payload}) => {
                const {data, meta} = payload
                state.data = data
                state.meta = meta
                state.loading = false
            })
            .addCase(reportCreate.rejected, (state, action) => {
                state.loading = true
            })
            .addCase(reportPaginate.pending, state => {
                state.loading = true
            })
            .addCase(reportPaginate.fulfilled, (state, {payload}) => {
                const {data, meta} = payload
                state.data = data
                state.meta = meta
                state.loading = false
            })
            .addCase(reportPaginate.rejected, (state, action) => {
                state.loading = true
            })
            .addCase(reportGet.pending, state => {
                state.loading = true
            })
            .addCase(reportGet.fulfilled, (state, {payload}) => {
                state.loading = false
            })
            .addCase(reportGet.rejected, (state, action) => {
                state.loading = true
            })
            .addCase(reportSaleGet.pending, state => {
                state.loading = true
            })
            .addCase(reportSaleGet.fulfilled, (state, {payload}) => {
                state.loading = false
            })
            .addCase(reportSaleGet.rejected, (state, action) => {
                state.loading = true
            })
            .addCase(reportMail.pending, state => {
                state.loading = true
            })
            .addCase(reportMail.fulfilled, (state, {payload}) => {
                state.loading = false
            })
            .addCase(reportMail.rejected, (state, action) => {
                state.loading = false
            })
    },
    reducers: {
        changeEnterprise_id: (state, action: PayloadAction<number>) => {
            state.enterprise_id = action.payload;
        },
        changeSeller_id: (state, action: PayloadAction<number>) => {
            state.seller_id = action.payload;
        },
        changeCommissionable_id: (state, action: PayloadAction<number>) => {
            state.commissionable_id = action.payload;
        },
        changeStart_date: (state, action: PayloadAction<string>) => {
            state.start_date = action.payload;
        },
        changeFinish_date: (state, action: PayloadAction<string>) => {
            state.finish_date = action.payload;
        },
        changeTransaction_type_id: (state, action: PayloadAction<number>) => {
            state.transaction_type_id = action.payload;
        },
        changeTransaction_status_id: (state, action: PayloadAction<number>) => {
            state.transaction_status_id = action.payload;
        },
        reset_Filter: (state) => {
            state.transaction_status_id = false;
            state.data = [];
            state.enterprise_id = false;
            state.seller_id = null;
            state.commissionable_id=null;
            state.transaction_type_id = false;
            state.transaction_status_id = false;
        },
        reset_data: (state) => {
            state.data = [];
        }
    },
})
export const ReportSelector = (state: RootState) => {
    return {
        meta: state.reports.meta,
        data: state.reports.data,
        search: state.reports.search,
        enterprise_id: state.reports.enterprise_id,
        seller_id: state.reports.seller_id,
        commissionable_id: state.reports.commissionable_id,
        start_date: state.reports.start_date,
        finish_date: state.reports.finish_date,
        transaction_type_id: state.reports.transaction_type_id,
        transaction_status_id: state.reports.transaction_status_id,
        loadingReport: state.reports.loading,
    }
}
export const ReportMetaSelector = (state: RootState) => {
    return {
        meta: state.commission.meta,
        data: state.commission.data,
        loadingCommissionPaginate: state.commission.loading,
        search: state.commission.search
    };
};

export const ReportReducer =reportSlice.reducer
export const {
    changeEnterprise_id,
    changeTransaction_type_id,
    changeTransaction_status_id,
    changeFinish_date,
    changeSeller_id,
    changeCommissionable_id,
    changeStart_date,
    reset_Filter,
    reset_data,
} = reportSlice.actions;
