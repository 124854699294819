import { Taxi, Reporte, CashBox, Increment, Person } from "../../assets/image/";
import React from "react";
import {
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
    Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { authSelector } from "../../store/auth/auth";

const CardOption = ({image,title,onClick}: {image:string,title: string,onClick:()=>void}) => {
    return (
        <Card sx={{ maxWidth: 345 }} onClick={onClick}>
            <CardActionArea>
                <CardMedia component="img" height="140" image={image} alt={title} />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {title}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

const Main = () => {
    const navigate = useNavigate();
    const { user } = useSelector(authSelector);
    const arrayTaxiPlan = JSON.parse(process.env.REACT_APP_ID_TAXIPLAN ?? "");
    
    const arrayCajaGeneral = JSON.parse(
        process.env.REACT_APP_ID_CAJA_GENERAL ?? ""
    );
    const arraySaleReport = JSON.parse(
        process.env.REACT_APP_ID_UPLOAD_SALE ?? ""
    );
    const arrayDirectSells = JSON.parse(
        process.env.REACT_APP_ID_VENTAS_DIRECTAS ?? ""
    );

    const hasAccessToCashBox = arrayTaxiPlan.some(
        (value:string) => +value === user.id
    );
    const hasAccessToIncrement =  arrayCajaGeneral.some(
        (value:string) => +value === user.id
    );
    const hasAccessToReport =   arraySaleReport.some(
        (value:string) => +value === user.id
    );
    const hasAccessToDirectSells = arrayDirectSells.some(
        (value:string) => +value === user.id
    );

    return (
        <>
            <div className="cont-cards">
                {hasAccessToCashBox && (
                    <CardOption
                        image={CashBox}
                        title="Caja"
                        onClick={() => navigate("/cash", { replace: true })}
                    />
                )}
                {hasAccessToCashBox && (
                    <CardOption
                        image={Taxi}
                        title="Comisiones"
                        onClick={() => navigate("/commission", { replace: true })}
                    />
                )}
                {hasAccessToIncrement && (
                    <CardOption
                        image={Increment}
                        title="Incrementos Caja General"
                        onClick={() => navigate("/increment/cashBox", { replace: true })}
                    />
                )}
                {hasAccessToCashBox && (
                    <CardOption
                        image={Increment}
                        title="Incrementos"
                        onClick={() => navigate("/increment/cash", { replace: true })}
                    />
                )}
                {hasAccessToCashBox && (
                    <CardOption
                        image={Person}
                        title="Taxistas"
                        onClick={() => navigate("/seller", { replace: true })}
                    />
                )}
                {
                    (hasAccessToCashBox || hasAccessToIncrement) && (
                        <CardOption
                            image={Reporte}
                            title="Reportes"
                            onClick={() => navigate("/reports", { replace: true })}
                        />
                    )

                }

                {hasAccessToDirectSells && (
                    <CardOption
                        image={Increment}
                        title="Ventas Directas"
                        onClick={() => navigate("/reports-sale", { replace: true })}
                    />
                )}


            </div>
        </>
    );
};

export default Main;
