import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axios from 'axios';
import {persistReducer} from 'redux-persist';
import {openBackdrop,closeBackdrop} from '../backdrop/backdrop';
import storageSession from 'redux-persist/lib/storage/session';
import {RootState} from '../rootReducer'
import {
ICashListPending
} from '../../models/cash'


const api = process.env.REACT_APP_API_URL

export interface ICashBoxPending{
    id: number,
    amount: number,
    initial_fund: number,
    currency: "USD" |'MXN',
    status: string,
    type: string

}

interface ICashBoxPendingList {
    data: ICashBoxPending[],
    cashBoxPending:ICashBoxPending,
    loadingCashBoxPending: boolean,
    openDialogPending:boolean
}

const initialStatePaginate: ICashBoxPendingList = {
    data: [],
    loadingCashBoxPending: false,
    cashBoxPending:{
        id:0,
        amount: 0,
        initial_fund: 0,
        currency: "MXN",
        status: '',
        type: ''
    },
    openDialogPending:false
}
export const cashPendingPaginate = createAsyncThunk<ICashBoxPendingList,
    {},
    {
        rejectValue: string
    }>(
    'cashBoxPending/list',
    async (data, thunkApi) => {
            thunkApi.dispatch(openBackdrop())
        try {
            const response = await axios.get(`${api}/cash-closing/listIncrementPending`);
             thunkApi.dispatch(closeBackdrop());
            return response.data;
        } catch (err) {
            thunkApi.dispatch(closeBackdrop());
            return thunkApi.rejectWithValue('error')
        }
    }
)
export const cashPendingApprove = createAsyncThunk<ICashBoxPendingList,
    {idIncrement:string},
    {
        rejectValue: string
    }>(
    'cashBoxPending/approve',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        try {
            const response = await axios.put(`${api}/cash-closing/approve/${data.idIncrement}`);
            thunkApi.dispatch(closeBackdrop());
            return response.data;
        } catch (err) {
            console.log(err)
            thunkApi.dispatch(closeBackdrop());
            return thunkApi.rejectWithValue('error')
        }
    }
)

export const changePending = createAsyncThunk<ICashBoxPending,
    {data:ICashBoxPending},
    {
        rejectValue: string
    }>(
    'cashBoxPending/change',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        try {
            thunkApi.dispatch(closeBackdrop());
            return data.data;
        } catch (err) {
            thunkApi.dispatch(closeBackdrop());
            return thunkApi.rejectWithValue('error')
        }
    }
)

export const cashBoxPendingSlice = createSlice({
    initialState: initialStatePaginate,
    name: 'cash',
    extraReducers: builder => {
        builder
            .addCase(cashPendingPaginate.pending, state => {
                state.loadingCashBoxPending = true
            })
            .addCase(cashPendingPaginate.fulfilled, (state, {payload}) => {
                const {data} = payload
                state.data = data
                state.loadingCashBoxPending = false
            })
            .addCase(cashPendingPaginate.rejected, (state, action) => {
                state.loadingCashBoxPending = false
            })
            //
            .addCase(changePending.pending, state => {
                state.loadingCashBoxPending = true
            })
            .addCase(changePending.fulfilled, (state, {payload}) => {
                state.cashBoxPending = payload
                state.loadingCashBoxPending = false
            })
            .addCase(changePending.rejected, (state, action) => {
                state.loadingCashBoxPending = false
            })
            //
            .addCase(cashPendingApprove.pending, state => {
                state.loadingCashBoxPending = true
            })
            .addCase(cashPendingApprove.fulfilled, (state, {payload}) => {
                state.loadingCashBoxPending = false
            })
            .addCase(cashPendingApprove.rejected, (state, action) => {
                state.loadingCashBoxPending = false
            })
    },
    reducers: {


    },
})


export const CashBoxMetaSelector = (state: RootState) => {
    return {
        data: state.cashBoxPending.data,
        cashBoxPending:state.cashBoxPending.cashBoxPending,
        loadingCashPendingPaginate: state.cashBoxPending.loadingCash,
    };
};

export const CashBoxPendingReducer = persistReducer({
    storage:storageSession,
    key: 'cashBoxPending',
    blacklist: ['data'],
}, cashBoxPendingSlice.reducer)
export const {
} = cashBoxPendingSlice.actions;
