import React, {useState, useEffect} from 'react';
import {useNavigate, useParams,useLocation} from "react-router-dom";
import {useAppDispatch} from "../../store/store";
import {useSelector} from "react-redux";
import {CommissionSelector, commissionUpdate, commissionRetrieve,resetCommission,commissionPay} from "../../store/commission/commision";
import {
    ICreateCommission,
    IDetailsRetrieve,
    InitialValuesCommission,
    IDataCommission,
    ICommission, TransactionStatus
} from "../../models/commision";
import {unwrapResult} from "@reduxjs/toolkit";
import {openNotifier} from "../../store/notifier/notifier";
import {AxiosError} from "axios";
import {Button, Grid, TextField,Typography} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from '@mui/material/Box';
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

interface Column {
    id: 'name' | 'code' | 'population' | 'size' | 'density';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
}

const columns: Column[] = [
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
    {
        id: 'population',
        label: 'Population',
        minWidth: 170,
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'size',
        label: 'Size\u00a0(km\u00b2)',
        minWidth: 170,
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'density',
        label: 'Density',
        minWidth: 170,
        align: 'right',
        format: (value: number) => value.toFixed(2),
    },
];

interface Data {
    name: string;
    code: string;
    population: number;
    size: number;
    density: number;
}




export const Payment = () => {
    const [value, setValue] = useState(0);
    const {sku,intAgent} = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {commission, loadingCommission, errors} = useSelector(CommissionSelector);
    const { state } = useLocation();
    useEffect(() => {
        if (sku) {
            if(intAgent){
                // @ts-ignore
                dispatch(commissionRetrieve({id:sku,intAgent:intAgent}))
            }else {
                // @ts-ignore
                dispatch(commissionRetrieve(sku))
            }
        }
    }, [dispatch, sku]);


    // @ts-ignore
    return (
        <>
            {
                loadingCommission ? <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                </Backdrop> : <>
                    <Box className="content-panel"
                         sx={{flexGrow: 1, display: 'flex', width: '100%'}}
                    >

                        <Button variant="outlined" size="small" className="btn-atras btn-actions"
                                onClick={() => {
                                    dispatch(resetCommission())
                                    // @ts-ignore
                                    if(state?.origin==='reports'){
                                        navigate("../reports", {replace: true})
                                    } else {
                                        navigate("../commission", {replace: true})
                                    }

                                    }
                                }><ArrowBackIcon
                            fontSize="small"/>&nbsp; Atras</Button>
                        <div className="cont-info-pagar">
                            <div className="pd-15">
                                {

                                    commission?.details.map((detail:IDetailsRetrieve,index:number)=>{
                                        return<React.Fragment key={`fragment-${index}`}>

                                            <div className="pd-15">
                                                <Grid container spacing={2} className="item-taxista">
                                                    <Grid item xs={12} className="b-bottom" >
                                                        <div>
                                                            <span>Paquete:</span>
                                                            <span className="bold"> {detail?.product?.name}</span>
                                                        </div>
                                                    </Grid><Grid item xs={4} className="b-bottom">
                                                        <div>
                                                            <span>Adultos:</span>
                                                            <span className="bold"> {detail?.adult}</span>
                                                        </div>
                                                    </Grid><Grid item xs={4} className="b-bottom">
                                                        <div>
                                                            <span>Niños:</span>
                                                            <span className="bold"> {detail?.children}</span>
                                                        </div>
                                                    </Grid><Grid item xs={4} className="b-bottom">
                                                        <div>
                                                            <span>Total Pax:</span>
                                                            <span className="bold"> {(detail?.children)+(detail?.adult)}</span>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={4} className="b-bottom-final">
                                                        <div>
                                                            <span>Total Adult:</span>
                                                            <span className="bold"> {detail?.adult_amount}{commission?.point_sale==='others'?'mxn':'mxn'}</span>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={4} className="b-bottom-final">

                                                        <div>
                                                            <span>Total Children:</span>
                                                            <span className="bold"> {detail?.children_amount}{commission?.point_sale==='others'?'mxn':'mxn'}</span>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={4} className="b-bottom-final">
                                                        <div>
                                                            <span>Total Amount:</span>
                                                            <span className="bold"> {commission?.amount} {commission?.point_sale==='others'?'mxn':'mx'}</span>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </React.Fragment>

                                    })
                                }
                            </div>
                            <div className="pd-15">
                                <Grid container spacing={2} className="card-grid">
                                    <Grid item xs={12} className="b-bottom">
                                        <div>
                                            <span>Tipo de transacción:</span>
                                            <span className="bold"> {commission?.transaction_type?.name}</span>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} className="b-bottom">
                                        <div>
                                            <span>Nombre del {intAgent==='1'?'Vendedor':'Taxista'}</span>
                                            <span className="bold"> {intAgent==='1'?commission?.commissionable_agent?.name:commission?.seller?.name}</span>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} className="b-bottom">
                                        <div>
                                            <span>N° de placa:</span>
                                            <span className="bold"> {commission?.seller?.web}</span>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} className="b-bottom">
                                        <div>
                                            <span>N° de gafete:</span>
                                            <span className="bold">{commission?.seller?.phone} </span>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} className="b-bottom-final">
                                        <div>
                                            <span>Total Pax: </span>
                                            <span className="bold">
                                                {commission?.details.reduce((acc:number, detail:IDetailsRetrieve)=>  acc+(detail.adult+detail.children), 0)}
                                              </span>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} className="b-bottom-final">
                                        <div>
                                            <span>Total Amount: <span className="bold">$</span></span>
                                            <span className="bold">
                                                 {commission?.amount} {commission?.point_sale==='others'?'mxn':'mxm'}
                                                   {/*{commission?.details.reduce((acc: number, detail:IDetailsRetrieve)=>  acc+( detail.adult_amount+detail.children_amount), 0)}*/}
                                            </span>
                                        </div>
                                    </Grid>
                                </Grid>
                                <div style={{textAlign: 'right',marginTop: '15px'}}>
                                    <Button variant="contained" onClick={()=>{
                                        const updateInfo={
                                            hotel_cruise_id: commission?.hotel_cruise?.id,
                                            customer_name: commission?.customer_name,
                                            seller_id: commission?.seller?.id,
                                            enterprise_id: commission?.enterprise?.id,
                                            point_sale: commission?.point_sale,
                                            incidence:commission?.incidence,
                                            commissionable_agent: commission?.commissionable_agent?.id,
                                        }

                                        const { commissionable_agent, ...updateInfoWithoutCommissionAgent } = updateInfo
                                        const inUpdateCommission=updateInfo.point_sale==='puerta-maya'?updateInfo:updateInfoWithoutCommissionAgent;
                                        const detailUpdate=Array.from(commission.details, (detail:IDetailsRetrieve) => {
                                            return {
                                                id:detail.product.id,
                                                adult: detail.adult,
                                                children: detail.children,
                                                infant: detail.infant,
                                                date_operation: detail.date_operation,
                                                adult_amount: detail.adult_amount,
                                                children_amount: detail.children_amount,
                                                comment: detail.comment
                                            }
                                        } )
                                        const updateCommission:ICommission={...inUpdateCommission,transaction_status:TransactionStatus.Pagado,details:detailUpdate};

                                        if(sku){
                                            if(intAgent){
                                                dispatch(commissionPay({id: sku,intAgent:intAgent})).then(unwrapResult).then((result: any) => {
                                                    navigate("../commission", {replace: true})
                                                    dispatch(openNotifier({variant: "success", open: true, message: 'success'}))
                                                }).catch((result: AxiosError) => {
                                                    // @ts-ignore
                                                    dispatch(openNotifier({variant: "error", open: true, message: result?.message}))
                                                })
                                            } else {
                                                // @ts-ignore
                                                dispatch(commissionPay({id: sku})).then(unwrapResult).then((result: any) => {
                                                    navigate("../commission", {replace: true})
                                                    dispatch(openNotifier({variant: "success", open: true, message: 'success'}))
                                                }).catch((result: AxiosError) => {
                                                    // @ts-ignore
                                                    dispatch(openNotifier({variant: "error", open: true, message: result.response?.data?.message}))
                                                })
                                            }

                                        }

                                    }
                                    } size="medium">Pagar</Button>
                                </div>
                            </div>
                        </div>


                    </Box>

                </>
            }


        </>
    );
}

export default Payment
