import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"; 
import axios from "axios";
import { persistReducer } from 'redux-persist';
import { openBackdrop, closeBackdrop } from '../backdrop/backdrop';
import storageSession from 'redux-persist/lib/storage/session';
import { RootState } from '../rootReducer';
import {
    ISaleListPending, ISalePaginate
} from '../../models/formSale';
import { AxiosResponse } from "axios";

const api = process.env.REACT_APP_API_URL

const initialStatePaginate: ISalePaginate = {
    response: {
        details_created: 0,
        message: "",
        total_created: 0,
        statusCode: 0
    },
    meta: {
        current_page: 1,
        per_page: '10',
        total: 0
    },
    search: '',
    errors: null,
    loadingSale: false,
}

// Hacemos POST del excel
export const formSaleUploadExcel = createAsyncThunk<ISalePaginate,  
    { datos: FormData},
    {
        rejectValue: unknown
    }
    >(
        'formSaleUploadExcel/create',
        async( data, thunkApi ) => {
            thunkApi.dispatch( openBackdrop() );
            try{
                const response = await axios.post(`${api}/taxi-plan/imports/ventasBooths`,
                    data.datos,                                                                                      
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        responseType: 'json' as const
                    }
                    );
                thunkApi.dispatch(closeBackdrop());
                return await response.data;
            }catch( err ){
                thunkApi.dispatch( closeBackdrop() );
                return thunkApi.rejectWithValue("error");
            }
        }
)

export const formSaleSlice = createSlice({
    initialState: initialStatePaginate,
    name: "formSale",
    extraReducers: builder => {
        builder
        .addCase(formSaleUploadExcel.pending, state => {
            state.loadingSale = true;
        })
        .addCase(formSaleUploadExcel.fulfilled, (state = initialStatePaginate, {payload}) => {
            state.loadingSale = false;
            //console.log("state", state);
            //console.log("payload", payload);
            //state.response = payload.response;

            //state.data.response = payload.data.response;
        })
        .addCase(formSaleUploadExcel.rejected, (state, action) => {
            state.loadingSale = false;
        })
    },
    reducers: {},
});

export const FormSaleReducer = formSaleSlice.reducer;
export const FormSaleSelector = ( state: RootState ) => state.formSale;

/*
export const saleListActivePaginate = createAsyncThunk<ISalePaginate,
    { page: number, items: number },
    {
        rejectValue: string
    }>(
    'sale/listActive',
    async ( data, thunkApi ) => {
        thunkApi.dispatch(openBackdrop())
        try{
            const response = await axios.get("");
            thunkApi.dispatch(closeBackdrop());
            return response.data;
        }catch (err){
            console.log(err);
            thunkApi.dispatch(closeBackdrop());
            return thunkApi.rejectWithValue("error");
        }
    }
) */