import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axios from 'axios';
import storageSession from 'redux-persist/lib/storage/session';
import {persistReducer} from 'redux-persist';
import {RootState} from '../rootReducer'
import {IErrorsLogin,AuthState} from "../../models/login";
import {closeBackdrop, openBackdrop} from "../backdrop/backdrop";
import {openNotifier} from "../notifier/notifier";
const api = process.env.REACT_APP_API_URL

const initialState: AuthState = {
    access_token: null,
    user: {
        name: "",
        email:'',
        id:0,
        enterprises: []
    },
    errors: null,
    loading: false,
    open_dialog:false
}

export const auth = createAsyncThunk<AuthState,
    { username: string, password: string },
    {
        rejectValue: unknown
    }>(
    'app/auth',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        try {
            thunkApi.dispatch(closeBackdrop());
            // @ts-ignore
            const response = await axios.post(`${api}/auth/login`, data);
            // @ts-ignore
            thunkApi.dispatch(openNotifier({open:true,variant:"success",message:`Welcome ${response.data?.user?.name}`}))
            return response.data;
        } catch (err) {
            thunkApi.dispatch(closeBackdrop());
            // @ts-ignore
            thunkApi.dispatch(fillError(err.response.data))
            // @ts-ignore
            thunkApi.dispatch(openNotifier({open:true,variant:"error",message:'wrong password or username'}))
            return thunkApi.rejectWithValue('error')
        }
    }
)
export const authSlice = createSlice({
    initialState,
    name: 'auth',
    extraReducers: builder => {
        builder
            .addCase(auth.pending, state => {
                state.loading = true
            })
            .addCase(auth.fulfilled, (state, {payload}) => {
                const {access_token, user} = payload
                state.access_token = access_token
                state.user = user
                state.loading = false
            })
            .addCase(auth.rejected, (state, action) => {
                state.loading = false
            })
    },
    reducers: {
        fillError: (state, action: PayloadAction<IErrorsLogin>) => {
            state.errors = {...action.payload};
        },
        openDialog:(state,action: PayloadAction<boolean>)=>{
            state.open_dialog= action.payload;
        },
        logout:(state)=>{
            state.access_token=null
            state.user= {name: "",email:'',id:0, enterprises: []}
            state.errors= null
            state.loading=false
        }
    },
})

export const authSelector = (state: RootState) => state.auth;

export const {
    fillError,
    logout,
    openDialog
} = authSlice.actions;
export const AuthReducer = persistReducer({
    storage:storageSession,
    key: 'auth',
    whitelist: ['access_token', 'user'],
    blacklist:['open_dialog']
}, authSlice.reducer)
