import React, {ReactNode} from 'react';
import {Menus} from "../menu/menu";

interface layoutComponent {
    children: ReactNode
}

export const LayoutMain = ({children}: layoutComponent) => {
    return (
        <>
            <Menus/>
            <main>{children}</main>
        </>
    )
}
