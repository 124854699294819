import React, {useEffect} from "react";
import {useFormik} from 'formik';
import {TextField, Button} from '@mui/material';
import Box from '@mui/material/Box';
import {useAppDispatch} from "../../store/store";
import {ICashOpen,initialCashState} from "../../models/cash";
import {cashOpen} from '../../store/cash/cash'
import {useNavigate, useParams} from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useSelector} from "react-redux";
import {authSelector, openDialog} from "../../store/auth/auth";
import {DialogConfirmation} from '../dialog/dialog'


export const FormCashBoxGeneral = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {type_movement,id_detail} = useParams();
    const {access_token} = useSelector(authSelector);
    const {
        setFieldValue,
        setValues,
        values,
        handleChange,
        submitForm,
        handleBlur,
        setSubmitting
    } = useFormik<ICashOpen>({
        initialValues: initialCashState,
        onSubmit: (values: ICashOpen, formikHelpers) => {
            dispatch(openDialog(true))


        },
    })

    useEffect(() => {

    }, []);
    // @ts-ignore
    return (
        <>
            <DialogConfirmation type_fund={type_movement!} detail_id={id_detail!} isIncrementType={type_movement==='2'} amount={values.amount_mxn} ></DialogConfirmation>

            <form onSubmit={submitForm} className="form-login">
                <Box className="content-panel"
                     sx={{flexGrow: 1, display: 'flex', width: '100%'}}
                >
                    <Button variant="outlined" size="small" className="btn-atras btn-actions"
                            onClick={() => {
                                navigate("../increment/cashBox", {replace: true})
                            }
                            }><ArrowBackIcon
                        fontSize="small"/>&nbsp; Atras</Button>
            <div className="grid-2">
                <div className="grid-1 b-bottom">
                    <TextField
                        id="amount_mxn"
                        InputProps={{ inputProps: { min: 1} }}
                        // error={(errors?.errores?.customer_name?.length > 0 ?? false) && values.customer_name === ''}
                        // helperText={errors?.errores?.customer_name?.length > 0 ? errors?.errores?.customer_name[0] : ''}
                        label={type_movement==='1'?'Reembolso MXN':'Incremento MXN'}
                        variant="outlined"
                        name={'amount_mxn'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type={'number'}
                        value={values.amount_mxn}
                    />
                    <div className="aling-right"><Button variant="contained" type={'button'}
                                                         onClick={() => submitForm()}>{type_movement==='1'?'Reembolso':'Incrementar'}</Button>
                    </div>

                </div>

            </div>
                </Box>
            </form>

        </>
    );
}
