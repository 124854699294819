import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axios from 'axios';
import {persistReducer} from 'redux-persist';
import {openBackdrop,closeBackdrop} from '../backdrop/backdrop';
import storageSession from 'redux-persist/lib/storage/session';
import {RootState} from '../rootReducer'
import {
    ICashOpen,
    ICashListAll,
    ICashPaginate,
    ICashListPending
} from '../../models/cash'

const api = process.env.REACT_APP_API_URL
interface ICash {
    loading:boolean,
    data: ICashListAll,
    meta: {
        current_page: number,
        per_page: string,
        total: number
    },
}

const initialState: ICash = {
    loading:false,
    data: {
        // @ts-ignore
        date:'',
        user:'',
        cash_closings: []
    },
    meta: {
        current_page: 1,
        per_page: '10',
        total: 0
    },
}
const initialStatePaginate: ICashPaginate = {
    data: [],
    meta: {
        current_page: 1,
        per_page: '10',
        total: 0
    },
    search:'',
    errors:null,
    loadingCash: false,
}
export const cashPaginate = createAsyncThunk<ICashPaginate,
    { page: number, items: number },
    {
        rejectValue: string
    }>(
    'cash/list',
    async (data, thunkApi) => {
            thunkApi.dispatch(openBackdrop())
        try {
            // const response = await axios.get(`${api}/cash-closing`);
            const response = await axios.get(`${api}/cash-closing?page=${data.page}&items=${data.items}`);
             thunkApi.dispatch(closeBackdrop());
            return response.data;
        } catch (err) {

            thunkApi.dispatch(closeBackdrop());
            return thunkApi.rejectWithValue('error')
        }
    }
)



export const cashOpen = createAsyncThunk<string,
    { cash: ICashOpen },
    {
        rejectValue: unknown
    }>(
    'cash/create',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        try {
            const response = await axios.post(`${api}/cash-closing`, data.cash);
            thunkApi.dispatch(closeBackdrop());
            return response.data;
        } catch (err) {
            // @ts-ignore
            thunkApi.dispatch(closeBackdrop());
            // @ts-ignore
            return thunkApi.rejectWithValue(err.response.data)
        }
    }
)




export const cashClose = createAsyncThunk<string,
    {token:string},
    {
        rejectValue: unknown
    }>(
    'cash/update',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        try {
            const response = await axios.put(`${api}/cash-closing/close`, data.token);
            thunkApi.dispatch(closeBackdrop());
            return response.data;
        } catch (err) {
            thunkApi.dispatch(closeBackdrop());
            // @ts-ignore
            thunkApi.dispatch(fillError(err.response.data))
            // @ts-ignore
            return thunkApi.rejectWithValue(err.response.data)
        }
    }
)



export const cashSlice = createSlice({
    initialState: initialStatePaginate,
    name: 'cash',
    extraReducers: builder => {
        builder
            .addCase(cashPaginate.pending, state => {
                state.loadingCash = true
            })
            .addCase(cashPaginate.fulfilled, (state, {payload}) => {
                const {data, meta} = payload
                state.data = data
                state.meta = meta
                state.loadingCash = false
            })
            .addCase(cashPaginate.rejected, (state, action) => {
                state.loadingCash = false
            })
            .addCase(cashOpen.pending, state => {
                state.loadingCash = true
            })
            .addCase(cashOpen.fulfilled, (state, {payload}) => {
                state.loadingCash = false
            })
            .addCase(cashOpen.rejected, (state, action) => {
                state.loadingCash = false
            })
            .addCase(cashClose.pending, state => {
                state.loadingCash = true
            })
            .addCase(cashClose.fulfilled, (state, {payload}) => {
                state.loadingCash = false
            })
            .addCase(cashClose.rejected, (state, action) => {
                state.loadingCash = false
            })
    },
    reducers: {},
})


export const CashSelector = (state: RootState) => {
    return {
        data: state.commission.commission,
        loadingCash: state.commission.loading,
    };
};

export const CashMetaSelector = (state: RootState) => {
    return {
        meta: state.cash.meta,
        data: state.cash.data,
        loadingCashPaginate: state.cash.loadingCash,
    };
};

export const CashReducer = persistReducer({
    storage:storageSession,
    key: 'cash',
    blacklist: ['data', 'meta'],
}, cashSlice.reducer)
export const {
} = cashSlice.actions;
